import Parse from "parse"
import { uniqBy } from "lodash"

import {
    actionWithLoader,
    getReplacingPath,
    onEnter,
    push
} from "../Utils/utils"
import { generateRandomToken, getServerUrl, parseLimitRequest } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import {
    getCookingModes,
    getSupplierItems,
    deleteSupplierItemById,
    createSupplierItem,
    getSupplierItemWithId,
    updateSupplierItem,
    changeSupplierItemState,
    SupplierItem, updateRecipesFoodcost, getSupplierItemRelatives, getSupplierItemsBySupplier, getRawMaterialSupplierItems,
} from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { getSitesStockZones } from "../../parseManager/site/parseSiteManager"
import { calculatePricePerKgByBillings, supplierItemTypes, supplierItemsBaseCsvHeader } from "../../utils/supplierItemUtils"
import { getTransformationModes, TransformationModes } from "../../parseManager/transformationModes/transformationModesManager"
import { loadMachineTypes } from "../Machines/Machines"
import {
    updateSubcontractorProductTotalCost
} from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import { exportCSVFile } from "../Products/SearchAdvanced"
import { generateOrderUnitLabel, generateActiveStatus } from "../../utils/ordersUtils"
import { getParseSupplier } from "../../parseManager/suppliers/suppliers/parseSupplierManager"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"
import { downloadFromBlob } from "../Utils/utils"
import { showError } from "../Utils/app"

export function loadSupplierItems() {
    return actionWithLoader(async (dispatch) => {
        const [supplierItems, suppliers] = await Promise.all([
            getSupplierItems({
                includes: ["supplier"],
                selects: ["name", "supplierArticleId", "type", "supplier.name", "units", "isActive", "pricePerKg"],
                sortBy: "updatedAt",
                sortDirection: "desc"
            }),
            getSuppliers({
                selects: ["name"]
            })
        ])

        dispatch({
            type: "SUPPLIER_ITEMS_LOADED",
            supplierItems,
            suppliers
        })
    })
}

export function loadSupplierItemsOptions() {
    return actionWithLoader(async (dispatch) => {
        const rawMaterialSupplierItems = await getRawMaterialSupplierItems({ select: ["name"] })
        const supplierItemsOptions = rawMaterialSupplierItems.map(supplierItem => ({
            key: supplierItem.objectId,
            label: supplierItem.name
        }))
        dispatch({
            type: "SUPPLIER_ITEMS_OPTIONS_LOADED",
            supplierItemsOptions: uniqBy(supplierItemsOptions, "label")
        })
    })
}

/**
 * supplier items to replace selected one to another one
 * @returns 
 */
export function loadSupplierItemsToReplace(useOnRecipe = null, onlyActive = false) {
    return actionWithLoader(async (dispatch) => {
        const query = new Parse.Query(SupplierItem)
            .equalTo("type", "RAW_MATERIAL")
            .notEqualTo("deleted", true)

        if (onlyActive) {
            query.equalTo("isActive", true)
        }

        if (useOnRecipe) {
            query
                .include(["transformationModes.transformationMode.name"])
                .equalTo("useOnRecipe", true)
        }

        const supplierItems = await query.limit(parseLimitRequest).find()
        const supplierItemsJson = supplierItems.map(supplierItem => supplierItem.toJSON())

        dispatch({
            type: "SUPPLIER_ITEMS_LOADED",
            supplierItems: supplierItemsJson,
        })

        dispatch({
            type: "PRODUCTION_STEPS_SUPPLIER_ITEMS_LOADED",
            supplierItems: supplierItemsJson,
        })
    })
}

/**
 * replace one supplier item to another one
 * @returns 
 */
export function replaceSupplierItem(values, username, isProductionSteps = false) {
    return actionWithLoader(async () => {
        if (isProductionSteps) {
            await Parse.Cloud.run("replaceRecipeProductionStepsSupplierItem", {
                replacedSupplierItemId: values.replacedSupplierItem.value,
                originalSupplierItemId: values.originalSupplierItem.value,
                username: username
            })
            return
        }
        await Parse.Cloud.run("replaceRecipeSupplierItem", {
            replacedSupplierItemId: values.replacedSupplierItem.value,
            originalSupplierItemId: values.originalSupplierItem.value,
            username: username
        })
    })
}

export const downloadPreorderZip = ({ data, startDate, endDate, prevDayData, nextDayData, packagingData, reportMetadata, fileName }) => {
    return async (dispatch) => {
        try {
            const url = `${getServerUrl()}/supplierItems/downloadPreorderExtract`

            const payload = {
                startDate,
                endDate,
                data,
                prevDayData,
                nextDayData,
                packagingData,
                reportMetadata,
                fileName
            }

            // it's important to set the response type as blob otherwise we encounter conversion issues when creating url
            const response = await axiosCall("POST", url, payload, { "Content-Type": "application/json" }, "blob")

            if (!response || !response.data) {
                throw new Error("Pas de fichiers dans le zip")
            }
            const zipData = response.data
            const blob = new Blob([zipData], { type: "application/zip" })
            downloadFromBlob(blob, fileName, ".zip")
        }
        catch (e) {
            dispatch(showError(e.message))
        }
    }
}

export const downloadSupplierItemsReportCsv = ({ data, startDate, endDate, prevDayData, nextDayData, packagingData, reportMetadata, fileName, isRandD }) => {
    return async (dispatch) => {
        try {
            const url = `${getServerUrl()}/supplierItems/downloadSupplierItemsExtract`

            const payload = {
                startDate,
                endDate,
                data,
                prevDayData,
                nextDayData,
                packagingData,
                reportMetadata,
                fileName,
                isRandD
            }

            // it's important to set the response type as blob otherwise we encounter conversion issues when creating url
            const response = await axiosCall("POST", url, payload, { "Content-Type": "application/json" }, "blob")

            if (!response || !response.data) {
                throw new Error("Pas de contenu csv")
            }
            const csvData = response.data
            const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" })
            const fileExtension = ".csv"
            downloadFromBlob(blob, fileName, fileExtension)
        }
        catch (e) {
            dispatch(showError(e.message))
        }
    }
}

export const downloadSupplierItemsByReportCsv = ({
    data,
    csvFileName,
    reportMetadata = [],
    isRandD = null,
    startDate,
    endDate,
    prevDayData,
    nextDayData,
    packagingData,
    isPreorderReport = false
}) => {
    return actionWithLoader(async (dispatch) => {

        if (isPreorderReport) {
            await dispatch(downloadPreorderZip({ data, startDate, endDate, prevDayData, nextDayData, packagingData, reportMetadata, fileName: csvFileName }))
            return
        }

        await dispatch(downloadSupplierItemsReportCsv({ data, startDate, endDate, prevDayData, nextDayData, packagingData, reportMetadata, isRandD, fileName: csvFileName }))
    })
}

export function loadSingleSupplierItem(supplierItemId) {
    return actionWithLoader(async (dispatch) => {
        const cookingModes = await getCookingModes()
        const sites = await getSitesStockZones()
        const transformationModes = await getTransformationModes(null, true, { order: "asc", orderBy: "name" })
        const supplierItemsRelatives = await getSupplierItemRelatives(supplierItemId)
        dispatch(loadMachineTypes())

        const selectedSupplierItem = await getSupplierItemWithId(
            supplierItemId,
            [
                "commercialName", "commercialName.group.family", "commercialName.allergens", "cookingModes.cookingMode",
                "supplier", "site", "stockZone", "units", "transformationModes.transformationMode", "transformationModes.machineType"
            ])

        dispatch({
            type: "SELECTED_SUPPLIER_ITEM_LOADED",
            selectedSupplierItem,
            cookingModes,
            sites,
            transformationModes,
            supplierItemsRelatives
        })

        const newTransformationModes = selectedSupplierItem.transformationModes?.map(transformationMode => ({
            ...transformationMode,
            objectId: generateRandomToken() // a random id for each list item
        })) || []

        // for transformation modes edition
        dispatch({
            type: "SUPPLIER_ITEM_TRANSFORMATION_MODES_LOADED",
            supplierItemTransformationModes: newTransformationModes,
        })
        dispatch({
            type: "OLD_SUPPLIER_ITEM_TRANSFORMATION_MODES_LOADED",
            oldSupplierItemTransformationModes: newTransformationModes
        })
    })
}

export const updateSupplierItemImage = (supplierItemId, values) => {
    return actionWithLoader(async (dispatch) => {
        dispatch(loadMachineTypes())

        const supplierItem = await getSupplierItemWithId(supplierItemId, [], false)
        if (!supplierItem.has("images")) return
        const images = supplierItem.get("images").map(image => {
            if (image.publicId === values.publicId) {
                return values
            }
            return image
        })
        supplierItem.set("images", images)
        const updatedSupplierItem = await supplierItem.save()
        dispatch({
            type: "UPDATE_SUPPLIER_ITEM_IMAGE_NAME",
            images: updatedSupplierItem.get("images")
        })
    })
}

export const extractSupplierItemsBaseCsv = () => {
    return actionWithLoader(async (dispatch) => {
        try {
            const supplierItems = await getSupplierItems({ includes: ["commercialName", "supplier", "site", "stockZone"], toJSON: true })

            const supplierItemsRow = supplierItems.map((supplierItem) => ({
                id: supplierItem.objectId,
                name: supplierItem.name,
                supplier: supplierItem.supplier?.name,
                type: supplierItemTypes[supplierItem.type].label,
                supplierArticleName: supplierItem.supplierArticleName,
                supplierArticleId: supplierItem.supplierArticleId,
                ean: supplierItem.ean,
                isActive: generateActiveStatus(supplierItem.isActive),
                pricePerKg: supplierItem.pricePerKg,
                orderUnit: generateOrderUnitLabel(supplierItem, "order"),
                unitsOrderWeight: supplierItem.units?.order?.weight,
                unitsOrderPrice: supplierItem.units?.order ? supplierItem.units.order.price + " €" : "",
                billingUnit: generateOrderUnitLabel(supplierItem, "billing"),
                unitsBillingWeight: supplierItem.units?.billing?.weight,
                unitsBillingPrice: supplierItem.units?.billing ? supplierItem.units.billing.price + " €" : "",
                stockUnit: generateOrderUnitLabel(supplierItem, "stock"),
                unitsStockWeight: supplierItem.units?.stock?.weight,
                unitsStockPrice: supplierItem.units?.stock ? supplierItem.units.stock.price + " €" : "",
                site: supplierItem.site?.name,
                stockZone: supplierItem.stockZone?.name,
                associatedId: supplierItem.commercialName?.objectId
            }))

            dispatch(exportCSVFile(supplierItemsBaseCsvHeader, supplierItemsRow, "supplierItems"))
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la base article globale" }
            })
        }
    })
}

export const extractSupplierItemsBySupplierCsv = (supplierId) => {
    return actionWithLoader(async (dispatch) => {
        try {
            const supplier = await getParseSupplier(supplierId)
            const supplierItems = await getSupplierItemsBySupplier(supplier)

            const supplierItemsRow = supplierItems.map((supplierItem) => ({
                id: supplierItem.objectId,
                name: supplierItem.name,
                supplier: supplierItem.supplier?.name,
                type: supplierItemTypes[supplierItem.type].label,
                supplierArticleName: supplierItem.supplierArticleName,
                supplierArticleId: supplierItem.supplierArticleId,
                ean: supplierItem.ean,
                isActive: supplierItem.isActive,
                pricePerKg: supplierItem.pricePerKg,
                orderUnit: generateOrderUnitLabel(supplierItem, "order"),
                unitsOrderWeight: supplierItem.units?.order?.weight,
                unitsOrderPrice: supplierItem.units?.order ? supplierItem.units.order.price + " €" : "",
                billingUnit: generateOrderUnitLabel(supplierItem, "billing"),
                unitsBillingWeight: supplierItem.units?.billing?.weight,
                unitsBillingPrice: supplierItem.units?.billing ? supplierItem.units.billing.price + " €" : "",
                stockUnit: generateOrderUnitLabel(supplierItem, "stock"),
                unitsStockWeight: supplierItem.units?.stock?.weight,
                unitsStockPrice: supplierItem.units?.stock ? supplierItem.units.stock.price + " €" : "",
                site: supplierItem.site?.name,
                stockZone: supplierItem.stockZone?.name,
            }))

            dispatch(exportCSVFile(supplierItemsBaseCsvHeader, supplierItemsRow, "supplierItems"))
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la base article globale" }
            })
        }
    })
}

export function deleteSupplierItem(id) {
    return actionWithLoader(async (dispatch) => {
        await deleteSupplierItemById(id)

        dispatch(loadSupplierItems())
    })
}

export const duplicateSupplierItem = (currentSupplierItemId) => {
    return actionWithLoader(async (dispatch) => {
        const result = (await Parse.Cloud.run("duplicateSupplierItem", { supplierItemId: currentSupplierItemId })) || {}
        const { success, id: supplierItemId } = result
        if (success === true && supplierItemId) {
            dispatch(showSupplierItem(supplierItemId))
        }
        else {
            dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                supplierItemSnackBar: { open: true, type: "error", message: "L'article fournisseur n'a pas pu être dupliqué", duration: 5000 }
            })
        }
    })
}

export function onChangeSupplierItemState(supplierItemId, value) {
    return actionWithLoader(async (dispatch) => {
        await changeSupplierItemState(supplierItemId, value)

        dispatch(loadSingleSupplierItem(supplierItemId))
    })
}

export function onCreateSupplierItem(values, supplierItem) {
    return actionWithLoader(async (dispatch) => {
        const newSupplierItem = await createSupplierItem(values, supplierItem)

        dispatch(showSupplierItem(newSupplierItem.objectId))
    })
}

export function onEnterSupplierItems(store) {
    return onEnter({
        store,
        actionThunk: loadSupplierItems,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterSingleSupplierItem(store) {
    return async (nextState, replace, callback) => {
        const { params } = nextState

        if (!params.id) {
            store.dispatch(showSupplierItems())
        }

        store.dispatch(loadSingleSupplierItem(params.id))

        callback()
    }
}

export function closeSupplierItemsSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
            supplierItemSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
        dispatch(loadSupplierItems())
    })
}

export function extractRecipesWithSupplierItemId(supplierItemId) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/ingredients/${supplierItemId}/_extractRecipes`
            const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" }, "blob")
            const csvUrl = window.URL.createObjectURL(new Blob([result.data]))
            const link = document.createElement("a")
            const fileName = `Export-${supplierItemId}-FT.csv`

            link.href = csvUrl
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()

            dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                supplierItemSnackBar: { open: true, type: "success", message: `La liste des produits internes contentant l'ingrédient ${supplierItemId} a été exportée.`, duration: 5000 }
            })
        } catch (err) {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                supplierItemSnackBar: { open: true, type: "error", message: `L'extraction des recettes contenant l'ingredient avec l'id ${supplierItemId} a échoué.`, duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemNews(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        const newSupplierItem = await updateSupplierItem(supplierItemId, ["news"], values)

        if (newSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: newSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemCommercialName(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        const newSupplierItem = await updateSupplierItem(supplierItemId, ["commercialName", "useOnRecipe"], values)

        if (newSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: newSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemCookingModes(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        const newSupplierItem = await updateSupplierItem(supplierItemId, ["cookingModes"], values)

        if (newSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: newSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemUnits(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        // calculate pricePerKg depending on the form values
        const pricePerKg = calculatePricePerKgByBillings(values.units.billing.price, values.units.billing.weight, false)
        const newValues = {
            ...values,
            pricePerKg,
        }
        const fields = ["units", "pricePerKg"] // fields to update
        const updatedSupplierItem = await updateSupplierItem(supplierItemId, fields, newValues)
        if (fields.includes("pricePerKg")) {
            updateRecipesFoodcost(updatedSupplierItem)
        }
        updateSubcontractorProductTotalCost(supplierItemId)

        if (updatedSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: updatedSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemStockage(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        const newSupplierItem = await updateSupplierItem(supplierItemId, ["stockage"], values)

        if (newSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: newSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemBillingUnits(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        const updatedSupplierItem = await updateSupplierItem(supplierItemId, ["billingUnits"], values)
        await updateSubcontractorProductTotalCost(supplierItemId)

        if (updatedSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: updatedSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}

export function updateSupplierItemResume(supplierItemId, values) {
    return actionWithLoader(async (dispatch) => {
        const fields = ["billingUnits", "isActive", "name", "supplierArticleName", "supplierArticleId", "ean", "pricePerKg", "productType", "availabilityAtSupplier"]
        const updatedSupplierItem = await updateSupplierItem(supplierItemId, fields, values)
        if (fields.includes("pricePerKg")) {
            updateRecipesFoodcost(updatedSupplierItem)
        }

        updateSubcontractorProductTotalCost(supplierItemId)

        if (updatedSupplierItem) {
            return dispatch({
                type: "SELECTED_SUPPLIER_ITEM_UPDATED",
                selectedSupplierItem: updatedSupplierItem
            })
        }
        else {
            return dispatch({
                type: "UPDATED_SUPPLIER_ITEMS_SNACKBAR",
                ingredientSnackBar: { open: true, type: "error", message: "Une erreur est survenue", duration: 5000 }
            })
        }
    })
}


export const saveSupplierItemsFilters = (field, value) => {
    return async (dispatch) => {
        dispatch({
            type: "FILTER_SUPPLIER_ITEMS",
            field,
            value
        })
    }
}

export const addTransformationModesToSupplierItem = (id, values) => {
    return actionWithLoader(async (dispatch) => {
        const supplierItem = await getSupplierItemWithId(id, ["transformationModes.transformationMode"], false)

        if (!supplierItem) {
            throw new Error("Supplier item not found")
        }

        const transformationModes = supplierItem.get("transformationModes") || []

        // pointer to the new transformation mode
        const newTransformationModePointer = new TransformationModes()
        newTransformationModePointer.id = values.transformationMode.objectId

        transformationModes.push({
            ...values,
            transformationMode: newTransformationModePointer,
        })

        supplierItem.set("transformationModes", transformationModes)
        const updatedSupplierItem = await supplierItem.save()
        const supplierItemJson = updatedSupplierItem?.toJSON()
        
        dispatch({
            type: "UPDATE_SUPPLIER_ITEM_TRANSFORMATION_MODE",
            transformationModes: supplierItemJson?.transformationModes || []
        })
    })
}

export const editTransformationModesToSupplierItem = (id, values) => {
    return actionWithLoader(async (dispatch) => {
        const supplierItem = await getSupplierItemWithId(id, ["transformationModes.transformationMode"], false)

        if (!supplierItem) {
            throw new Error("Supplier item not found")
        }

        const transformationModes = supplierItem.get("transformationModes") || []

        const newTransformationModes = transformationModes.map(transformationMode => {
            if (transformationMode.transformationMode.id === values.transformationMode.objectId) {
                return {
                    ...values,
                    transformationMode: transformationMode.transformationMode,
                }
            } else {
                return transformationMode
            }
        })

        supplierItem.set("transformationModes", newTransformationModes)
        const updatedSupplierItem = await supplierItem.save()
        const supplierItemJson = updatedSupplierItem?.toJSON()
        
        dispatch({
            type: "UPDATE_SUPPLIER_ITEM_TRANSFORMATION_MODE",
            transformationModes: supplierItemJson?.transformationModes || []
        })
    })
}

export const removeTransformationModeFromSupplierItem = (id, transformationModeId) => {
    return actionWithLoader(async (dispatch) => {
        const supplierItem = await getSupplierItemWithId(id, ["transformationModes.transformationMode"], false)

        if (!supplierItem) {
            throw new Error("Supplier item not found")
        }

        const transformationModes = supplierItem.get("transformationModes") || []

        const newTransformationModes = transformationModes.filter(transformationMode => transformationMode.transformationMode.id !== transformationModeId)

        supplierItem.set("transformationModes", newTransformationModes)
        const updatedSupplierItem = await supplierItem.save()
        const supplierItemJson = updatedSupplierItem?.toJSON()
        
        dispatch({
            type: "UPDATE_SUPPLIER_ITEM_TRANSFORMATION_MODE",
            transformationModes: supplierItemJson?.transformationModes || []
        })
    })
}
export function showSupplierItems() {
    return push("/suppliers/supplierItems")
}

export function showSupplierItem(supplierItemId) {
    if (supplierItemId) {
        return push(`/suppliers/supplierItems/${supplierItemId}`)
    }
}
