import React from "react"
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	styled,
} from "@mui/material"
import { COLORS } from "../../../../utils"

const StyledDialog = styled(Dialog)({
	"& .MuiPaper-root": {
		width: 500,
	},
})

const TransformationModeDeleteConfirmationModal = ({
	onClose,
	open,
	transformationModeName,
	onConfirm
}) => {
	return (
		<StyledDialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogContent sx={{ color: COLORS.DRAFT_GREY, fontSize: 16 }}>
				Vous avez choisi de supprimer le mode de transformation {transformationModeName}.<br />
				Cette action est irréversible.<br />
				Confirmez-vous cette action ?
			</DialogContent>
			<DialogActions sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 2 }}>
				<Button onClick={onClose} color="primary">
					Retour
				</Button>
				<Button onClick={onConfirm} color="primary">
					Confirmer
				</Button>
			</DialogActions>
		</StyledDialog>
	)
}

export default TransformationModeDeleteConfirmationModal
