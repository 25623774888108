import React, { useEffect, useState } from "react"
import { TableHead, TableBody, TableRow, Stack } from "@mui/material"
import { PETable, PETableHeaderCell_V2, PETotalTableCell_V2 } from "../StyledPackagingExecutionPreviewComponents"
import { COLORS } from "../../../utils"
import PackagingExecutionSection from "./PackagingExecutionSection"
import { convertKilosIntoGrams, convertGramsIntoKilos } from "../../../utils/ressources"
import { roundNumber } from "../../../utils"
import Typography from "@mui/material/Typography"

const styles = {
	borders: {
		left: { borderLeft: "1px solid #E6E6E6" },
		right: { borderRight: "1px solid #E6E6E6" },
		bottomLeft: {
			borderRadius: "0 0 0 6px",
		},
		bottomRight: {
			borderRadius: "0 0 6px 0",
		}
  }
}

const PackagingExecutionSections = ({ sections = [], packagingForecastNumber, setFieldValue, setFieldTouched, editable = true, touchedSections = [], FCPackagingNumber = 1 }) => {

	const [focusedFieldIndex, setFocusedFieldIndex] = useState(null)

	const _renderKiloToGrams = (value) => {
		return roundNumber(convertKilosIntoGrams(value), 0)
	}

	const _formatCellValue = (value1, value2 = null, unit = "", style=null) => {
    if (!value1 && !value2) {
      return "-"
    }
    if (value1 && value2) {
        return (
            <Stack gap={1} direction="row" alignItems="center">
                {value1} / {value2} <span style={style}>{unit}</span>
            </Stack>
        )
    }
    return (
        <Stack gap={1} direction="row" alignItems="center">
            {value1 || value2} <span style={style}>{unit}</span>
        </Stack>
    )
}

	/**
	 * calculate section realWeight (poids barquetté) = capacity (contenance) of each packaging
		*/
	const onChangeSectionRealWeight = (realWeight, index) => {
		if (realWeight === "") {
			setFieldValue(`sections[${index}].realWeight`, "")
			return
		}
		const realWeightKilos = convertGramsIntoKilos(realWeight)
		setFieldValue(`sections[${index}].realWeight`, realWeightKilos)

		const section = sections[index]
		const sectionProductionWeight = section.initialProductionWeight // already in kilos
		if (!sectionProductionWeight) return

		let newPackagingForecastNumber = Math.floor(sectionProductionWeight / (realWeightKilos * FCPackagingNumber))
		if (!isFinite(newPackagingForecastNumber)) { // if realWeight is 0 the division will return Infinity
			newPackagingForecastNumber = 0
		}
		setFieldValue(`sections[${index}].packagingForecastNumber`, newPackagingForecastNumber)
	}

	/** update waste by section on section changes */
	const _updateSectionsWaste = (sections, packagingForecastNumber, touchedSections) => {
		for (const section of sections) {
			const index = sections.indexOf(section)
			const sectionProductionWeight = section.initialProductionWeight
			const sectionRealWeight = section.realWeight
			if (!touchedSections?.[index]?.realWeight) {
				continue
			}
			if (!isFinite(sectionProductionWeight) || !isFinite(sectionRealWeight) || !isFinite(packagingForecastNumber)) {
				continue
			}
			const sectionForecastWaste = sectionProductionWeight - (packagingForecastNumber * sectionRealWeight * FCPackagingNumber)
			setFieldValue(`sections[${index}].forecastWaste`, sectionForecastWaste)
		}
	}

	useEffect(() => {
		_updateSectionsWaste(sections, packagingForecastNumber, touchedSections)
	}, [sections])


	// trigger all fields calculations from proposed weight if real weight has not yet been manually changed
	useEffect(() => {
		if (focusedFieldIndex === null) {
		for (const section of sections) {
			const index = sections.indexOf(section)
			if (!section.realWeight && isFinite(section.proposedWeight)) {
				setFieldTouched(`sections[${index}].realWeight`, true)
				const proposedWeightToGrams = convertKilosIntoGrams(section.proposedWeight)
				onChangeSectionRealWeight(proposedWeightToGrams, index)
			}
		}}
	}, [sections, focusedFieldIndex])

	//  update forecast packaging number with the minimum value of all sections' forecast packaging number
	useEffect(() => {
		const sectionsWithPackagingForecastNumber = sections.filter(section => section.packagingForecastNumber && isFinite(section.packagingForecastNumber))
		const packagingForecastNumbers = sectionsWithPackagingForecastNumber.map(section => section.packagingForecastNumber)
		if (packagingForecastNumbers.length > 0) {
			const minimumPackagingForecastNumber = Math.min(...packagingForecastNumbers)
			setFieldValue("packagingForecastNumber", minimumPackagingForecastNumber)
		}
	}, [sections])

	return (
    <Stack spacing={2} sx={{width:"100%"}}>
      <Stack spacing={1} direction={"row"}>
        <img src="/img/packagingExecutions/distributionBySections.svg" alt="Packaging Icon" />
        <Typography variant="h6" sx={{fontSize:"14px", lineHeight:"22px", color: COLORS.BLACK800}}>
          Répartition par sections
        </Typography>
      </Stack>
			<Typography variant="p" sx={{fontSize:"16px", lineHeight:"24px", color:"#7C7C7C"}}>
				Saisissez les quantités réelles des sections à barquetter
			</Typography>
      <PETable sx={styles.table}>
        <TableHead>
          <TableRow>
            <PETableHeaderCell_V2 align="start" sx={styles.headerCell}>
              Élements à barquetter
            </PETableHeaderCell_V2>
            <PETableHeaderCell_V2 align="start" sx={styles.headerCell}>
              Quantité produite / estimée
            </PETableHeaderCell_V2>
            <PETableHeaderCell_V2 align="start" sx={styles.headerCell}>
              Quantité de la fiche recette
            </PETableHeaderCell_V2>
            <PETableHeaderCell_V2 align="start" sx={styles.headerCell}>
              Quantité à barquetter
            </PETableHeaderCell_V2>
            <PETableHeaderCell_V2 align="start" sx={styles.headerCell}>
              Restes
            </PETableHeaderCell_V2>
          </TableRow>
        </TableHead>
        <TableBody>

				{sections.map((currentSection, index) => (
					<PackagingExecutionSection
						key={index}
						editable={editable}
						section={currentSection}
						index={index}
						setFieldTouched={(form) => form.setFieldTouched} 
						renderKiloToGrams={_renderKiloToGrams}
						onChangeSectionRealWeight={(value) => onChangeSectionRealWeight(value, index)}
						_formatCellValue={_formatCellValue}
						setFocusedFieldIndex={setFocusedFieldIndex}
					/>
				))}

				<TableRow>
					<PETotalTableCell_V2>
						Total
					</PETotalTableCell_V2>
					<PETotalTableCell_V2>
						{_formatCellValue(
							roundNumber(sections.reduce((acc, section) => acc + (section.initialProductionWeight), 0), 1),
							roundNumber(sections.reduce((acc, section) => acc + (section.totalTheoreticalWeight), 0), 1),
							"kg"
						)}
					</PETotalTableCell_V2>
					<PETotalTableCell_V2>
						{_formatCellValue(
            _renderKiloToGrams(sections.reduce((acc, section) => acc + (section.recipeSectionWeight), 0)),
						null,
            "g"
						)}
					</PETotalTableCell_V2>
					<PETotalTableCell_V2>
						{_formatCellValue(
            _renderKiloToGrams(sections.reduce((acc, section) => acc + (section.realWeight || 0), 0)),
						null,
            "g"
						)}
					</PETotalTableCell_V2>
					<PETotalTableCell_V2 disabled>
						{_formatCellValue(
            roundNumber(sections.reduce((acc, section) => acc + (section.forecastWaste || 0), 0), 1),
						null,
            "kg"
						)}
					</PETotalTableCell_V2>
				</TableRow>
        </TableBody>
      </PETable>
    </Stack>)
}


export default PackagingExecutionSections
