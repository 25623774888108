import React, { useMemo, useState, useEffect } from "react"
import { cloneDeep, isEqual } from "lodash"
import { Box, MenuItem, Stack } from "@mui/material"
import { ErrorMessage, Field } from "formik"
import IconButton from "@mui/material/IconButton"
import ClearIcon from "@mui/icons-material/Clear"
import {
  StyledErrorMessage,
  StyledStepBodyCell,
  StyledStepFirstBodyColumn,
  StyledStepText
} from "../StyledSectionComponents"
import { COLS_OFFSETS, PRODUCTION_STEPS_COL_WIDTHS } from "../../../../utils/constant"
import { COLORS, roundNumber } from "../../../../utils"
import {
  recalculateCostValuesPriorStep,
} from "../../../../utils/recipes"
import { FormikEditableStepSelect } from "./EditableStepComponent"
import { updateAllRecipesFieldsOnChange } from "../../../../utils/recipes"
import { convertKilosIntoGrams } from "../../../../utils/ressources"

const widths = PRODUCTION_STEPS_COL_WIDTHS

const EditablePriorStepComponent = ({
  stepComponent,
  transformationModes,
  isHover,
  sectionIndex,
  indexStep,
  parentStep,
  setFieldValue,
  steps,
  section,
  recipe,
  fromRecipe,
  stepComponentsIndexesList
}) => {

  const getCurrentStepComponent = (stepComponent) => {
    if (stepComponent.supplierItem !== null) {
      return stepComponent
    }
    if (stepComponent.priorSteps) {
      for (const stepComponentIteration of stepComponent.priorSteps.stepComponents) {
        if (stepComponent.priorSteps) {
          return getCurrentStepComponent(stepComponentIteration)
        }
      }
    }
    return null
  }

  const priorStepComponent = getCurrentStepComponent(stepComponent) || {}
  const supplierItem = priorStepComponent.supplierItem

  const [usedSupplierItems, setUsedSupplierItems] = useState([])

  useEffect(() => {
    // setting usedSupplierItems to supplierItems that hold the pointers in db (not the denormalized supplierItems in prior steps)
    if (fromRecipe && !recipe) {
      return
    }
    const productionSteps = fromRecipe ? recipe.sections.map(section => section.productionSteps).flat() : section.productionSteps
    let stepComponents = []
    if (fromRecipe) {
      const allStepComponents = productionSteps.map(step => {
        if (step.reusable) {
          const productionStepsReusableStep = step.step.productionSteps.flat()
          return productionStepsReusableStep.map(step => step.stepComponents).flat()
        }
        if (!step.reusable) {
          return step.step.stepComponents
        }
      })
      stepComponents = allStepComponents.flat()
    } else {
      stepComponents = productionSteps.map(step => step.stepComponents).flat()
    }
    const usedSupplierItems = stepComponents.filter(stepComponent => !!stepComponent).filter(stepComponent => stepComponent.supplierItem).map(stepComponent => stepComponent.supplierItem)
    setUsedSupplierItems(usedSupplierItems)
  }, [fromRecipe, recipe, section, stepComponent])


  const transformationModesOptions = useMemo(() => {
    // retrieve supplierItem which is not from prior steps (because this one is denormalized) and therefore has transformation modes pointers ( =up to date if a transformation mode was added)
    // see KFC-2189
    if (!supplierItem) return []

    const supplierItemRef = usedSupplierItems.find(item => item.id === (supplierItem.objectId || supplierItem.id))
    const supplierItemTransformationModes = supplierItemRef?.transformationModes || []

    if (!supplierItemRef) {
      const supplierItemTransformationModes = supplierItem?.transformationModes || []
      const newTransformationModes = supplierItemTransformationModes.filter(transformationMode => transformationMode.transformationMode.transformationType === parentStep.transformation)
      return newTransformationModes.map(transformationMode => transformationMode.transformationMode)
    }

    const newTransformationModes = transformationModes.filter(transformationMode => 
      supplierItemTransformationModes.some(supplierItemTransformationMode => {
        return (
          supplierItemTransformationMode.transformationMode.objectId === transformationMode.objectId 
          && transformationMode.transformationType === parentStep.transformation
        )
      })
    )

    return newTransformationModes
  }, [usedSupplierItems, transformationModes, supplierItem, parentStep])


  const updateNestedStepComponentsStructure = (parent, indexesList, newStepComponent) => {
    let current = parent
    if (indexesList.length > 0) {
      const firstIndex = indexesList.shift()
      current = current.stepComponents[firstIndex].priorSteps
    }
    // traverse the nested stepComponents using the array of indexes passed as props through every iteration of "loopPriorSteps"
    while (indexesList.length > 1) {
      const index = indexesList.shift()
      current = current.stepComponents[index].priorSteps
    }
    const lastIndex = indexesList.at(-1)
    current.stepComponents[lastIndex] = newStepComponent
  }

  const handleChangeTransformationMode = (event) => {
    const transformationMode = transformationModesOptions.find(option => option.objectId === event.target.value)
    const newParentStep = cloneDeep(parentStep)

    let supplierItemRef = usedSupplierItems.find(item => item.id === (supplierItem.objectId || supplierItem.id))
    // KFC-2426 Bug not found anymore (probably solved in between?), we let the quick fix code below in case off.
    // if (!supplierItemRef) {
      // When we add an ingredient in an already existing priorStep in the hope than the ulteriorStep will hold the revelant transformationModes
      // it doesn't work tho (matchingTransformationModewithSupplierItem does not exist)
      // this is due to the fact that the useEffect that sets usedSupplierItems doesn't listen the section at a deep enough level (in the dependencies)
      // we'll have to refactor the code that handles usedSupplierItems to fix this
      // this is a provisional fix to avoid a blank page but it doesn't ensures that the transformationMode and transformationRate are up to date as they are not retrieved from the pointer but from a (potentially) old denormalized version of the supplierItem 
      // see KFC-2189
    //   supplierItemRef = supplierItem
    // }

    const supplierItemTransformationModes = supplierItemRef?.transformationModes || []
    const matchingTransformationModeWithSupplierItem = supplierItemTransformationModes.find(supplierItemTransformationMode => supplierItemTransformationMode.transformationMode.objectId === event.target.value)



    const transformationRate = (matchingTransformationModeWithSupplierItem?.transformationRate || matchingTransformationModeWithSupplierItem.transformationRate === 0) ? matchingTransformationModeWithSupplierItem.transformationRate : 100

    let newStepComponent = {
      ...stepComponent,
      transformationMode: transformationMode,
      transformRate: transformationRate,
    }

    newStepComponent = recalculateCostValuesPriorStep(newStepComponent)

    const stepComponentsIndexesListCopy = [...stepComponentsIndexesList]
    updateNestedStepComponentsStructure(newParentStep, stepComponentsIndexesListCopy, newStepComponent)

    if (fromRecipe) {
      const index = steps.findIndex((step) => isEqual(step.step, parentStep))
      steps[index].step = newParentStep
      setFieldValue(`sections[${sectionIndex}].productionSteps[${indexStep}].step`, newParentStep)
    }
    else {
      const index = steps.indexOf(parentStep)
      steps[index] = newParentStep
      setFieldValue(`productionSteps[${indexStep}]`, newParentStep)
    }

    updateAllRecipesFieldsOnChange({
      recipe,
      section,
      steps,
      parentStep,
      currentStepComponentChanged: newStepComponent,
      isProductionSteps: !!fromRecipe
    })
  }

  const _clearStepComponentTransformationMode = () => {
    const newParentStep = cloneDeep(parentStep)
    let newStepComponent = {
      ...stepComponent,
      transformationMode: null,
      transformRate: 100,
      netWeight: stepComponent.grossWeight
    }

    updateNestedStepComponentsStructure(newParentStep, stepComponentsIndexesList, newStepComponent)

    if (fromRecipe) {
      setFieldValue(`sections[${sectionIndex}].productionSteps[${indexStep}].step`, newParentStep)
      const index = steps.findIndex((step) => isEqual(step.step, parentStep))
      steps[index].step = newParentStep
    } else {
      const index = steps.indexOf(parentStep)
      steps[index] = newParentStep
      setFieldValue(`productionSteps[${indexStep}]`, newParentStep)
    }

    updateAllRecipesFieldsOnChange({
      recipe,
      section,
      steps,
      parentStep,
      currentStepComponentChanged: newStepComponent,
      isProductionSteps: !!fromRecipe
    })
  }

  const transformationModeFieldName = useMemo(() => {
    const stepComponentsIndexesListCopy = [...stepComponentsIndexesList]
    const baseStepComponentIndex = stepComponentsIndexesListCopy.shift()
    const basePath = fromRecipe ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${baseStepComponentIndex}].priorSteps` : `productionSteps[${indexStep}].stepComponents[${baseStepComponentIndex}].priorSteps`
    let fieldName = basePath
    while (stepComponentsIndexesListCopy.length > 1) {
      const nestedIndex = stepComponentsIndexesListCopy.shift()
      fieldName += `.stepComponents[${nestedIndex}].priorSteps`
    }
    const lastIndex = stepComponentsIndexesListCopy.at(-1)
    fieldName += `.stepComponents[${lastIndex}].transformationMode`
    return fieldName
  }, [sectionIndex, indexStep, stepComponentsIndexesList, fromRecipe])

  return (
    <>
      <StyledStepFirstBodyColumn className="flexRow center" leftStep={COLS_OFFSETS.priorStepComponents} style={{ backgroundColor: COLORS.PRODUCTION_STEPS_COMPONENT_WHITE }}>
        <Stack spacing={1}>
          <Box sx={{ height: 30 }} className="flexRow center">
            <Stack direction="row" spacing={4}>
              <StyledStepText weight={0} fontSize={12} disabled firstCol>{supplierItem && supplierItem.name || "-"}</StyledStepText>
            </Stack>
          </Box>
        </Stack>
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText weight={0} fontSize={12} disabled>
          {priorStepComponent.grossWeight ? roundNumber(convertKilosIntoGrams(priorStepComponent.grossWeight), 3) : "-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText weight={0} fontSize={12} disabled>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText weight={0} fontSize={12} disabled>
          {"-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledStepText weight={0}>
          {transformationModesOptions && isHover ? (
            <Stack className="flex1">
              <Field
                name={transformationModeFieldName}
                component={FormikEditableStepSelect}
                stepComponent={stepComponent}
                transformationModesOptions={transformationModesOptions}
                isClearable={true}
                endAdornment={<IconButton sx={{ display: stepComponent.transformationMode ? "" : "none" }} onClick={() => _clearStepComponentTransformationMode()}><ClearIcon /></IconButton>}
                onChange={handleChangeTransformationMode}
              >
                {transformationModesOptions && transformationModesOptions.map((transformation, keyIndex) => (
                  <MenuItem key={keyIndex + "-" + transformation.objectId} value={transformation.objectId}>
                    {transformation.name}
                  </MenuItem>
                ))}
              </Field>
              <ErrorMessage
                name={transformationModeFieldName}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
            </Stack>
          )
            :
            (<StyledStepText weight={0}>{priorStepComponent.transformationMode && priorStepComponent.transformationMode.name ? priorStepComponent.transformationMode.name : "-"}</StyledStepText>)
          }
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledStepText weight={0} fontSize={12} disabled>{priorStepComponent.transformRate || priorStepComponent.transformRate === 0 ? priorStepComponent.transformRate + " %" : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledStepText weight={0} fontSize={12} disabled>{priorStepComponent.netWeight || priorStepComponent.netWeight === 0 ? roundNumber(convertKilosIntoGrams(priorStepComponent.netWeight), 3) : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledStepText weight={0} fontSize={12} disabled>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledStepText weight={0} fontSize={12} disabled>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledStepText weight={0} fontSize={12} disabled>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledStepText weight={0} fontSize={12} disabled>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledStepText weight={0} fontSize={12} disabled>{"-"}</StyledStepText>
      </StyledStepBodyCell>
    </>
  )
}

export default EditablePriorStepComponent
