import React, { useEffect, useState } from "react"

import { useDispatch } from "react-redux"
import { Box, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import DataGrid from "../../../../components/DataGrid/DataGrid"
import FixedAddButton from "../../../../components/FixedAddButton"
import SupplierItemTransformationModeFormModal from "./SupplierItemTransformationModeFormModal"
import { addTransformationModesToSupplierItem, editTransformationModesToSupplierItem, removeTransformationModeFromSupplierItem } from "../../../../actions/Supplier/supplierItems"
import { TRANSFORMATION_TYPES, getTransformationTypeLabel } from "../../../../utils/supplierItemUtils"
import { isTransformationModeUsedInRecipe } from "../../../../parseManager/transformationModes/transformationModesManager"
import CantDeleteTransformationModeModal from "./CantDeleteTransformationModeModal"
import TransformationModeDeleteConfirmationModal from "./TransformationModeDeleteConfirmationModal"

const SupplierItemTransformationModes = ({ id, transformationModes, supplierItemTransformationModes = [] }) => {
	const [selectedRow, setSelectedRow] = useState(null)
  const [rowToDelete, setRowToDelete] = useState(null)
  const [openCreationDialog, setOpenCreationDialog] = useState(false)
  const [data, setData] = useState([])
  const [hoveredRowId, setHoveredRowId] = useState(null)
  const [openCanNotDeleteDialog, setOpenCanNotDeleteDialog] = useState(false)
  
  const dispatch = useDispatch()

  useEffect(() => {
    const newData = supplierItemTransformationModes.map((transformationMode) => {
      const useInRecipeCount = transformationMode.useInRecipeCount || 0
      const useInReusableStepCount = transformationMode.useInReusableStepCount || 0
      return {
        id: transformationMode.transformationMode.objectId,
        name: transformationMode.transformationMode.name,
        transformationType: getTransformationTypeLabel(transformationMode.transformationMode.transformationType),
        transformationRate: transformationMode.transformationRate,
        totalUseCount: useInRecipeCount + useInReusableStepCount,
        deletion: transformationMode.transformationMode.objectId,
      }
    })
    const sortedData = newData.sort((a, b) => a.name.localeCompare(b.name))
    setData(sortedData)
  }, [supplierItemTransformationModes])

  const closeDeleteConfirmationDialog = () => setRowToDelete(null)

  const confirmDeletion = async (transformationModeId) => {
    await dispatch(removeTransformationModeFromSupplierItem(id, transformationModeId))
    closeDeleteConfirmationDialog()
  }

  const handleDelete = (transformationModeId) => async (event) => {
    event.stopPropagation()
    const isUsedInRecipe = await isTransformationModeUsedInRecipe(id, transformationModeId)

    // open can not delete dialog if transformation mode is used in recipe
    if (isUsedInRecipe) {
      setOpenCanNotDeleteDialog(true)
      return
    }

    // open delete confirmation dialog
    const currentTransformation = supplierItemTransformationModes.find((item) => item.transformationMode.objectId === transformationModeId)
    setRowToDelete(currentTransformation)
  }

  const columns = [
    {
        key: "id",
        label: "ID",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            strict: true
        },
        width: 120
    },
    {
        key: "name",
        label: "Nom",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        },
    },
    {
        key: "transformationType",
        label: "Type de transformation",
        dataType: "text",
        sortable: false,
        filter: {
          filterable: true,
          type: "select",
          options: TRANSFORMATION_TYPES.map((type) => ({
            key: type.value,
            value: type.label,
            label: type.label
          }))
        },
        width: 400
    },
    {
      key: "transformationRate",
      label: "Rendement (%)",
      dataType: "text",
      sortable: false,
      filter: {
          filterable: false,
      },
      width: 500
    },
    {
      key: "totalUseCount",
      label: "Nombre d'utilisation du mode de transformation",
      dataType: "text",
      sortable: false,
      filter: {
          filterable: false,
      },
      width: 500
    },
    {
      key: "deletion",
      label: "",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: false,
      },
      width: 60,
      render: (value) => {
        return (
          <Box sx={{ alignSelf: "stretch", display: "flex" }}>
            <IconButton
              sx={{ visibility: value === hoveredRowId ? "visible" : "hidden" }}
              onClick={handleDelete(value)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )
      }
    },
  ]

  const toggleCreationDialog = () => setOpenCreationDialog(!openCreationDialog)
  const closeEditionDialog = () => setSelectedRow(null)

	const handleSelectRow = (row) => {
    const transformationMode = supplierItemTransformationModes.find((item) => item.transformationMode.objectId === row.id)
    setSelectedRow(transformationMode)
  }

  const handleCreation = (values) => {
    dispatch(addTransformationModesToSupplierItem(id, values))
  }

	const handleEdition = (values) => {
    dispatch(editTransformationModesToSupplierItem(id, values))
	}
  
  const handleRowMouseEnter = (row) => {
    setHoveredRowId(row.deletion)
  }

  const handleRowMouseLeave = () => {
    setHoveredRowId(null)
  }

  return (
    <div>
      <div className="flexColumn">
        <DataGrid
          title="Modes de transformation associés à l'article"
          columns={columns}
          data={data}
          withFilters
          rowLabel="modes de transformation"
          onRowClick={handleSelectRow}
          onRowMouseEnter={handleRowMouseEnter}
          onRowMouseLeave={handleRowMouseLeave}
          sxBodyCell={{ height: 60 }}
        />
        <FixedAddButton onClick={toggleCreationDialog} />
      </div> 

      {/* -------- creation modal --------  */}
      <SupplierItemTransformationModeFormModal
        open={openCreationDialog}
        onClose={toggleCreationDialog}
        onConfirm={handleCreation}
        transformationModes={transformationModes}
        supplierItemTransformationModes={supplierItemTransformationModes}
      />

      {/* -------- edition modal -------- */}
      <SupplierItemTransformationModeFormModal
        open={!!selectedRow}
        onClose={closeEditionDialog}
        onConfirm={handleEdition}
        transformationMode={selectedRow}
        transformationModes={transformationModes}
        supplierItemTransformationModes={supplierItemTransformationModes}
        isEdition
      />
      {/* can not delete modal */}
      <CantDeleteTransformationModeModal
        open={openCanNotDeleteDialog}
        onClose={() => setOpenCanNotDeleteDialog(false)}
      />
      {/* delete confirmation modal  */}
      <TransformationModeDeleteConfirmationModal
        open={!!rowToDelete}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={() => confirmDeletion(rowToDelete?.transformationMode.objectId)}
        transformationModeName={rowToDelete?.transformationMode.name}
      />
  </div>
  )
}

export default SupplierItemTransformationModes