import React, { useCallback, useEffect, useState, useLayoutEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import MoreMenu from "../../../components/MoreMenu"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderInfo from "../../../components/GenericHeaderInfo"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import OrdersHeaderTitle from "../../../components/Order/Orders/OrdersHeaderTitle"
import OrderLeftPanel from "../../../components/Order/Orders/OrderLeftPanel"
import OrderRightPanel from "../../../components/Order/Orders/OrderRightPanel"
import OrderBackModalCreate from "../../../components/Order/Orders/OrderBackModalCreate"
import OrderBackModalUpdate from "../../../components/Order/Orders/OrderBackModalUpdate"
import OrderCancelModal from "../../../components/Order/Orders/OrderCancelModal"
import OrderCommentModal from "../../../components/Order/Orders/OrderCommentModal"
import OrderDeleteModal from "../../../components/Order/Orders/OrderDeleteModal"
import OrderCancelBackModalUpdate from "../../../components/Order/Orders/OrderCancelBackModalUpdate"
import OrderValidityModal from "../../../components/Order/Orders/OrderValidityModal"
import {
    showOrdersList,
    createOrders as createOrdersAction,
    deleteOrderAction,
    updateOrderAction,
    validateOrderAction,
    cancelOrderAction,
    duplicateOrderAction
} from "../../../actions/Orders/Orders"
import {
    orderMode,
    createOrderCart,
    getOrderUpdateStatus,
    computeMinDateDelivery,
    getStockQuantity,
    isValidOrder
} from "../../../utils/ordersUtils"
import {getComingOrders} from "../../../parseManager/orders/parseOrdersManager"

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    headerBloc: {
        flex: "0 0 64px"
    },
    contentBloc: {
        backgroundColor: COLORS.LIGHT_GREY,
        flex: "1 1 auto",
        maxHeight: "calc(100vh - 64px)",
        display: "flex"
    },
    leftBloc: {
        flex: "1 1 auto"
    },
    rightBloc: {
        flex: "0 0 454px",
        borderLeft: `1px solid ${COLORS.DEFAULT_GREY}`
    },
    filterBloc: {
        display: "flex"
    }
})

const Order = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const site = useSelector(state => state.orders.site)
    const suppliers = useSelector(state => state.orders.suppliers)
    const data = useSelector(state => state.orders.data)
    const mode = useSelector(state => state.orders.mode)
    const selectedOrder = useSelector(state => state.orders.selectedOrder)
    const [showBackModal, setShowBackModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showOrderValidityModal, setShowOrderValidityModal] = useState(false)
    const [cart, setCart] = useState(selectedOrder ? createOrderCart(selectedOrder) : new Map())
    const [selectedCard, setSelectedCard] = useState(null)
    const [selectedSupplier, setSelectedSupplier] = useState(selectedOrder ? selectedOrder.supplier : null)
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(selectedOrder ? selectedOrder.expectedDeliveryDate : moment().valueOf())
    const [validatedOrder, setValidatedOrder] = useState(true)
    const [ordersComing, setOrdersComing] = useState([])

    useEffect(() => {
        if (selectedOrder) {
            selectedOrder.minDateDelivery = computeMinDateDelivery(selectedOrder.supplier.order)
            setCart(createOrderCart(selectedOrder))
            setSelectedSupplier(selectedOrder ? selectedOrder.supplier : null)
        }
    }, [selectedOrder])

    const onBack = () => {
        localStorage.setItem("useStoredDataGridFilters", "1")
        dispatch(showOrdersList(site.objectId))
    }

    useLayoutEffect(() => {

        if (selectedOrder && selectedOrder.expectedDeliveryDate && !selectedSupplier.order.expectedDeliveryDate) {
            selectedSupplier.order.expectedDeliveryDate = selectedOrder.expectedDeliveryDate
        }

        setValidatedOrder(selectedSupplier ? isValidOrder(selectedSupplier.order) : false)
    }, [cart, selectedSupplier])

    const onCloseCommentModal = () => setShowCommentModal(false)
    const onCloseCancelModal = () => setShowCancelModal(false)
    const onCloseOrderValidityModal = () => setShowOrderValidityModal(false)

    const addCart = (data) => {
        const newCart = new Map(cart)
        const cartSupplierValues = newCart.get(data.card.supplier.objectId)

        //CREATE
        if (!cartSupplierValues) {
            newCart.set(data.card.supplier.objectId, [{ card: data.card, quantity: data.quantity }])
        }
        else {
            const cardIndex = cartSupplierValues.findIndex(el => el.card.objectId === data.card.objectId)

            //UPDATE
            if (cardIndex !== -1) {
                cartSupplierValues[cardIndex].quantity = +data.quantity
                newCart.set(data.card.supplier.objectId, cartSupplierValues)
            }
            //CREATE
            else {
                newCart.set(data.card.supplier.objectId, [...cartSupplierValues, { card: data.card, quantity: data.quantity }])
            }
        }

        setCart(newCart)
        unSelectCard()
    }

    const selectCard = async (card) => {
        if (selectedCard && selectedCard.objectId === card.objectId) {
            unSelectCard()
        }
        else {
            card.stockQuantity = await getStockQuantity(card)
            const ordersComing = await getComingOrders(card.objectId, true, site.objectId)
            setOrdersComing(ordersComing)
            setSelectedCard(card)
        }
    }

    const unSelectCard = () => {
        setSelectedCard(null)
        setOrdersComing([])
    }

    const createOrders = useCallback(async () => {
        await dispatch(createOrdersAction(cart))
    }, [cart])

    const deleteOrder = useCallback(async () => {
        if (selectedOrder) {
            await dispatch(deleteOrderAction(selectedOrder.objectId))
        }
    }, [selectedOrder])

    const updateOrder = useCallback(async () => {
        if (selectedOrder) {
            await dispatch(updateOrderAction(selectedOrder.objectId, selectedSupplier, expectedDeliveryDate, cart))
        }
    }, [selectedOrder, selectedSupplier, expectedDeliveryDate, cart])

    const isOrderValid = (order, cart) => {
        const cartItems = cart.get(order.supplier.objectId) || []
        return !cartItems.some((cartItem) => cartItem.quantity === 0)
    }

    const prevalidateOrderForComment = useCallback(() => {
        if (selectedOrder) {
            if (!isOrderValid(selectedOrder, cart)) {
                setShowOrderValidityModal(true)
                return
            }
            setShowCommentModal(true)
        }
    }, [selectedOrder, cart])

    const validateOrder = useCallback(async (comment) => {
        if (selectedOrder) {
            const orderUpdateStatus = getOrderUpdateStatus(selectedOrder)

            await dispatch(validateOrderAction(selectedOrder.objectId, selectedSupplier, expectedDeliveryDate, cart, comment, orderUpdateStatus))
        }
    }, [selectedOrder, selectedSupplier, expectedDeliveryDate, cart])

    const removeCardInCart = (card) => {
        const newCart = new Map(cart)
        const cartSupplierValues = newCart.get(card.supplier.objectId)

        if (cartSupplierValues.length === 1) {
            newCart.delete(card.supplier.objectId)
        }
        else {
            newCart.set(card.supplier.objectId, cartSupplierValues.filter(el => el.card.objectId !== card.objectId))
        }

        setCart(newCart)
    }

    const cancelOrder = useCallback(async () => {
        if (!selectedOrder) return
        await dispatch(cancelOrderAction(selectedOrder.objectId))
    }, [selectedOrder])

    const duplicateOrder = useCallback(async () => {
        if (!selectedOrder) return
        await dispatch(duplicateOrderAction(selectedOrder.objectId, site.objectId))
    }, [selectedOrder, site])

    const updateExpectedDeliveryDate = async (expectedDeliveryDate) => {
        selectedOrder.supplier.order.expectedDeliveryDate = expectedDeliveryDate

        await setExpectedDeliveryDate(expectedDeliveryDate)
    }

    const actions = (callBack) => {
        switch (callBack.method) {
            case "SELECT_CARD":
                return selectCard(callBack.data.card)
            case "CANCEL_CART":
                return unSelectCard()
            case "UPDATE_CART":
                return addCart(callBack.data)
            case "CREATE":
                return createOrders()
            case "DELETE":
                return setShowDeleteModal(true)
            case "COMMENT":
                return prevalidateOrderForComment()
            case "CANCEL":
                return setShowCancelModal(true)
            case "REMOVE_CART":
                return removeCardInCart(callBack.data.card)
            case "VALIDATE":
                return validateOrder(callBack.data.comment)
            case "DELIVERY_DATE_UPDATED":
                return updateExpectedDeliveryDate(callBack.data.expectedDeliveryDate)
            default:
                return
        }
    }

    const moreMenus = useMemo(() => {
        if (selectedOrder) {
            return [{
                label: "Dupliquer la commande",
                onClick: duplicateOrder
            }]
        }
        return []
    }, [selectedOrder])

    const renderHeaderInfo = () => {
        if (mode === orderMode.UPDATE && selectedOrder) {
            return <GenericHeaderInfo info={`- ${site.name.toUpperCase()} - commande ${selectedOrder.supplier.name} n°${selectedOrder.orderNumber}`} />
        }
        else {
            return <GenericHeaderInfo info={`- ${site.name.toUpperCase()}`} />
        }
    }

    return (
        <>
            {
                site &&
                <div className={classes.container}>
                    <div className={classes.headerBloc}>
                        <GenericTopBarHeader
                            leftAction={<GenericHeaderReturnButton handleClick={() => setShowBackModal(true)} />}
                            rightAction={<MoreMenu menus={moreMenus} />}
                            title={<OrdersHeaderTitle />}
                            info={renderHeaderInfo()}
                        />
                    </div>
                    <div className={classes.contentBloc}>
                        <div className={classes.leftBloc}>
                            <OrderLeftPanel
                                isValidOrder={validatedOrder}
                                mode={mode}
                                suppliers={suppliers}
                                data={data}
                                cart={cart}
                                selectedCard={selectedCard}
                                selectedOrder={selectedOrder}
                                actions={actions}
                            />
                        </div>
                        <div className={classes.rightBloc}>
                            <OrderRightPanel
                                mode={mode}
                                order={selectedOrder}
                                selectedCard={selectedCard}
                                selectedSupplier={selectedSupplier}
                                cart={cart}
                                expectedDeliveryDate={expectedDeliveryDate}
                                ordersComing={ordersComing}
                                actions={actions}
                            />
                        </div>
                    </div>
                    {
                        mode === orderMode.CREATE &&
                        <OrderBackModalCreate
                            goBack={onBack}
                            onClose={() => setShowBackModal(false)}
                            open={showBackModal}
                        />
                    }
                    {
                        mode === orderMode.UPDATE
                            ? selectedOrder && selectedOrder.orderStatus === "DONE"
                                ? (
                                    <OrderCancelBackModalUpdate
                                        goBack={onBack}
                                        onClose={() => setShowBackModal(false)}
                                        open={showBackModal}
                                    />
                                ) : (
                                    <OrderBackModalUpdate
                                        goBack={onBack}
                                        onUpdate={updateOrder}
                                        onClose={() => setShowBackModal(false)}
                                        open={showBackModal}
                                    />
                                )
                            : null
                    }
                    {
                        showDeleteModal &&
                        <OrderDeleteModal
                            onDelete={deleteOrder}
                            onClose={() => setShowDeleteModal(false)}
                            open={showDeleteModal}
                        />
                    }
                    {
                        showCommentModal &&
                        <OrderCommentModal
                            actions={actions}
                            onClose={onCloseCommentModal}
                            open={showCommentModal}
                            isOrderCancel={selectedOrder && selectedOrder.orderStatus === "DONE"}
                        />
                    }
                    {
                        showCancelModal &&
                        <OrderCancelModal
                            onCancel={cancelOrder}
                            onClose={onCloseCancelModal}
                            open={showCancelModal}
                        />
                    }
                    {
                        showOrderValidityModal &&
                        <OrderValidityModal
                            onClose={onCloseOrderValidityModal}
                            open={showOrderValidityModal}
                        />
                    }
                </div>
            }
        </>
    )
}

export default Order