import React, { useState } from "react"
import { Box, Button, Modal, Snackbar, Stack } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { isEqual } from "lodash"

import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import MiscHeaderTitle from "../../components/Misc/MiscHeaderTitle"
import { getSupplierItemsSelector } from "../../reducers/Suppliers/SupplierItems/supplierItems"
import { loadSupplierItemsToReplace, replaceSupplierItem } from "../../actions/Supplier/supplierItems"
import ReplaceSupplierItemsModal from "../Suppliers/SupplierItems/ReplaceSupplierItemsModal"
import { startProductionItemsPubSub } from "../../actions/Misc/productionItems"
import { closeRecipeSnackBar, exportRecipes, flushExportRecipesErrors, synchronizeEtiquettable, updateAllRecipeFoodcost, updateAllRecipes } from "../../actions/Recipe/recipes"
import ExportProductsResultModal from "../../components/ExportProductsResultModal"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { exportSubcontractorsProducts, flushExportSubcontractorsProductsErrors } from "../../actions/Subcontractor/subcontractorsProducts"
import { updateProductDispatchHubPriorities } from "../../actions/Misc/productDispatch"
import ReplaceSupplierItemsErrorModal from "../Suppliers/SupplierItems/ReplaceSupplierItemsErrorModal"
import { areTransformationModesEqual } from "../../utils/transformationModes"
import { migrateRecipeComments, resetSectionProductionStepsByRecipes } from "../../actions/Misc/recipes"
import SwitchOutputProduction from "./SwitchOutputProduction"
import {updatePlanningRulesSwitchOutputProduction, uploadUnpackingStepCSVForMigration} from "../../actions/Misc/Misc"
import UnpackingStepUploadDialogForm from "./UnpackingStepUploadDialogForm"
import {recalculateTotalAmountOrders} from "../../actions/Orders/Orders"
import ReplacePackagingDialog from "../Packaging/ReplacePackagingDialog"
import { replacePackagingInRecipes } from "../../actions/Packaging/packaging"
import { getCurrentUser } from "../../reducers/Utils/app"
import { parseRecipeProductionStepsMigrationCSVFile } from "../../utils/packagingExectutionUtils"

const Misc = () => {
    // hooks
    const dispatch = useDispatch()

    // states
    const [openReplaceSupplierItemDialog, setOpenReplaceSupplierItemDialog] = useState(false)
    const [supplierItemReplaceMode, setSupplierItemReplaceMode] = useState("steps")
    const [isReplacementErrorShown, setIsErrorReplacementShown] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openUnpackingStepUploadDialog, setOpenUnpackingStepUploadDialog] = useState(false)
    const [openReplacePackingDialog, setOpenReplacePackagingDialog] = useState(false)

    // selectors
    const exportRecipesErrors = useSelector(state => state.recipes.exportRecipesErrors)
    const exportRecipesMessage = useSelector(state => state.recipes.exportRecipesMessage)
    const recipeSnackBar = useSelector(state => state.recipes.recipeSnackBar, isEqual)

    const exportSubcontractorsProductsErrors = useSelector(state => state.subcontractorsProducts.exportSubcontractorsProductsErrors)
    const exportSubcontractorsProductsMessage = useSelector(state => state.subcontractorsProducts.exportSubcontractorsProductsMessage)
    const planningRules = useSelector(state => state.misc.planningRules)
    const [switchOutputProduction, setSwitchOutputProduction] = useState(planningRules && planningRules.switchOutputProduction)
    const supplierItems = useSelector(getSupplierItemsSelector)
    const username = useSelector(getCurrentUser).get("username") || "Utilisateur inconnu"

    const toggleUnpackingStepUploadDialog = () => setOpenUnpackingStepUploadDialog(!openUnpackingStepUploadDialog)

    // errors or messages actions
    const _flushExportRecipesErrors = () => dispatch(flushExportRecipesErrors())
    const _closeRecipeSnackBar = () => dispatch(closeRecipeSnackBar(recipeSnackBar.type))
    const _flushExportSubcontractorsProductsErrors = () => dispatch(flushExportSubcontractorsProductsErrors())

    // ----------- replace supplier item ----------- //
    const handleOpenReplaceSupplierItemDialog = async (mode = "steps") => {
        setOpenReplaceSupplierItemDialog(true)
        setSupplierItemReplaceMode(mode)
        setLoading(true)
        await dispatch(loadSupplierItemsToReplace(null, true))
        setLoading(false)
    }

    const handleCloseReplaceSupplierItemDialog = () => {
        setOpenReplaceSupplierItemDialog(false)
    }

    const toggleReplacePackagingDialog = () => setOpenReplacePackagingDialog(!openReplacePackingDialog)

    const handleConfirmReplaceSupplierItem = (values) => {
        const isProductionSteps = supplierItemReplaceMode === "productionSteps"
        if (isProductionSteps) {
            const originalTransformationsModes = values.originalSupplierItem?.data?.transformationModes || []
            const replacedTransformationsModes = values.replacedSupplierItem?.data?.transformationModes || []
            if (!areTransformationModesEqual(originalTransformationsModes, replacedTransformationsModes)) {
                setIsErrorReplacementShown(true)
                return
            }
        }
        dispatch(replaceSupplierItem(values, username, isProductionSteps))
    }

    const handleConfirmReplacePackaging = (values) => {
        dispatch(replacePackagingInRecipes(values))
    }

    // ------- export production item pubsub ------- //
    const exportProductionItemsPubSub = async () => {
        setLoading(true)
        await dispatch(startProductionItemsPubSub())
        setLoading(false)
    }

    // ------- update product dispatch hub priorities ------- //
    const regulateProductDispatchHubPriorities = async () => {
        setLoading(true)
        await dispatch(updateProductDispatchHubPriorities())
        setLoading(false)
    }

    const updateCommentsFormat = async () => {
        setLoading(true)
        await dispatch(migrateRecipeComments())
        setLoading(false)
    }

    // ------- export production item pubsub ------- //
    const onUpdateAllRecipes = async () => {
        setLoading(true)
        await dispatch(updateAllRecipes())
        setLoading(false)
    }

    const calculateTotalAmountOrders = () => dispatch(recalculateTotalAmountOrders())
    const _updateAllRecipesFoodcost = () => dispatch(updateAllRecipeFoodcost())

    const _synchronizeEtiquettable = () => dispatch(synchronizeEtiquettable())

    const _exportRecipes = () => dispatch(exportRecipes())
    const _exportSubcontractorProducts = () => dispatch(exportSubcontractorsProducts())
    const handleSectionProductionStepsByRecipe = () => dispatch(resetSectionProductionStepsByRecipes())

    const actions = [
        {
            onClick: handleSectionProductionStepsByRecipe,
            label: "Vider les étapes de production des sections de recettes"
        },
        {
            onClick: toggleReplacePackagingDialog,
            label: "Remplacer un packaging dans toutes les recettes"
        },
        {
            onClick: calculateTotalAmountOrders,
            label: "Recalculer le montant total des commandes"
        },
        {
            onClick: handleOpenReplaceSupplierItemDialog,
            label: "Remplacer un article fournisseur dans toutes les recettes"
        },
        {
            onClick: () => handleOpenReplaceSupplierItemDialog("productionSteps"),
            label: "Remplacer un article fournisseur dans toutes les fiches étapes de production"
        },
        {
            onClick: _updateAllRecipesFoodcost,
            label: "Recalculer les foodcosts et poids de toutes les recettes"
        },
        {
            onClick: _synchronizeEtiquettable,
            label: "Synchroniser tous les produits internes avec Étiquettable"
        },
        {
            onClick: _exportRecipes,
            label: "Exporter tous les produits internes vers le BO Joe"
        },
        {
            onClick: _exportSubcontractorProducts,
            label: "Exporter tous les produits sous-traitants vers le BO Joe"
        },
        {
            onClick: onUpdateAllRecipes,
            label: "Recalculer, synchroniser et exporter les produits internes"
        },
        {
            onClick: exportProductionItemsPubSub,
            label: "Réaliser l'export pubsub des productionItems"
        },
        {
            onClick: regulateProductDispatchHubPriorities,
            label: "Aligner les priorités de dispatch des productDispatch"
        },
        {
            onClick: updateCommentsFormat,
            label: "Migrer le format des commentaires"
        },
        {
            onClick: toggleUnpackingStepUploadDialog,
            label: "Migrer les étapes de déconditionnement"
        },
        
    ]

    const handleSwitchOutputProduction = (checked) => {
        setSwitchOutputProduction(checked)
        dispatch(updatePlanningRulesSwitchOutputProduction(planningRules.objectId, checked))
    }

    const handleUploadUnpackingStepCSVFile = async (values) => {
        parseRecipeProductionStepsMigrationCSVFile(values.file, async(lines) => {
            await dispatch(uploadUnpackingStepCSVForMigration(lines))
        })
        toggleUnpackingStepUploadDialog()
    }

    return (
        <Box>
            <GenericTopBarHeader
                title={<MiscHeaderTitle />}
            />

            {/* ------------------------------------ */}
            {/* ------------- buttons -------------- */}
            {/* ------------------------------------ */}
            <Box
              className="flexRow center"
              sx={{ px: 4, py: 2, justifyContent: "center" }}
            >
                <SwitchOutputProduction checked={switchOutputProduction} onCheck={handleSwitchOutputProduction} />
            </Box>
            <Box sx={{ pt: 4, pb: 6 }}>
                <Stack spacing={2.6} alignItems="center">
                    {actions.map((action, index) => (
                        <Button sx={{ textTransform: "inherit", minWidth: 500, py: 1.5 }} variant="contained" onClick={action.onClick} key={index}>
                            {action.label}
                        </Button>
                    ))}
                </Stack>
            </Box>


            {/* ------------------------------------ */}
            {/* ------------- dialogs -------------- */}
            {/* ------------------------------------ */}
            <ReplaceSupplierItemsModal
                open={openReplaceSupplierItemDialog}
                onClose={handleCloseReplaceSupplierItemDialog}
                supplierItems={supplierItems}
                loading={loading}
                onConfirm={handleConfirmReplaceSupplierItem}
                isProductionSteps={supplierItemReplaceMode === "productionSteps"}
            />
            <ReplaceSupplierItemsErrorModal
                open={isReplacementErrorShown}
                onClose={() => setIsErrorReplacementShown(false)}
            />
            <ReplacePackagingDialog
                open={openReplacePackingDialog}
                onClose={toggleReplacePackagingDialog}
                onConfirm={handleConfirmReplacePackaging}
            />

            {/* ------------------------------------ */}
            {/* -------- errors or messages -------- */}
            {/* ------------------------------------ */}
            <Modal
                open={!!((exportRecipesErrors && exportRecipesErrors.length) || exportRecipesMessage)}
                onClose={_flushExportRecipesErrors}
            >
                <ExportProductsResultModal
                    errors={exportRecipesErrors}
                    message={exportRecipesMessage}
                    title="Erreur lors de l'export des produits internes"
                    subTitle="Une ou plusieurs erreurs sont survenus lors de l'export des produits internes : "
                    tableTitle="Produit Interne"
                    isFem
                    searchFieldsLabel="Rechercher un produit interne"
                    isRecipes
                    closeModal={_flushExportRecipesErrors} />
            </Modal>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={recipeSnackBar.open}
                autoHideDuration={recipeSnackBar.duration}
                onClose={_closeRecipeSnackBar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeRecipeSnackBar}
                    variant={recipeSnackBar.type}
                    message={recipeSnackBar.message}
                />
            </Snackbar>

            <Modal
                aria-labelledby="subcontractor-product-title"
                aria-describedby="subcontractor-product-description"
                open={!!((exportSubcontractorsProductsErrors && exportSubcontractorsProductsErrors.length) || exportSubcontractorsProductsMessage)}
                onClose={_flushExportSubcontractorsProductsErrors}
            >
                <ExportProductsResultModal
                    errors={exportSubcontractorsProductsErrors}
                    message={exportSubcontractorsProductsMessage}
                    title="Erreur lors de l'export de produits sous-traitants"
                    subTitle="Une ou plusieurs erreurs sont survenus lors de l'export de produits sous traitant : "
                    tableTitle="Produit sous-traitant"
                    isFemine={false}
                    isRecipes={false}
                    searchFieldsLabel="Rechercher un produit sous-traitant"
                    closeModal={_flushExportSubcontractorsProductsErrors} />
            </Modal>
            <UnpackingStepUploadDialogForm
                open={openUnpackingStepUploadDialog}
                onClose={toggleUnpackingStepUploadDialog}
                onConfirm={handleUploadUnpackingStepCSVFile}
            />
        </Box>
    )
}

export default Misc
