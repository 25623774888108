import React, { useRef } from "react"
import { Form, Formik } from "formik"
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    IconButton,
    DialogTitle,
    FormHelperText,
    Stack,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import * as Yup from "yup"
import SectionsField from "./SectionsField"

const sectionSchema = Yup.object().shape({
    counterWeighing: Yup.object().shape({
        weight: Yup.number(),
        reason: Yup.string().when("weight", (weight, schema) => {
            return weight > 0
                ? schema.required("Veuillez sélectionner le motif de cette contre-pesée.")
                : schema.notRequired()
        }),
    })
})

const schema = Yup.object().shape({
    sections: Yup.array().of(sectionSchema).test(
        "at-least-one-weight",
        "Veuillez compléter la section sur laquelle vous souhaitez faire votre contre-pesée.",
        (sections) => sections?.some(section => section.counterWeighing?.weight)
    ).min(1, "Veuillez sélectionner au moins une section.")
})

const sx = {
    dialog: {
        "& .MuiDialog-paper": {
            minWidth: 700,
            padding: "32px 24px",
        },
    },
    dialogTitle: {
        color: "#262626",
        fontFamily: "Roboto",
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: 1.33,
    },
    dialogContent: {
        p: 0,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "32px",
        marginBottom: "32px",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}

const SectionsSelectionDialogForm = ({
    onClose,
    open,
    onSubmit,
    packagingExecution,
}) => {
    const formikRef = useRef(null)

    const handleConfirm = () => {
        if (!formikRef?.current) return
        formikRef.current.submitForm()
    }

    const _handleSubmit = (values) => {
        onSubmit(values)
    }

    const handleCancel = () => {
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} scroll="body" sx={sx.dialog}>
            <DialogTitle sx={{ p: 0 }}>
                {packagingExecution?.uniqueCode} - {packagingExecution?.recipeName}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCancel}
                sx={{ position: "absolute", top: 8, right: 8 }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={sx.dialogContent}>
                <DialogContentText>
                    Choisissez la section sur laquelle vous devez faire une contre-pesée.
                </DialogContentText>
                <Box>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            sections: packagingExecution?.sections || [],
                            events: packagingExecution?.events || [],
                        }}
                        validationSchema={schema}
                        onSubmit={_handleSubmit}
                    >
                        {({ errors, setFieldValue, values, setFieldTouched, touched }) => {
                            return (
                                <Form>
                                    <Stack spacing={1}>
                                        <SectionsField
                                            options={packagingExecution?.sections}
                                            sections={values.sections}
                                            events={values.events}
                                            setFieldValue={setFieldValue}
                                            errors={errors?.sections}
                                            setFieldTouched={setFieldTouched}
                                            touched={touched}
                                        />
                                        {errors?.sections && typeof errors.sections === "string" && (
                                            <FormHelperText error>{errors.sections}</FormHelperText>
                                        )}
                                    </Stack>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </DialogContent>
            <DialogActions  sx={sx.dialogActions}>
                <Button onClick={onClose} color="primary">
                    Retour
                </Button>
                <Button onClick={handleConfirm} color="primary" variant="contained">
                    Suivant
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SectionsSelectionDialogForm
