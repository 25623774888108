import React, { useState, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import DataGrid from "../../components/DataGrid/DataGrid"
import PackagingExecutionStatus from "./PackagingExecutionStatus"
import { PE_STATUSES, PE_STATUSES_AND_LABELS } from "../../utils/packagingExecutions"
import { timestampToDate } from "../../utils"
import PackagingExecutionCommentBadge from "./PackagingExecutionCommentBadge"
import {
	updatedSectionsCounterWeight,
	showPackagingExecution,
	showPackagingExecutionsHoursEdit,
} from "../../actions/PackagingExecution/packagingExecution"
import RecipeDialogForm from "./counterWeighing/RecipeDialogForm"
import SectionsSelectionDialogForm from "./counterWeighing/SectionsSelectionDialogForm"
import PreparedPackagingDialogForm from "./counterWeighing/PreparedPackagingDialogForm"
import PEReprodDialogForm from "./reprod/PEReprodDialogForm"
import PEReprodConfirmationDialog from "./reprod/PEReprodConfirmationDialog"
import { generatePackagingExecutionReprodBySections } from "../../actions/ProductionSteps/Reproduction"

const HOURS_FORMAT = "HH:mm"

const PackagingExecutionTable = ({ packagingExecutions, packagingExecutionsByStatus, status = "TODO", date, site }) => {
	const dispatch = useDispatch()

  const [selectedPackagingExecution, setSelectedPackagingExecution] = useState(null)

	// 1. first dialog (recipe)
	const [isOpenRecipeDialog, setIsOpenRecipeDialog] = useState(false)
	// 2. second dialog (sections counter weight)
  const [isOpenSectionsDialog, setIsOpenSectionsDialog] = useState(false)
	// 3. third dialog (prepared packaging) (only for inProgress status)
  const [isOpenPreparedPackagingDialog, setIsOpenPreparedPackagingDialog] = useState(false)
	// 4. fourth dialog (reprod)
	const [isOpenReprodDialog, setIsOpenReprodDialog] = useState(false)
	// 5. fifth dialog (reprod confirmation)
  const [isOpenReprodConfirmationDialog, setIsOpenReprodConfirmationDialog] = useState(false)
  const [peFormValues, setPEFormValues] = useState(null)

	const [tableData, setTableData] = useState([])
	const [commentModalShown, setCommentModalShown] = useState(false)
	const [selectedComments, setSelectedComments] = useState([])

	// update the local selectedPackagingExecution when the packagingExecutions list (from store) change
	useEffect(() => {
		if (!selectedPackagingExecution) return

		const currentSelectedPackagingExecution = packagingExecutions.find(pe => pe.objectId === selectedPackagingExecution.objectId)
		setSelectedPackagingExecution(currentSelectedPackagingExecution)
	}, [packagingExecutions])

	useEffect(() => {
		const formattedData = packagingExecutionsByStatus.map(pe => {
			const theoreticalStart = timestampToDate(pe.theoreticalStartTime, HOURS_FORMAT) || ""
			const theoreticalEnd = timestampToDate(pe.theoreticalEndTime, HOURS_FORMAT) || ""
			const startTime = timestampToDate(pe.startTime, HOURS_FORMAT) || ""
			const endTime = timestampToDate(pe.endTime, HOURS_FORMAT) || ""
			return {
				id: pe.objectId,
				theoreticalTime: theoreticalStart + " - " + theoreticalEnd,
				time: startTime + " - " + endTime,
				recipe: `${pe.uniqueCode || ""} - ${pe.recipeName || ""}`,
				packagingLine: pe.packagingLine?.name || "",
				expectedPackagingNumber: pe.expectedPackagingNumber || 0,
				status: PE_STATUSES_AND_LABELS.find(elem => elem.key === pe.status)?.label || "",
				comments: (pe.comments || []),
			}
		})
		setTableData(formattedData)
	}, [packagingExecutionsByStatus])
	
  const toggleOpenRecipeDialog = () => setIsOpenRecipeDialog(!isOpenRecipeDialog)
  const toggleOpenPreparedPackagingDialog = () => setIsOpenPreparedPackagingDialog(!isOpenPreparedPackagingDialog)
  const toggleOpenReprodDialog = () => setIsOpenReprodDialog(!isOpenReprodDialog)
  const toggleOpenReprodConfirmationDialog = () => setIsOpenReprodConfirmationDialog(!isOpenReprodConfirmationDialog)

	const tableTitle = useMemo(() => {
		const commonTitle = "Barquettages"
		switch (status) {
			case PE_STATUSES.toValidate:
				return `${commonTitle} à valider`
			case PE_STATUSES.todo:
				return `${commonTitle} à démarrer`
			case PE_STATUSES.inProgress:
				return `${commonTitle} en cours`
			case PE_STATUSES.done:
				return `${commonTitle} terminés`
			default:
				return commonTitle
		}
	}, [status])

	const goToPackagingExecutionHoursEdit = () => {
		const localStorage = window.localStorage
		localStorage.setItem("packagingExecutionStatus", status)
		dispatch(showPackagingExecutionsHoursEdit(site.objectId, date))
	}

	const moreMenus = useMemo(() => {
		const counterWeightOption = {
			label: "Faire une contre-pesée/reprod",
			onClick: toggleOpenRecipeDialog
		}
		if (status === PE_STATUSES.toValidate || status === PE_STATUSES.todo) {
			return [
				{
					label: "Éditer les heures et les lignes de barquettage",
					onClick: goToPackagingExecutionHoursEdit
				},
				counterWeightOption
			]
		}
		return [counterWeightOption]
	}, [status])

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				label: "Heure théorique",
				key: "theoreticalTime",
				dataType: "text",
				sortable: true,
				filter: {
					filterable: false,
				},
				statuses: [PE_STATUSES.toValidate, PE_STATUSES.todo, PE_STATUSES.locked, PE_STATUSES.inProgress]
			},
			{
				label: "Heure réelle",
				key: "time",
				dataType: "text",
				sortable: true,
				filter: {
					filterable: false
				},
				statuses: [PE_STATUSES.done]
			},
			{
				label: "Recette",
				key: "recipe",
				sortable: false,
				filter: {
					filterable: true,
					type: "text"
				},
			},
			{
				label: "Ligne de barquettage",
				key: "packagingLine",
				sortable: false,
				dataType: "text",
				filter: {
					filterable: true,
					type: "select",
					options: site.packagingLines?.map(packagingLine => ({
						key: packagingLine.objectId,
						value: packagingLine.name,
						label: packagingLine.name
					})) || []
				},
				width: 250
			},
			{
				label: "Nb théorique de produits barquettés",
				key: "expectedPackagingNumber",
				dataType: "number",
				sortable: false,
				filter: {
					filterable: false,
				}
			},
			{
				label: "État du barquettage",
				key: "status",
				sortable: false,
				filter: {
					filterable: false,
				},
				render: (statusLabel) => {
					const statusValue = PE_STATUSES_AND_LABELS.find(elem => elem.label === statusLabel)?.key
					return <PackagingExecutionStatus statusValue={statusValue} statusName={statusLabel} />
				}
			},
			{
				label: "",
				key: "comments",
				sortable: false,
				filter: {
					filterable: false
				},
				render: (comments) => {
					return <PackagingExecutionCommentBadge count={comments.length} handleClick={() => showComments(comments)} />
				},
				width: 100
			}]
		return defaultColumns.filter(({ statuses }) => !statuses || statuses.includes(status))
	}, [status, site])

	// 1. recipe selection form
	const handleSubmitRecipe = (values) => {
    setSelectedPackagingExecution(values.recipe)
    setIsOpenSectionsDialog(true)
  }

	// 2. sections counter weight form
  const handleSubmitSections = async (values) => {
		if (!selectedPackagingExecution) return
		await dispatch(updatedSectionsCounterWeight(selectedPackagingExecution.objectId, values))
    setIsOpenSectionsDialog(false)

		// open 3th dialog (prepared packaging) if status is inProgress
		if (selectedPackagingExecution?.status === PE_STATUSES.inProgress) {
			toggleOpenPreparedPackagingDialog()
			return
		}

		// open 4th dialog (reprod) if status is other than inProgress
		setIsOpenReprodDialog(true)
  }

	// 3. prepared packaging form (only for inProgress status)
	const handleSubmitPreparedPackaging = async (values) => {
		if (selectedPackagingExecution) {
			setSelectedPackagingExecution(prev => ({ ...prev, realizedNumber: values.realizedNumber }))
		}
    setIsOpenRecipeDialog(false)

		// open 4th dialog (reprod) if status is other than inProgress
		setIsOpenReprodDialog(true)
  }

	// 4. reprod form
	const handleSubmitReprod = (values) => {
		if (!selectedPackagingExecution) return
    setPEFormValues(values)
		setIsOpenReprodConfirmationDialog(true)

		setIsOpenReprodDialog(false)
	}

	// 5. reprod confirmation
	const handleConfirmReprod = async () => {
		await dispatch(generatePackagingExecutionReprodBySections(peFormValues))
		setIsOpenReprodConfirmationDialog(false)
  }
	const showComments = (comments) => {
		console.log("to be continued", commentModalShown, selectedComments)

		setSelectedComments(comments)
		setCommentModalShown(true)
	}

	const handleCloseSectionsDialog = () => {
    setIsOpenSectionsDialog(false)
    toggleOpenRecipeDialog()
	}

	const onClickPe = (pe) => {
			dispatch(showPackagingExecution(site.objectId, date, pe.id))
	}

	return (
		<>
			<DataGrid
				title={tableTitle}
				menus={moreMenus}
				columns={columns}
				data={tableData}
				onRowClick={onClickPe}
				withFilters
			/>
			{/* 1. recipe selection dialog */}
			<RecipeDialogForm
				onClose={toggleOpenRecipeDialog}
				open={isOpenRecipeDialog}
				onSubmit={handleSubmitRecipe}
			/>
			{/* 2. sections modal */}
			<SectionsSelectionDialogForm
        packagingExecution={selectedPackagingExecution}
        open={isOpenSectionsDialog}
        onClose={handleCloseSectionsDialog}
        onSubmit={handleSubmitSections}
      />
			{/* 3. prepared packaging form */}
			<PreparedPackagingDialogForm
        open={isOpenPreparedPackagingDialog}
        onClose={toggleOpenPreparedPackagingDialog}
        packagingExecution={selectedPackagingExecution}
        onSubmit={handleSubmitPreparedPackaging}
      />
			{/* 4. reprod dialog */}
			<PEReprodDialogForm
        packagingExecution={selectedPackagingExecution}
        open={isOpenReprodDialog}
        onClose={toggleOpenReprodDialog}
        onSubmit={handleSubmitReprod}
      />
			{/* 5. reprod confirmation dialog */}
			<PEReprodConfirmationDialog
        open={isOpenReprodConfirmationDialog}
        onClose={toggleOpenReprodConfirmationDialog}
        onConfirm={handleConfirmReprod}
        sections={peFormValues?.sections}
      />
		</>
	)


}

export default PackagingExecutionTable