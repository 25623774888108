export const areTransformationModesEqual = (originalTransformationModes = [], replacedTransformationModes = []) => {
  if (originalTransformationModes.length !== replacedTransformationModes.length) {
    return false
  }
  for (const originalTransformationMode of originalTransformationModes) {
    const matchingTransformationMode = replacedTransformationModes.find(replacedTransformationMode => {
      return originalTransformationMode.transformationMode.objectId === replacedTransformationMode.transformationMode.objectId
    })
    if (!matchingTransformationMode) {
      return false
    }
  }
  return true
}

export const formatTransformationIdentifier = (supplierItemId, transformationModeId) => {
	return `SupplierItem${supplierItemId} - TransformationMode${transformationModeId}`
}
