import React, { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const sx = {
		title: {
			fontSize: "14px",
			lineHeight: 1.571,
			color: COLORS.BLACK800,
			fontWeight: 500,
		}
}

const PEReprodTableContainer = ({
	icon,
	title,
	description,
	children
}) => {
	return (
    <>
      <Stack spacing={1} direction="row">
        <img src={`/img/packagingExecutions/${icon}`} alt={icon} />
        <Typography variant="h6" sx={sx.title}>
          {title}
        </Typography>
      </Stack>
			{description && (
				<Typography sx={{ fontSize:"16px", lineHeight:"24px", color:"#7C7C7C"}}>
					{description}
			</Typography>
			)}
      {children}
    </>
	)
}


export default PEReprodTableContainer
