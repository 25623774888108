import React from "react"
import { makeStyles } from "@mui/styles"

import { useSelector } from "react-redux"
import { Stack, Typography} from "@mui/material"

import ProductionStepExecutionStatus from "../../ProductionStepExecutions/ProductionStepExecutionsStatus"
import { getPSEStatusLabel } from "../../../utils/productionStepExecution"
import { StyledItemLabel, StyledItemValue } from "../../ProductionStepExecutions/styledComponents"
import { PEPSEResumeContainer } from "../../ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import { getNameInEmail } from "../../../utils"

const useStyles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1,
        color: "#000",
        fontWeight: 500,
    },
})

const OrderReceptionResume = () => {
    const classes = useStyles()
    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)

    const weightOrder = orderReception?.totalWeight
    return (
        <PEPSEResumeContainer sx={{ position: "relative", alignSelf: "stretch" }}>
            <Stack spacing={4}>
                {/* head */}
                <Stack spacing={2.2} direction="row" alignItems="center">
                    <Typography variant="h1" className={classes.title}>
                        Commande n°{orderReception && orderReception.orderNumber}
                    </Typography>
                    <ProductionStepExecutionStatus
                    statusValue={orderReception && orderReception.receptionStatus}
                    statusName={getPSEStatusLabel(orderReception && orderReception.receptionStatus)}
                    />
                </Stack>
                {/* resume */}
                <Stack spacing={4} direction="row">
                    {/* transformation */}
                    <Stack spacing={1}>
                        <StyledItemLabel>
                            Fournisseur
                        </StyledItemLabel>
                        <StyledItemValue>
                            {orderReception && orderReception.supplier && orderReception.supplier.name}
                        </StyledItemValue>
                    </Stack>
                    {/* recipe & section */}
                    <Stack spacing={1}>
                        <StyledItemLabel>
                            Montant total
                        </StyledItemLabel>
                        <StyledItemValue>
                            {orderReception && orderReception.totalAmount} €
                        </StyledItemValue>
                    </Stack>
                    <Stack spacing={1}>
                        <StyledItemLabel>
                            Poids total
                        </StyledItemLabel>
                        <StyledItemValue>
                            {weightOrder} kg
                        </StyledItemValue>
                    </Stack>
                    <Stack spacing={1}>
                        <StyledItemLabel>
                            Passeur de commande
                        </StyledItemLabel>
                        <StyledItemValue>
                            {orderReception && orderReception.user && orderReception.user.email && orderReception.user.email.indexOf("@") !== -1 ? getNameInEmail(orderReception.user.email) : ""}
                        </StyledItemValue>
                    </Stack>
                </Stack>
            </Stack>
        </PEPSEResumeContainer>
                     
    )
}

export default OrderReceptionResume
