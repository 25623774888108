import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import { Button, Card, Stack, styled } from "@mui/material"
import WeightInput from "./WeightInput"
import { COLORS } from "../../../utils"

const primaryColor = COLORS.BLACK800
const errorColor = COLORS.RED_ERROR_LIGHT
const activeColor = COLORS.PRIMARY_COLOR

const StyleCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "hasError"
})(({ isSelected = false, hasError = false }) => {
  const styles = {
    padding: "0px 16px",
    borderRadius: "6px",
    border: "1px solid #E6E6E6",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    cursor: "pointer",
    boxShadow: "none",
    flex: 1,
    height: "140px",
    "& img": {
      width: "32px",
      height: "32px",
    },
  }

  if (isSelected && !hasError) {
    styles.border = "2px solid " + activeColor
  } else if (hasError) {
    styles.border = "1px solid " + errorColor
  }

  return styles
})

const sx = {
  weightContainer: {
    padding: "32px 0px",
    gap: "16px"
  },
  reasonLabel: {
    color: primaryColor,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "157.1%",
    textAlign: "center",
  },
}

const reasons = [
  { value: "broken", label: "Casse", icon: "packaging-broken" },
  { value: "weighing-regularization", label: "Régularisation de la pesée", icon: "packaging-weighing-regularization" },
  { value: "other", label: "Autre", icon: "packaging-help" },
]

const SectionField = ({
  sections = [],
  events = [],
  errors,
  setFieldValue,
  sectionIndex,
  section,
  setFieldTouched,
  touched,
}) => {
  const [isReset, setIsReset] = useState(false)
  const [hasPreviousCounterWeighing, setHasPreviousCounterWeighing] = useState(false)

  useEffect(() => {
    if (section?.counterWeighing) {
      setHasPreviousCounterWeighing(true)
    }
  }, [])

  const handleSelectReason = (reason, sectionIndex) => {
    setFieldValue(`sections[${sectionIndex}].counterWeighing.reason`, reason.value)
  }

  const handleChangeWeight = (sectionIndex) => (value) => {
    setFieldValue(`sections[${sectionIndex}].counterWeighing.weight`, value)
    if (!setFieldTouched) return
    setFieldTouched(`sections[${sectionIndex}].counterWeighing.weight`)
  }

  /**
   * @NOTE realWeight (only for in progress status) should be preserved?
   */
  const handleResetSection = (section, sectionIndex) => {
    const prevSection = { ...section }

    if (!isReset) {
      const event = {
        type: "COUNTER_WEIGHING",
        ...prevSection.counterWeighing,
      }
      const sectionEvents = [...events, event]
      setFieldValue("events", sectionEvents)
    }

    if (prevSection.counterWeighing) {
      delete prevSection.counterWeighing
    }

    setFieldValue(`sections[${sectionIndex}]`, prevSection)
    setIsReset(true)
  }

  const hasGlobalError = errors && typeof errors === "string"

  return (
    <Stack alignItems="center" spacing={16 / 6}>
      {/* ------- top: weight ------- */}
      <WeightInput
        value={sections[sectionIndex]?.counterWeighing?.weight || 0}
        onChange={handleChangeWeight(sectionIndex)}
        label="Poids initial"
        // TODO: when the counter-weighing is done, the initialProductionWeight should be the counter-weighing weight?
        valueLabel={section?.initialProductionWeight}
        subtitle="kg"
        inputLabel="Poids de la contre-pesée"
        hasError={
          // individual section error: when weight is not defined
          !!(errors && errors[sectionIndex]?.counterWeighing?.weight)
          // global error: for all sections
          || !!hasGlobalError
        }
        sxRoot={sx.weightContainer}
        isTouched={touched?.sections && touched.sections[sectionIndex]?.counterWeighing?.weight}
      />
      {/* -------- bottom: reason -------- */}
      <Stack spacing={16 / 6} sx={{ alignSelf: "stretch" }}>
        {/* list of reasons */}
        <Stack direction="row" sx={{ gap: "16px", alignSelf: "stretch" }}>
          {reasons.map((reason, reasonIndex) => {
            const isSelectedReason = sections[sectionIndex]?.counterWeighing?.reason === reason.value
            return (
              <StyleCard
                key={reason.value + reasonIndex}
                onClick={() => handleSelectReason(reason, sectionIndex)}
                isSelected={isSelectedReason}
                hasError={
                  // individual section error: when weight is defined but reason is not
                  !!errors && errors[sectionIndex]?.counterWeighing?.reason
                  // global error: for all sections
                  || !!hasGlobalError
                }
              >
                <img alt={reason.label} src={`/icons/${reason.icon}${isSelectedReason ? "-active" : ""}.svg`} />
                <Typography sx={sx.reasonLabel}>
                  {reason.label}
                </Typography>
              </StyleCard>
            )
          })}
        </Stack>
        {/* reset button */}
        {hasPreviousCounterWeighing && (
          <div>
            <Button variant="outlined" color="primary" onClick={() => handleResetSection(section, sectionIndex)}>
              Faire une nouvelle contre-pesée
            </Button>
          </div>
        )}
        {/* reason error message */}
        {errors && errors[sectionIndex]?.counterWeighing?.reason && (
          <Typography color="error" variant="caption">
            {errors[sectionIndex]?.counterWeighing.reason}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default SectionField 