import React from "react"
import {
	Button,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogActions,
	styled,
} from "@mui/material"

const StyledDialog = styled(Dialog)({
	"& .MuiPaper-root": {
		width: 500,
		padding: 10
	},
})

const CantDeleteTransformationModeModal = ({
	onClose, open,
}) => {
	return (
		<StyledDialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
				Suppression du mode de transformation impossible
			</DialogTitle>
			<DialogContent sx={{ mt: 1 }}>
				Ce mode de transformation est utilisé dans certaines recettes. Si vous souhaitez le supprimer, il faut d’abord faire en sorte qu’il n’apparaisse sur aucune fiche technique
			</DialogContent>
			<DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button onClick={onClose} color="primary">
					Retour
				</Button>
			</DialogActions>
		</StyledDialog>
	)
}

export default CantDeleteTransformationModeModal
