import React from "react"
import moment from "moment"
import Parse from "parse"
import { v4 as uuidv4 } from "uuid"
import { isEqual, cloneDeep } from "lodash"

import { productType } from "./dispatchUtils"
import { ACTIVE_KFC_BRANDS, ACTIVE_KFC_BRANDS_NAMES } from "../utils"
import { MachineTypes } from "../parseManager/machines/machineTypesManager"
import { KitchenArea } from "../parseManager/kitchenAreas/kitchenAreasManager"
import { ReusableProductionStep } from "../parseManager/recipe/parseReusableProductionStepsManager"
import { recipeSectionsFormInitialValues } from "../actions/Utils/utils"
import { formatValuesWithWeight, convertKilosIntoGrams } from "../utils/ressources"
import { isFalsyOtherThanZero, roundCurrency, roundNumber } from "../utils"
import { DEFAULT_STEP_DATE } from "./productionStepExecution"
import { COLLECTION_NAMES } from "../parseUtils"

const Packaging = Parse.Object.extend("Packaging")
const Section = Parse.Object.extend("Section")
const CookingMode = Parse.Object.extend("CookingMode")
const SupplierItems = Parse.Object.extend("SupplierItems")

export const ProductionStep = Parse.Object.extend("ProductionStep")

export const priorStepNoName = "NO_NAME"

export const STEP_DURATION_UNITS = ["kg / heure", "minute"]

const headers = [
    { label: "Poids en entrée (g)" },
    { label: "Prix au kg (€)" },
    { label: "Foodcost (€)" },
    { label: "Transformation" },
    { label: "Rendement (%)" },
    { label: "Poids en sortie (g)" },
    { label: "Atelier" },
    { label: "Machine" },
    { label: "Paramétrage machine" },
    { label: "Durée de l'étape (valeur)" },
    { label: "Durée de l'étape (unité)" },
    { label: "Jour de l'étape (VS jour de prod)" },
    { label: "Pesée en fin d'étape" },
    { label: "" }
]

export const REUSABLE_STEPS_TABLE_HEADS = [
    { label: "Étape / Article" },
    ...headers
]

export const RECIPE_STEPS_TABLE_HEADS = [
    { label: "Section / Étape / Article" },
    ...headers
]

export async function getPackagingCost(id) {
    if (!id) {
        return 0
    }
    const packaging = await new Parse.Query(Packaging)
        .equalTo("objectId", id)
        .first()
    if (packaging && packaging.get) {
        return roundCurrency(packaging.get("price"))
    }

    return 0
}

export function getSubPackagingCost(subPackaging) {
    if (!subPackaging) {
        return 0
    }

    let finalPrice = 0
    for (const i in subPackaging) {
        finalPrice += roundCurrency(subPackaging[i].get("price"))
    }

    return finalPrice
}

export async function getExistingSection(sectionId) {
    const section = await new Parse.Query(Section)
        .equalTo("objectId", sectionId)
        .first()

    return section
}

export const getCookingMode = async (cookingModeId) => {
    const cookingMode = await new Parse.Query(CookingMode)
        .equalTo("objectId", cookingModeId)
        .first()

    return cookingMode
}

export const getIngredient = async (supplierItemId) => {
    const supplierItem = await new Parse.Query(SupplierItems)
        .notEqualTo("deleted", true)
        .equalTo("objectId", supplierItemId)
        .first()

    return supplierItem
}

export const formatCommercialNameInPriorSteps = async (commercialName) => {
    const commercialNameParseObj = await new Parse.Query("CommercialName")
        .equalTo("objectId", commercialName.objectId)
        .select("name")
        .first()

    return {
        objectId: commercialName.objectId,
        name: commercialNameParseObj?.get("name") || "pas de nom commercial",
        className: "commercialName",
    }
}


const INGREDIENT_FIELDS_NOT_TO_SAVE = ["error", "emptyComponent"]

const removeIngredientFieldsToSave = (ingredient) => {
    Object.keys(ingredient).forEach((key) => {
        if (
            ingredient[key] === null
            || INGREDIENT_FIELDS_NOT_TO_SAVE.includes(key)
        ) {
            delete ingredient[key]
        }

        // only if priorSteps exists to avoid infinite loop
        if (key === "priorSteps" && ingredient.priorSteps) {
            removeIngredientFieldsToSave(ingredient.priorSteps)
        }
    })
}

export const formatIngredientTransformationModeToSave = (transformationMode) => {
    return {
        objectId: transformationMode.objectId,
        name: transformationMode.name,
        transformationType: transformationMode.transformationType,
        className: "transformationModes",
    }
}
export const formatIngredientsToSave = async (ingredients, percent = false, isPriorSteps = false, unitWeightsCache = new Map()) => {

    // interact here to remove it at each level
    const newIngredients = []

    for (const ingredient of ingredients) {
        delete ingredient.error
        delete ingredient.emptyComponent
        delete ingredient.stepRootIndex
        removeIngredientFieldsToSave(ingredient)

        ingredient.grossWeight = ingredient.grossWeight ?
            ((percent) ? getIngredientNetWeight(ingredient, percent) : parseFloat(ingredient.grossWeight))
            : 0

        if (ingredient.cookingMode) {
            ingredient.cookingMode = await getCookingMode(ingredient.cookingMode)
        }
        if (ingredient.supplierItem) {
            ingredient.supplierItem = isPriorSteps ? await formatSupplierItemInPriorSteps(ingredient.supplierItem) : await getIngredient(ingredient.supplierItem.id)
            if (!isPriorSteps) await setServingUnit(ingredient, ingredient.supplierItem.id)
        }
        if (ingredient.transformationMode) {
            ingredient.transformationMode = formatIngredientTransformationModeToSave(ingredient.transformationMode)
        }
        // Initializes unit weights in stepComponent root
        if (!ingredient.priorSteps && !isPriorSteps) {
            await setUnitWeightInStepComponent(ingredient, unitWeightsCache)
        }
        if (ingredient.priorSteps && !isPriorSteps) {
            formatSupplierItemsDataAtPriorStepsRoot(ingredient, unitWeightsCache)
        }
        if (ingredient.priorSteps && ingredient.priorSteps.stepComponents) {
            const formattedPriorStepComponents = await formatIngredientsToSave(ingredient.priorSteps.stepComponents, percent, true, unitWeightsCache)
            ingredient.priorSteps.stepComponents = formattedPriorStepComponents
        }

        if (ingredient.priorSteps && ingredient.priorStepsSupplierItems) {
            for (const priorItem of ingredient.priorStepsSupplierItems) {
                if (priorItem.supplierItem) {
                    await setServingUnit(priorItem, priorItem.supplierItem.objectId)
                }
            }
        }
        // Set unit weights in priorStepsSupplierItems root
        if (ingredient.priorSteps && !isPriorSteps) {
            setUnitWeightsInPriorStepsSupplierItems(ingredient.priorStepsSupplierItems, unitWeightsCache)
        }

        newIngredients.push(ingredient)
    }

    return newIngredients
}

// See KFC-2189 we need to save on the root of ingredient the info pertaining to supplier items to know which supplierItems are used
export const formatSupplierItemsDataAtPriorStepsRoot = (ingredient) => {
    const supplierItemsByIndex = generateIngredientsMapByIndex({ stepComponents: ingredient.priorSteps.stepComponents })
    const priorStepsSupplierItems = []
    for (const { supplierItem, netWeight, grossWeight, transformRate, transformationMode, unitGrossWeight, unitNetWeight, index } of supplierItemsByIndex.values()) {
        const supplierItemPointer = {
            __type: "Pointer",
            className: COLLECTION_NAMES.supplierItem,
            objectId: supplierItem.objectId || supplierItem.id
        }
        let transformationModePointer = null
        if (transformationMode) {
            transformationModePointer = {
                __type: "Pointer",
                className: COLLECTION_NAMES.transformationMode,
                objectId: transformationMode.objectId
            }
        }
        const priorStepSupplierItemData = {
            supplierItem: supplierItemPointer,
            netWeight,
            grossWeight,
            transformRate,
            unitGrossWeight,
            unitNetWeight,
            index
        }
        if (transformationModePointer) {
            priorStepSupplierItemData.transformationMode = transformationModePointer
        }
        priorStepsSupplierItems.push(priorStepSupplierItemData)
    }
    ingredient.priorStepsSupplierItems = priorStepsSupplierItems
}

export async function createStep(steps, ingredientsField = "ingredients", percent = false) {
    const newSteps = []

    for (const step of steps) {
        delete step.error
        delete step.index
        delete step.preventGrossWeightChange
        step[ingredientsField] = await formatIngredientsToSave(step[ingredientsField], percent)
        newSteps.push(step)
    }

    return newSteps
}

const createProductionStepsMachineTypePointer = (step) => {
    const machineType = new MachineTypes()
    machineType.id = step.machineType.objectId
    step.machineType = machineType
}

const createProductionStepsKitchenAreaPointer = (step) => {
    const kitchenArea = new KitchenArea()
    kitchenArea.id = step.kitchenArea.objectId
    step.kitchenArea = kitchenArea
}

export const createOrUpdateProductionStep = async (steps, percent = false, isRecipe = false) => {
    const newSteps = []
    let allHiddenSteps = true
    const unitWeightsCache = new Map() // Initialize a map to store the production steps unit weights if servingUnit is "piece". This map will be used to reference the base values for recalculating subsequent steps.

    for (const currentStep of steps) {
        const step = getProductionStepPointerObj(currentStep, isRecipe)
        const fieldsNotToSave = ["error", "preventGrossWeightChange", "outputWeight", "inputWeight", "totalNetWeight", "totalGrossWeight", "totalInputWeight", "totalOutputWeight", "ingredients", "stepRootIndex"]
        fieldsNotToSave.forEach(field => {
            if (step[field] !== null) {
                delete step[field]
            }
        })

        if (step.isReusable) {
            const reusableProductionStep = await new Parse.Query(ReusableProductionStep)
                .equalTo("objectId", step.objectId)
                .first()
            const originalReusableProductionStep = parseReusableProductionStepToObject(reusableProductionStep.toJSON(), percent, unitWeightsCache)
            const stepObj = isRecipe ? getProductionStepFieldsToSave(reusableProductionStep, currentStep, originalReusableProductionStep) : reusableProductionStep

            newSteps.push(stepObj)

            if (!originalReusableProductionStep.hidden) allHiddenSteps = false
        } else {
            // ---------- old productionStep ---------- //
            allHiddenSteps = false
            let productionStep
            if (step.objectId) {
                productionStep = await new Parse.Query(ProductionStep)
                    .equalTo("objectId", step.objectId)
                    .first()

                // step machine
                if (step.machineType) {
                    // check if old machine type is not the same than the new one, then save it
                    if (productionStep.has("machineType")) {
                        const oldMachineType = productionStep.get("machineType")
                        if (oldMachineType.id !== step.machineType.objectId) {
                            createProductionStepsMachineTypePointer(step)
                        } else {
                            // if it the same, no need to update the field with the same value
                            delete step.machineType
                        }
                    } else {
                        createProductionStepsMachineTypePointer(step)
                    }
                }

                // step machine
                if (step.kitchenArea) {
                    // check if old machine type is not the same than the new one, then save it
                    if (productionStep.has("kitchenArea")) {
                        const oldMachineType = productionStep.get("kitchenArea")
                        if (oldMachineType.id !== step.kitchenArea.objectId) {
                            createProductionStepsKitchenAreaPointer(step)
                        } else {
                            // if it the same, no need to update the field with the same value
                            delete step.kitchenArea
                        }
                    } else {
                        createProductionStepsKitchenAreaPointer(step)
                    }
                }
            } else {
                // ---------- new productionStep ---------- //
                productionStep = new ProductionStep()
                if (step.machineType) {
                    createProductionStepsMachineTypePointer(step)
                }
                if (step.kitchenArea) {
                    createProductionStepsKitchenAreaPointer(step)
                }
            }

            // ---------- update productionStep ---------- //
            if (productionStep) {
                // set the stepComponents later (below)
                const fieldsNotToSet = ["stepComponents", "objectId"]
                const fieldsToSetEvenZero = ["stepDate", "endStepWeighing"]

                Object.keys(step).forEach(field => {
                    if (step[field] && !fieldsNotToSet.includes(field)) {
                        // remove all falsy except 0
                        if (isFalsyOtherThanZero(step[field])) {
                            productionStep.set(field, step[field])
                        }
                    } else if (fieldsToSetEvenZero.includes(field)) {
                        productionStep.set(field, step[field])
                    }
                })

                // TODO: remove this filter when saving step components to database
                const filteredStepComponents = step.stepComponents?.filter(stepComponent => {
                    return !stepComponent.error && (stepComponent.supplierItem || stepComponent.priorSteps)
                }) || []
                const stepComponents = await formatIngredientsToSave(filteredStepComponents, percent, false, unitWeightsCache)

                productionStep.set("stepComponents", stepComponents)

                const newProductionStep = await productionStep.save(null, global.USE_MASTER_KEY)
                const originalReusableProductionStep = parseReusableProductionStepToObject(newProductionStep.toJSON(), percent)
                const stepObj = isRecipe ? getProductionStepFieldsToSave(newProductionStep, currentStep, originalReusableProductionStep) : newProductionStep

                newSteps.push(stepObj)
            }
        }
    }

    return [newSteps, allHiddenSteps]
}

const setSectionStepsField = async (section, sectionValues) => {
    // production steps (pointers)
    // remove empty production step
    const [productionSteps, allHiddenSteps] = await createOrUpdateProductionStep(sectionValues.productionSteps, false, true) // isRecipe
    section.set("productionSteps", productionSteps)

    // Check visibility in production and packaging
    if (allHiddenSteps) {
        section.set("packagingDisplay", false)
    } else if (section.has("packagingDisplay")) {
        section.unset("packagingDisplay")
    }

    // steps (objects)
    const steps = await createStep(sectionValues.steps)
    section.set("steps", steps)
}

export const updateSection = async (section, sectionValues) => {
    section.set("name", sectionValues.name)
    section.set("description", sectionValues.description)
    section.set("netWeight", sectionValues.netWeight)
    section.set("cost", sectionValues.cost)

    await setSectionStepsField(section, sectionValues)

    section.set("reusable", sectionValues.reusable)
    section.set("parentId", (sectionValues.parentId) ? sectionValues.parentId : null)
    section.set("parentPercent", (sectionValues.parentPercent) ? sectionValues.parentPercent : 0)
    section.set("grossWeight", sectionValues.grossWeight)

    const newSection = await section.save()
    return newSection
}

export const createSection = async (sectionValues) => {
    const section = new Section()

    section.set("name", sectionValues.name)
    section.set("description", sectionValues.description)
    section.set("netWeight", sectionValues.netWeight)
    section.set("cost", sectionValues.cost)

    await setSectionStepsField(section, sectionValues)

    section.set("reusable", sectionValues.reusable)
    section.set("print", sectionValues.print)
    section.set("parentId", sectionValues.parentId ? sectionValues.parentId : null)
    section.set("parentPercent", sectionValues.parentPercent ? sectionValues.parentPercent : 0)

    const newSection = await section.save()
    return newSection
}

export const parseSectionToParse = async (sections) => {
    const finalSections = []

    for (const section of sections) {
        if (section.id !== null) {
            const existingSection = await getExistingSection(section.id)
            if (!section.generic) {
                const parseExistingSection = await parseSectionToObject([existingSection])[0]
                if (!compareSections(parseExistingSection, section)) {
                    const updatedSection = await updateSection(existingSection, section)
                    finalSections.push(updatedSection)
                } else {
                    finalSections.push(existingSection)
                }
            } else {
                finalSections.push(existingSection)
            }
        }
        else {
            if (!isDefaultSection(section)) {
                const newSection = await createSection(section)
                finalSections.push(newSection)
            }
        }
    }

    return finalSections
}

export function parseCookingModesToObject(cookingModes) {
    return cookingModes && cookingModes.map(cookingMode => {
        return {
            cookingMode: {
                id: cookingMode.cookingMode.get ? cookingMode.cookingMode.id : cookingMode.cookingMode.objectId,
                name: (cookingMode.cookingMode.get ? cookingMode.cookingMode.get("name") : cookingMode.cookingMode.name) || ""
            },
            transformRate: cookingMode.transformRate !== null ? parseFloat(cookingMode.transformRate) : 100
        }
    })
}

export function parseSupplierItemToObject(ingredient, isProductionSteps = false) {
    const commercialName = (ingredient.get ? ingredient.get("commercialName") : ingredient.commercialName) || null
    let name = "Pas de nom commercial"
    if (commercialName) {
        if (commercialName.get && commercialName.get("name")) {
            name = commercialName.get("name")
        } else {
            name = commercialName.name
        }
    }
    const complexity = commercialName ? (commercialName.get ? commercialName.get("complexity") : commercialName.complexity) || 0 : 42
    const id = commercialName ? (commercialName.get ? commercialName.get("id") : (commercialName.id || commercialName.objectId)) : null

    let allergens = []
    if (commercialName) {
        if (commercialName.get && commercialName.get("allergens")) {
            allergens = commercialName.get("allergens") || []
        } else {
            allergens = commercialName.allergens || []
        }

    }

    let cookingModes = null
    if (ingredient.get) {
        cookingModes = parseCookingModesToObject(ingredient.get("cookingModes"))
    } else {
        cookingModes = parseCookingModesToObject(ingredient.cookingModes)
    }

    let ingredientName = ""
    if (ingredient.get) {
        ingredientName = ingredient.get("name").toLowerCase()
    } else {
        ingredientName = ingredient.name.toLowerCase()
    }

    return {
        id: ingredient.id || ingredient.objectId,
        index: uuidv4(),
        name: ingredientName,
        pricePerKg: (ingredient.get ? ingredient.get("pricePerKg") : ingredient.pricePerKg) || null,
        cookingModes,
        commercialName: isProductionSteps ? commercialName : name,
        commercialNameId: id,
        allergens: allergens.map(allergen => ({
            allergenId: allergen.get ? allergen.id : allergen.objectId,
            name: allergen.get ? allergen.get("name") : allergen.name
        })),
        complexity: complexity,
        transformationModes: ingredient.transformationModes,
        units : ingredient.units || []
    }
}

export function parseIngredientsListToObject(ingredients = [], percent = false, isProductionSteps = false, stepIndexesMap) {
    return ingredients.map(ingredient => {
        // --------- cooking mode --------- //
        let cookingMode = null
        if (ingredient.cookingMode) {
            cookingMode = ingredient.cookingMode.get ? ingredient.cookingMode.id : ingredient.cookingMode.objectId
        }

        if (isProductionSteps && ingredient.priorSteps && !ingredient.supplierItem) {
            let sumIngredients = { grossWeight: 0, netWeight: 0 }
            /** for a stepComponent with priorSteps we need to calculate grossWeight, netWeight for all subComponents**/
            sumIngredients = updatePriorStepValuesForEachChild(ingredient, sumIngredients)
            ingredient.grossWeight = sumIngredients.grossWeight
            ingredient.netWeight = sumIngredients.netWeight
        }

        const returnedIngredient = {
            index: ingredient.index || uuidv4(),
            // level 1 step index
            stepRootIndex: ingredient.priorSteps && stepIndexesMap ? stepIndexesMap.get(ingredient.priorSteps.index) : undefined,
            grossWeight: ((false !== percent) ? getIngredientNetWeight(ingredient, percent) : parseFloat(ingredient.grossWeight)) || 0,
            cookingMode,
            priorSteps: ingredient.priorSteps ? computePriorStepsData(ingredient.priorSteps) : null,
            supplierItem: ingredient.supplierItem ? parseSupplierItemToObject(ingredient.supplierItem, isProductionSteps) : null,
            transformationMode: ingredient.transformationMode ? ingredient.transformationMode : null,
            transformRate: ingredient?.transformRate,
            netWeight: ingredient.netWeight ? parseFloat(ingredient.netWeight) : null,
            ...(!ingredient.priorSteps && { servingUnit: ingredient.servingUnit || "weight"}),
            error: !isProductionSteps && (ingredient.supplierItem
                ?
                (ingredient.grossWeight && ingredient.grossWeight !== 0
                    ?
                    false
                    :
                    true
                )
                :
                (percent !== 0 && !ingredient.priorSteps))
        }

        if (ingredient.complexity) {
            // --------- complexity --------- //
            let complexity = 0
            if (ingredient.supplierItem) {
                if (ingredient.supplierItem.get && ingredient.supplierItem.get("commercialName")) {
                    complexity = ingredient.supplierItem.get("commercialName").get("complexity")
                } else {
                    const commercialName = ingredient.supplierItem.commercialName
                    complexity = commercialName?.get ? commercialName.get("complexity") : commercialName?.complexity
                }
            }
            returnedIngredient.complexity = complexity
        }

        return returnedIngredient
    })
}

export function parseStepsToObject(steps, percent = false) {
    return steps.map(step => {
        return {
            name: step.name || "",
            index: step.index || uuidv4(),
            description: step.description || "",
            ingredients: step.ingredients ? parseIngredientsListToObject(step.ingredients, percent) : [getDefaultIngredients()],
            error: step.description && step.description !== "" ? false : true,
            grossWeight: (false !== percent) ?
                (step.grossWeight || 0) :
                ((step.grossWeight || 0) * percent) / 100,
            preventGrossWeightChange: true
        }
    })
}

export function computePriorStepsData(priorSteps) {
    let grossWeight = 0, pricePerKg = 0, realCost = 0, netWeight = 0

    const stepComponents = priorSteps.stepComponents || []
    stepComponents.forEach(stepComponent => {
        grossWeight += stepComponent.grossWeight ? parseFloat(stepComponent.grossWeight) : 0
        pricePerKg += stepComponent.supplierItem ? stepComponent.supplierItem.pricePerKg : 0
        realCost += stepComponent.realCost ? stepComponent.realCost : 0
        netWeight += stepComponent.netWeight ? stepComponent.netWeight : 0
    })

    priorSteps.grossWeight = grossWeight
    priorSteps.pricePerKg = pricePerKg
    priorSteps.realCost = realCost
    priorSteps.netWeight = netWeight

    return priorSteps
}

export const parseProductionStepToObject = (step, percent = false, stepIndexesMap) => {
    const outputValues = {
        objectId: step.objectId,
        name: step.name || "",
        index: step.index || uuidv4(),
        description: step.description || "",
        stepComponents: step.stepComponents && step.stepComponents.length > 0
            ? parseIngredientsListToObject(step.stepComponents, percent, true, stepIndexesMap)
            : [getDefaultStepComponents()],
        error: step.description && step.description !== "" ? false : true,
        grossWeight: (false !== percent) ?
            (step.grossWeight || 0) :
            ((step.grossWeight || 0) * percent) / 100,
        preventGrossWeightChange: true,
        // new fields
        kitchenArea: step.kitchenArea || null,
        transformation: step.transformation || "",
        machineType: step.machineType || null,
        machineSetting: step.machineSetting || "",
        stepDuration: step.stepDuration || 0,
        stepDurationUnit: step.stepDurationUnit || "",
        stepDate: step.stepDate || DEFAULT_STEP_DATE,
        endStepWeighing: (step.endStepWeighing || step.endStepWeighing === false) ? step.endStepWeighing : true,
    }

    if (stepIndexesMap) {
        outputValues.stepRootIndex = stepIndexesMap.get(step.objectId)
    }

    return outputValues
}

export const parseProductionStepToObjectToSave = (step, percent = false) => {
    const newStep = {
        ...parseProductionStepToObject(step, percent),
        netWeight: step.netWeight,
        grossWeight: step.grossWeight,
        foodcost: step.foodcost,
        realCost: step.realCost,
    }

    return newStep
}

export const parseSectionProductionStepsToObject = (steps, percent = false) => {
    return steps.map((step) => {
        const stepJson = step.step.toJSON()
        // reusable production steps
        if (stepJson.productionSteps) {
            const newReusableStep = parseReusableProductionStepToObject(stepJson, percent)
            const computedReusableSteps = computeRecipeReusableStepWeights(newReusableStep, step.netWeight)
            const coeff = computedReusableSteps.netWeight / newReusableStep.netWeight

            computedReusableSteps.realCost = computedReusableSteps.realCost * coeff

            computedReusableSteps.cost = computedReusableSteps.cost * coeff

            return {
                ...step,
                step: computedReusableSteps,
                coeff,
                netWeight: computedReusableSteps.netWeight
            }

        }

        const newStep = parseProductionStepToObject(stepJson, percent)

        return {
            ...step, // other fields
            step: newStep // from a pointer
        }
    })
}

export const parseProductionStepsToObject = (steps, percent = false, unitWeightsCache = null) => {
    // keep the same index for the root step used in each prior steps child with the same step
    const stepIndexesMap = new Map()
    return steps.map((step, index) => {
        if (step.productionSteps) {
            return parseReusableProductionStepToObject(step, percent, unitWeightsCache)
        }

        if (unitWeightsCache && step.stepComponents.length) updateUnitWeightsCacheForReusableStep(step, unitWeightsCache)

        stepIndexesMap.set(step.index, index)
        return parseProductionStepToObject(step, percent, stepIndexesMap)
    })
}

export const parseProductionStepsToSave = (steps, percent = false) => {
    // keep the same index for the root step used in each prior steps child with the same step
    return steps.map((step) => {
        // reusable step called in recipe
        if (step.productionSteps) {
            return parseReusableProductionStepToObject(step, percent)
        }

        return parseProductionStepToObjectToSave(step, percent)
    })
}

export const parseInitialProductionStepsToObject = (steps, percent = false) => {
    // keep the same index for the root step used in each prior steps child with the same step
    const stepIndexesMap = new Map()
    return steps.map((step, index) => {
        if (step.productionSteps) {
            stepIndexesMap.set(step.index, index) // group by random index
            const stepObj = parseReusableProductionStepToObject(step, percent)
            const newStep = recalculateStepComponentsCost(stepObj)
            computeStepData(newStep, "stepComponents", false) // update all weight
            return newStep
        }

        stepIndexesMap.set(step.index, index) // group by random index
        const stepObj = parseProductionStepToObject(step, percent, stepIndexesMap)
        const newStep = recalculateStepComponentsCost(stepObj)
        computeStepData(newStep, "stepComponents", false)
        return newStep
    })
}

/**
 * parent reusable step
 * @param {*} step 
 * @param {*} percent 
 * @returns 
 */
export const parseReusableProductionStepToObject = (step, percent = false, unitWeightsCache = null) => {
    const { reusableStepTotalGrossWeight, netWeight, cost, realCost } = formatValuesWithWeight(step)

    return {
        objectId: step.objectId,
        name: step.name || "",
        index: step.index || uuidv4(),
        isReusable: true,
        error: step.name !== "" ? false : true,
        productionSteps: step.productionSteps
            ? parseProductionStepsToObject(step.productionSteps, percent, unitWeightsCache)
            : [],
        grossWeight: reusableStepTotalGrossWeight,
        netWeight,
        cost,
        realCost,
        ...(step.hidden !== undefined && { hidden: step.hidden }),
    }
}

export const parseReusableProductionStepsToObject = (steps, percent = false) => {
    return steps.map((step) => {
        return parseReusableProductionStepToObject(step, percent)
    })
}


export const parseSectionToObject = (sections, percent = false) => {
    return sections.map((section) => {
        const parseProductionSteps = section.get("productionSteps") || []

        // the format is different from 
        let productionSteps = parseSectionProductionStepsToObject(parseProductionSteps, percent)

        if (!productionSteps || !productionSteps.length) {
            productionSteps = [getDefaultProductionStepObj()]
        }

        return {
            id: section.id,
            index: uuidv4(),
            name: section.get("name") || "",
            generic: section.get("reusable") !== null ? section.get("reusable") : false,
            print: section.get("print") !== null && section.get("print") !== undefined ? section.get("print") : true,
            reusable: false,
            steps: section.get("steps") ? parseStepsToObject(section.get("steps"), percent) : [],
            productionSteps,
            error: section.get("name") && section.get("name") !== "" ? false : true,
            parentId: section.get("parentId") ? section.get("parentId") : null,
            parentPercent: section.get("parentPercent") && section.get("parentPercent") ? section.get("parentPercent") : 0,
            grossWeight: (false !== percent) ?
                (section.get("grossWeight") || 0) :
                ((section.get("grossWeight") || 0) * percent) / 100,
            preventGrossWeightChange: true,
        }
    })
}

export function getSectionGrossWeight(section) {
    if (!section) return ""
    const steps = section.get ? section.get("steps") : section.steps
    if (steps && steps.length > 0) return Number(
        steps.map(
            el => el.ingredients.map(ingredient => ingredient.grossWeight).reduce((a, b) => Number(a) + Number(b))
        ).reduce((a, b) => Number(a) + Number(b))
    )

    return ""
}

export function getStepGrossWeight(step) {
    return Number(step.ingredients.map(ingredient => ingredient.grossWeight).reduce((a, b) => Number(a) + Number(b)))
}

export function isDefaultIngredients(ingredient) {
    const defaultIngredient = getDefaultIngredients()

    if ((ingredient.cookingMode !== defaultIngredient.cookingMode) || ingredient.cookingMode === undefined) {
        return false
    }
    if (ingredient.grossWeight !== defaultIngredient.grossWeight) {
        return false
    }
    if (ingredient.supplierItem !== defaultIngredient.supplierItem) {
        return false
    }

    return true
}

export function isDefaultStep(step) {
    const defaultStep = getDefaultSteps()

    if (step.name !== defaultStep.name) {
        return false
    }
    if (step.description !== defaultStep.description) {
        return false
    }
    if (step.ingredients && defaultStep.ingredients && step.ingredients.length !== defaultStep.ingredients.length) {
        return false
    }
    for (const j in step.ingredients) {
        if (!isDefaultIngredients(step.ingredients[j])) {
            return false
        }
    }

    return true
}

export function isDefaultSection(section) {
    const defaultSection = getDefaultSection()

    if (section.name !== defaultSection.name) {
        return false
    }
    if (section.description !== defaultSection.description) {
        return false
    }
    for (const i in section.steps) {
        if (!isDefaultStep(section.steps[i])) {
            return false
        }
    }

    return true
}

export function compareKey(a, b, key, isFloat) {
    if (a[key] !== null && b[key] === null) {
        return false
    }
    if (a[key] === null && b[key] !== null) {
        return false
    }
    if (key !== "") {
        return isFloat ? (parseFloat(a[key]) === parseFloat(b[key])) : (a[key] === b[key])
    }
    return true
}

export function compareIngredients(a, b) {
    if (!compareKey(a, b, "cookingMode", false)) {
        return false
    }
    if (!compareKey(a, b, "grossWeight", true)) {
        return false
    }
    if (!a.supplierItem || !b.supplierItem) {
        return false
    }
    if (!compareKey(a.supplierItem, b.supplierItem, "id", false)) {
        return false
    }
    if (!compareKey(a.supplierItem, b.supplierItem, "name", false)) {
        return false
    }
    return true
}

export const compareProductionSteps = (oldStep, newStep) => {
    if (!compareSteps(oldStep, newStep)) {
        return false
    }
    for (const i in oldStep.stepComponents) {
        if (!compareStepComponents(oldStep.stepComponents[i], newStep.stepComponents[i])) {
            return false
        }
    }

    return true
}

export function compareSectionProductionSteps(oldStep, newStep) {
    const oldStepObj = oldStep.step
    for (const i in oldStep.stepComponents) {
        if (!compareStepComponents(oldStepObj.stepComponents[i], newStep.stepComponents[i])) {
            return false
        }
    }

    return true
}

export function compareStepComponents(a, b) {
    if (a.supplierItem && b.supplierItem && a.supplierItem.id === b.supplierItem.id) {
        return true
    }

    return false
}
export function compareSteps(a, b) {
    if (!compareKey(a, b, "name", false)) {
        return false
    }
    if (!compareKey(a, b, "description", false)) {
        return false
    }
    if (!compareKey(a, b, "grossWeight", false)) {
        return false
    }
    if (!compareKey(a.ingredients, b.ingredients, "", false)) {
        return false
    }
    if (a.ingredients.length !== b.ingredients.length) {
        return false
    }
    for (const j in a.ingredients) {
        if (!compareIngredients(a.ingredients[j], b.ingredients[j])) {
            return false
        }
    }
    return true
}

export const compareSections = (oldSection, newSection) => {
    if (!compareKey(oldSection, newSection, "name", false)) {
        return false
    }
    if (!compareKey(oldSection, newSection, "parentId", false)) {
        return false
    }
    if (!compareKey(oldSection, newSection, "parentPercent", false)) {
        return false
    }
    if (!compareKey(oldSection, newSection, "grossWeight", false)) {
        return false
    }
    if (!compareKey(oldSection.steps, newSection.steps, "", false)) {
        return false
    }
    if (oldSection.steps.length !== newSection.steps.length) {
        return false
    }
    for (const i in oldSection.steps) {
        if (!compareSteps(oldSection.steps[i], newSection.steps[i])) {
            return false
        }
    }

    for (const i in oldSection.productionSteps) {
        if (!compareProductionSteps(oldSection.productionSteps[i].step, newSection.productionSteps[i])) {
            return false
        }

        // TODO: may be add other fields here later
    }

    return true
}

export function isSectionsError(sections) {
    for (const i in sections) {
        const currentSection = sections[i]
        if (currentSection.error) {
            return true
        }
        for (const j in currentSection.steps) {
            const currentStep = currentSection.steps[j]
            if (currentStep.error) {
                return true
            }
            for (const k in currentStep.ingredients) {
                const currentIngredient = currentStep.ingredients[k]
                if (currentIngredient.error) {
                    return true
                }
            }
        }
    }
    return false
}

export function isGenericSection(newSection, genericSections) {
    const parseGenericSections = parseSectionToObject(genericSections)

    for (const i in parseGenericSections) {
        const currentGeneric = parseGenericSections[i]
        if (compareSections(newSection, currentGeneric)) {
            return { success: true, genericSection: currentGeneric }
        }
    }

    return { success: false, genericSection: null }
}

export function computePackagingData(packaging, subPackagings, packagings) {
    let cost = 0

    if (packagings) {
        const packagingIds = [].concat(packaging, subPackagings.map(subPackaging => subPackaging.value))
        const selectedPackagings = packagings.filter(packaging => packagingIds.includes(packaging.id))
        for (const i in selectedPackagings) {
            const current = selectedPackagings[i]
            cost += parseFloat(current.get("price"))
        }
    }

    return { cost: cost }
}

// NOT USED ANYMORE
// export function calculPackagingLogData(recipe) {
//     let costFC = 0
//     let costSZ = 0

//     if (recipe.packagingFC){
//         costFC += parseFloat(recipe.packagingFC.price)
//     }

//     if (recipe.packagingSZ){
//         costSZ += parseFloat(recipe.packagingSZ.price)
//     }

//     if (recipe.subPackagingFC){
//         for (const i in recipe.subPackagingFC){
//             const current = recipe.subPackagingFC[i]
//             costFC += parseFloat(current.price)
//         }
//     }

//     if (recipe.subPackagingSZ){
//         for (const i in recipe.subPackagingSZ){
//             const current = recipe.subPackagingSZ[i]
//             costSZ += parseFloat(current.price)
//         }
//     }

//     return {costFC: costFC, costSZ: costSZ}
// }

/**
 * @TODO Remove this old bad code when old recipe sheet not used anymore.
 */
export function calculRecipeData(recipeSections, /*isLog = false*/) {
    let netWeight = 0
    let cost = 0

    for (const i in recipeSections) {
        const current = recipeSections[i]
        const sectionData = calculSectionData(current.steps, /*isLog*/)
        netWeight += sectionData.netWeight
        cost += sectionData.cost
    }

    return { netWeight: netWeight, cost: cost }
}

/**
 * @TODO Remove this old bad code when old recipe sheet not used anymore.
 */
export function calculSectionData(sectionSteps, /*isLog = false*/) {
    let netWeight = 0
    let cost = 0

    for (const i in sectionSteps) {
        const current = sectionSteps[i]
        const stepData = calculStepData(current.ingredients, /*isLog*/)
        netWeight += stepData.netWeight
        cost += stepData.cost
    }

    return { netWeight: netWeight, cost: cost }
}

/**
 * @TODO Remove this old bad code when old recipe sheet not used anymore.
 */
export function calculStepData(stepIngredients, /*isLog = false*/) {
    let netWeight = 0
    let cost = 0

    for (const i in stepIngredients) {
        const current = stepIngredients[i]
        if (current.ingredient !== null) {
            const ingredientData = computeIngredientData(current, /*isLog*/)
            netWeight += ingredientData.netWeight
            cost += ingredientData.cost
        }
    }

    return { netWeight: netWeight, cost: cost }
}

/**
 * @TODO Remove this old bad code when old recipe sheet not used anymore.
 */
export function calculIngredientData(ingredient, /*isLog = false*/) {
    if (ingredient.supplierItem.cookingModes === null || ingredient.supplierItem.cookingModes === undefined) {
        return { netWeight: 0, cost: 0 }
    }

    const grossWeight = ingredient.grossWeight
    let currentCookingMode
    // if (isLog){
    //     currentCookingMode = ingredient.ingredient.cookingModes.filter(cookingMode =>
    //         cookingMode.cookingMode && ingredient.cookingMode && cookingMode.cookingMode.objectId === ingredient.cookingMode.objectId
    //     )
    // }
    // else {
    currentCookingMode = ingredient.supplierItem.cookingModes.filter(cookingMode =>
        cookingMode.cookingMode && cookingMode.cookingMode.id === ingredient.cookingMode
    )
    //}

    const transformRate = currentCookingMode.length === 1 ? parseFloat(currentCookingMode[0].transformRate) : 0
    const netWeight = (parseFloat(grossWeight) * (transformRate / 100)) || 0
    const cost = (ingredient.supplierItem.pricePerKg * parseFloat(grossWeight)) || 0

    return { netWeight: netWeight, cost: cost }
}

/**
 * Here we assume that a computed data can change only if numbered values changes in an ingredient.
 * If ingredientIndex is null, it means it's a new or a removed ingredient
 */
export function computeRecipeDataOnFieldChange(recipe, sectionIndex = null, stepIndex = null, ingredientIndex = null) {
    if (null !== sectionIndex) {
        const section = recipe.sections[sectionIndex]

        if (null !== stepIndex) {
            const step = section.steps[stepIndex]

            if (null !== ingredientIndex) {
                const ingredient = step.ingredients[ingredientIndex]
                const { cost, realCost, grossWeight, netWeight, transformRate, cookingModeLabel } = computeIngredientData(ingredient)
                ingredient.grossWeight = grossWeight
                ingredient.netWeight = netWeight
                ingredient.cost = cost
                ingredient.realCost = realCost
                ingredient.transformRate = transformRate
                ingredient.cookingModeLabel = cookingModeLabel
            }

            computeStepData(step)
        }

        computeSectionData(section, "steps", "ingredients")
    }

    computeRecipeData(recipe)
}

/**
 * Here we assume that a computed data can change only if numbered values changes in an ingredient.
 * If ingredientIndex is null, it means it's a new or a removed ingredient
 */
export const computeProductionStepsRecipeOnFieldChange = ({
    recipe,
    sectionIndex = null,
    stepIndex = null,
    stepComponentIndex = null,
    isProductionStep = false,
}) => {
    if (sectionIndex !== null) {
        // to avoid 0
        const section = recipe.sections[sectionIndex]

        if (stepIndex !== null) {
            const step = section.productionSteps[stepIndex]
            const pointerStep = getProductionStepPointerObj(step, isProductionStep)
            if (stepComponentIndex !== null) {
                const stepComponent = pointerStep.stepComponents[stepComponentIndex]
                const {
                    cost,
                    realCost,
                    grossWeight,
                    netWeight,
                    transformRate,
                    cookingModeLabel
                } = computeIngredientData(stepComponent, "productionSteps")
                stepComponent.grossWeight = grossWeight
                stepComponent.netWeight = netWeight
                stepComponent.cost = cost
                stepComponent.realCost = realCost
                stepComponent.transformRate = transformRate
                stepComponent.cookingModeLabel = cookingModeLabel
            }

            computeStepData(step, "stepComponents", isProductionStep)
        }

        computeSectionData(section, "productionSteps", "stepComponents", isProductionStep)
    }

    computeRecipeData(recipe)
}

export const computeReusableProductionStepsOnFieldChange = (
    reusableStep,
    stepIndex = null,
    stepComponentIndex = null
) => {
    if (stepIndex !== null) {
        const step = reusableStep.productionSteps[stepIndex]

        if (stepComponentIndex !== null) {
            const stepComponent = step.stepComponents[stepComponentIndex]

            if (stepComponent) {
                const {
                    cost,
                    realCost,
                    grossWeight,
                    netWeight,
                    cookingModeLabel
                } = computeIngredientData(stepComponent, "productionSteps")
                stepComponent.grossWeight = grossWeight
                stepComponent.netWeight = netWeight
                stepComponent.cost = cost
                stepComponent.realCost = realCost
                stepComponent.cookingModeLabel = cookingModeLabel
            }

            //   New: reusable steps
            if (step.isReusable && step.productionSteps) {
                step.productionSteps.forEach((subStep) => {
                    subStep.stepComponents.forEach((subStepComponent) => {
                        const {
                            cost,
                            realCost,
                            grossWeight,
                            netWeight,
                            transformRate,
                            cookingModeLabel
                        } = computeIngredientData(subStepComponent, "productionSteps")
                        stepComponent.grossWeight = grossWeight
                        stepComponent.netWeight = netWeight
                        stepComponent.cost = cost
                        stepComponent.realCost = realCost
                        stepComponent.transformRate = transformRate
                        stepComponent.cookingModeLabel = cookingModeLabel
                    })

                    computeStepData(subStep, "stepComponents")
                })
            }
        }

        computeStepData(step, "stepComponents")
    }
}

//=========================================
//========= Compute functions =============
//=========================================

/**
 * @TODO if you look well at the 3 functions above, it's basically the same functions
 * We should write a unique one with generic code
 * 
 * there are to step field
 * 1. ingredients: (deprecated)
 * 2. stepsComponent
 */

const getIngredientsFromReusableStepsAndStandardSteps = (steps, ingredientsField = "stepComponents", isProductionSteps) => {
    const ingredientsInOrder = []
    for (const currentStep of steps) {
        const step = getProductionStepPointerObj(currentStep, isProductionSteps)
        if (currentStep.reusable) {
            if (step.productionSteps) {
                for (const productionStep of step.productionSteps) {
                    const stepComponents = productionStep[ingredientsField] || []
                    stepComponents.forEach((stepComponent) => ingredientsInOrder.push(stepComponent))
                }
            }
        } else {
            const stepComponents = step[ingredientsField] || []
            stepComponents.forEach((stepComponent) => ingredientsInOrder.push(stepComponent))
        }
    }
    return ingredientsInOrder
}

const sortRecipeStepsByLast = (recipeSections, stepsField) => {
    const sections = cloneDeep(recipeSections).reverse()
    const reversedSteps = []
    sections.forEach((section) => {
        const sectionSteps = cloneDeep(section[stepsField] || []).reverse()
        sectionSteps.forEach((step) => {
            if (step.reusable) {
                const currentStep = step.step
                const reversedProductionSteps = (currentStep.productionSteps || []).toReversed()
                currentStep.productionSteps = reversedProductionSteps
                step.step = currentStep
            }
        })
        reversedSteps.push(...sectionSteps)
    })
    return reversedSteps
}

/** use "index" field to identify the singularity of an ingredient
 * this index is the same from prior step to original, allowing us to count an ingredient only once if it's used multiple times
 */

const generateIngredientsMapByIndex = ({ stepComponents = [], ingredientsField = "stepComponents", ingredientsMap = new Map()}) => {
    for (const stepComponent of stepComponents) {
        const netWeight = stepComponent.netWeight || 0
        const grossWeight = stepComponent.grossWeight || 0
        const supplierItem = stepComponent.supplierItem
        const priorSteps = stepComponent.priorSteps
        const unitGrossWeight = stepComponent.unitGrossWeight
        const unitNetWeight = stepComponent.unitNetWeight
        const index = stepComponent.index
        const transformRate = stepComponent.transformRate
        const transformationMode = stepComponent.transformationMode
        if (supplierItem) {
            if (!ingredientsMap.has(stepComponent.index)) {
                ingredientsMap.set(stepComponent.index, {
                    netWeight,
                    grossWeight,
                    supplierItem,
                    transformRate,
                    transformationMode,
                    unitGrossWeight, 
                    unitNetWeight,
                    index
                })
            }
        } else if (priorSteps) {
            generateIngredientsMapByIndex({ stepComponents: priorSteps[ingredientsField], ingredientsField, ingredientsMap})
        }
    }
    return ingredientsMap
}

const reformatWeightMapBySupplierItem = (ingredientsMap, netWeightOnly = false) => {
    const reformattedMap = new Map()
    for (const { supplierItem, netWeight, grossWeight } of ingredientsMap.values()) {
        const supplierItemId = supplierItem.id || supplierItem.objectId
        if (reformattedMap.has(supplierItemId)) {
            const currentIngredientData = reformattedMap.get(supplierItemId)
            reformattedMap.set(supplierItemId, {
                netWeight: currentIngredientData.netWeight + netWeight,
                grossWeight: currentIngredientData.grossWeight + grossWeight
            })
        }
        else {
            reformattedMap.set(supplierItemId, {
                netWeight,
                grossWeight
            })
        }
    }
    if (netWeightOnly) {
        let weightOnlyMap = new Map()
        for (const [key, value] of reformattedMap.entries()) {
            weightOnlyMap.set(key, value.netWeight)
        }
        return weightOnlyMap
    }
    return reformattedMap
}

export const getIngredientsAndTheirOuputWeight = ({ recipeSections, stepsField = "productionSteps", ingredientsField = "stepComponents", isProductionSteps = true }) => {
    const reversedSteps = sortRecipeStepsByLast(recipeSections, stepsField)
    const stepComponentsInOrder = getIngredientsFromReusableStepsAndStandardSteps(reversedSteps, ingredientsField, isProductionSteps)
    const lastComputedOccurrenceOfIngredientMap = generateIngredientsMapByIndex({ stepComponents: stepComponentsInOrder })
    const ingredientsMapBySupplierItems = reformatWeightMapBySupplierItem(lastComputedOccurrenceOfIngredientMap, true)
    return ingredientsMapBySupplierItems
}

export const computeSectionInputWeight = (section, stepsField = "productionSteps", ingredientsField = "stepComponents", isProductionSteps = true) => {

    let grossWeightForAllIngredients = 0
    const stepComponents = getIngredientsFromReusableStepsAndStandardSteps(section[stepsField], ingredientsField, isProductionSteps)
    const firstComputedOccurrenceOfIngredientMap = generateIngredientsMapByIndex({ stepComponents })

    for (const { grossWeight } of firstComputedOccurrenceOfIngredientMap.values()) {
        grossWeightForAllIngredients += grossWeight
    }
    return grossWeightForAllIngredients

}
/**
 * get global netWeight (sum of each ingredient's LAST transformation output)
 * @param {Array} steps 
 * @param {String} ingredientsField // "stepComponents" (new form from tab "étapes de production") || "ingredients" (old form tab "recette")
 * @returns 
 */
export const computeSectionOutputWeight = (section, stepsField = "productionSteps", ingredientsField = "stepComponents", isProductionSteps = true) => {

    let netWeightForAllIngredients = 0
    const reversedSteps = sortRecipeStepsByLast([section], stepsField)
    const stepComponentsInOrder = getIngredientsFromReusableStepsAndStandardSteps(reversedSteps, ingredientsField, isProductionSteps)
    const lastComputedOccurrenceOfIngredientMap = generateIngredientsMapByIndex({ stepComponents: stepComponentsInOrder, ingredientsField })

    for (const { netWeight } of lastComputedOccurrenceOfIngredientMap.values()) {
        netWeightForAllIngredients += netWeight
    }
    return netWeightForAllIngredients
}

const updateStepWeightAndCost = (step, values) => {
    step.netWeight = roundNumber(values.stepNetWeight, 5)
    step.grossWeight = roundNumber(values.stepGrossWeight, 5)
    step.cost = values.stepCost
    step.realCost = values.stepRealCost
}

export const computeStepData = (step, ingredientsField = "ingredients", isProductionStep = false) => {
    const stepPointerObj = getProductionStepPointerObj(step, isProductionStep)

    const { stepCost, stepRealCost, stepNetWeight, stepGrossWeight } = stepPointerObj[ingredientsField].reduce((acc, ingredient) => {
        let grossWeight = ingredient.grossWeight || 0
        let netWeight = (ingredient.netWeight && parseFloat(ingredient.netWeight)) || 0
        let totalGrossWeight = grossWeight || 0
        let totalNetWeight = netWeight || 0

        if (ingredient.priorSteps && !ingredient.supplierItem) {
            let sumIngredients = { grossWeight: 0, netWeight: 0 }
            /** for a stepComponent with priorSteps we need to calculate grossWeight, netWeight for all subComponents
             * those values are reported on totalGrossWeight and totalNetWeight **/
            grossWeight = 0
            netWeight = 0
            sumIngredients = updatePriorStepValuesForEachChild(ingredient, sumIngredients)
            ingredient.grossWeight = sumIngredients.grossWeight
            ingredient.netWeight = sumIngredients.netWeight
            totalGrossWeight = sumIngredients.grossWeight
            totalNetWeight = sumIngredients.netWeight
        }

        acc.stepCost += ingredient.cost || 0
        acc.stepRealCost += ingredient.realCost || 0
        acc.stepNetWeight += (ingredient.priorSteps && !ingredient.supplierItem) ? totalNetWeight : netWeight
        acc.stepGrossWeight += (ingredient.priorSteps && !ingredient.supplierItem) ? totalGrossWeight : grossWeight

        return acc
    }, { stepCost: 0, stepRealCost: 0, stepNetWeight: 0, stepGrossWeight: 0 })

    const values = {
        stepNetWeight,
        stepGrossWeight,
        stepCost,
        stepRealCost,
    }

    updateStepWeightAndCost(step, values)

    // update step.step
    if (isProductionStep) {
        updateStepWeightAndCost(stepPointerObj, values)
    }
}

const computeRecipeReusableStepComponentWeights = (stepComponents, percent) => {
    if (!stepComponents) return
    for (const stepComponent of stepComponents) {
        if (stepComponent.priorSteps) {
            computeRecipeReusableStepComponentWeights(stepComponent.priorSteps.stepComponents, percent)
        }
        stepComponent.netWeight = (stepComponent.netWeight * percent) / 100
        stepComponent.grossWeight = (stepComponent.grossWeight * percent) / 100

        if (stepComponent.supplierItem) {
            stepComponent.realCost = calculateStepComponentCost(stepComponent.grossWeight, stepComponent.supplierItem.pricePerKg)
        }
    }
}


/**
 * update all reusable step stepComponents (priorStep stepComponents and stepComponents) netWeight and grossWeight
 * @param {*} step 
 * @param {*} parentNetWeight 
 * @returns
 */
export const computeRecipeReusableStepWeights = (step, parentNetWeight) => {
    const clonedStep = cloneDeep(step)
    clonedStep.netWeight = parentNetWeight
    const percent = (parentNetWeight / step.netWeight) * 100

    for (const productionStep of clonedStep.productionSteps) {
        computeRecipeReusableStepComponentWeights(productionStep.stepComponents, percent)
    }

    return clonedStep
}

/**
 * there are 2 steps field for a section: 
 * 1. steps: array of object (deprecated)
 * 2. productionSteps: array of pointers
 * @param {*} section 
 * @param {*} stepsField 
 */
export const computeSectionData = (section, stepsField = "steps", stepIngredientField = "ingredients", isProductionSteps = false) => {
    const { sectionRealCost, sectionGrossWeight, sectionNetWeight } = section[stepsField].reduce((acc, currentStep) => {
        const step = getProductionStepPointerObj(currentStep, isProductionSteps)
        acc.sectionRealCost += step.realCost || 0
        acc.sectionNetWeight += step.netWeight || 0
        acc.sectionGrossWeight += step.grossWeight || 0   // input weight

        return acc
    }, { sectionCost: 0, sectionRealCost: 0, sectionGrossWeight: 0, sectionNetWeight: 0 })

    const sectionNetWeightForNewRecipe = computeSectionOutputWeight(section, stepsField, stepIngredientField, isProductionSteps) // for new recipe and reusable steps pseudo section
    const sectionGrossWeightForNewRecipe = computeSectionInputWeight(section, stepsField, stepIngredientField, isProductionSteps) // idem
    const sectionNetWeightRef = stepsField === "productionSteps" ? sectionNetWeightForNewRecipe : sectionNetWeight
    const sectionGrossWeightRef = stepsField === "productionSteps" ? sectionGrossWeightForNewRecipe : sectionGrossWeight

    section.netWeight = roundNumber(sectionNetWeightRef, 5)
    section.grossWeight = roundNumber(sectionGrossWeightRef, 5)
    section.cost = sectionRealCost
    section.realCost = sectionRealCost
}

export function computeRecipeData(recipe) {
    const { recipeCost, recipeRealCost, recipeNetWeight, recipeGrossWeight } = recipe.sections.reduce((acc, section) => {
        acc.recipeCost += section.cost || 0
        acc.recipeRealCost += section.realCost || 0
        acc.recipeNetWeight += section.netWeight || 0
        acc.recipeGrossWeight += section.grossWeight || 0

        return acc
    }, { recipeCost: 0, recipeRealCost: 0, recipeNetWeight: 0, recipeGrossWeight: 0 })

    recipe.cost = recipeCost
    recipe.netWeight = roundNumber(recipeNetWeight, 5)
    recipe.asp = computeRecipeASP(recipe)
    recipe.grossWeight = roundNumber(recipeGrossWeight, 5)

    const finalPrice = recipe.HTprice || recipe.HTPVC
    recipe.fcpct = finalPrice ? roundNumber(roundNumber(recipeRealCost, 2) / finalPrice * 100, 2) : null

    return { // return modified values
        cost: recipe.cost,
        netWeight: recipe.netWeight,
        asp: recipe.asp,
        grossWeight: recipe.grossWeight,
        fcpct: recipe.fcpct
    }
}

export const getStepComponentCost = (stepComponent) => {
    const pricePerKg = stepComponent.supplierItem.get ? stepComponent.supplierItem.get("pricePerKg") : stepComponent.supplierItem.pricePerKg
    const cost = pricePerKg * parseFloat(stepComponent.grossWeight) || 0
    return cost
}

export const getIngredientNetWeight = (ingredient, transformRate) => {
    const netWeight = parseFloat(ingredient.grossWeight) * (transformRate / 100)
    return netWeight || 0
}

export function computeIngredientData(ingredient, stepsField = "steps") {
    let result = { netWeight: 0, cost: 0 }

    let cookingModes = null

    if (ingredient.supplierItem) {
        try {
            cookingModes = ingredient.supplierItem.get ? ingredient.supplierItem.get("cookingModes") : ingredient.supplierItem.cookingModes
        } catch (error) {
            cookingModes = ingredient.supplierItem.cookingModes
        }
    }

    if (ingredient.supplierItem) {
        const ingredientCookingModeId = typeof ingredient.cookingMode === "string" ? ingredient.cookingMode : ingredient.cookingMode?.id
        const currentCookingMode = cookingModes.find(cookingMode => {
            return cookingMode.cookingMode && cookingMode.cookingMode?.id === ingredientCookingModeId
        })

        const transformRate = currentCookingMode
            ? parseFloat(currentCookingMode.transformRate)
            : 0

        const label = currentCookingMode && currentCookingMode.cookingMode
            ? currentCookingMode.cookingMode.name
            : ""

        const cost = getStepComponentCost(ingredient)

        result = {
            grossWeight: roundNumber((parseFloat(ingredient.grossWeight)), 5) || 0,
            netWeight: stepsField === "productionSteps"
                ? parseFloat(ingredient.netWeight)
                : roundNumber(getIngredientNetWeight(ingredient, transformRate), 5) || 0,
            cost,
            realCost: cost,
            transformRate: transformRate,
            cookingModeLabel: label
        }
    } else if (ingredient.priorSteps) {
        let sumIngredients = { grossWeight: 0, netWeight: 0 }
        /** for a stepComponent with priorSteps we need to calculate grossWeight, netWeight for all subComponents**/
        sumIngredients = updatePriorStepValuesForEachChild(ingredient, sumIngredients)
        result.netWeight = sumIngredients.netWeight
        result.grossWeight = sumIngredients.grossWeight
    }

    return result
}

/**
 * Compute recipe advised sell price (old )
 */
export function computeRecipeASP(recipe) {
    let result = 0

    if (recipe) {
        const currentRecipeType = productType.find(item => item.value === recipe.type)
        result = getPVC(
            "RECIPE",
            currentRecipeType ? currentRecipeType.value : "",
            recipe.cost
        ).pvc
    }

    return result
}

//=========================================
//=========================================
//=========================================

export function resetStepComponent(stepComponent) {
    stepComponent.grossWeight = 0
    stepComponent.cost = null
    stepComponent.netWeight = null
    stepComponent.realCost = null
    stepComponent.supplierItem = null
    stepComponent.priorSteps = null
    stepComponent.transformRate = 100
    stepComponent.transformationMode = null

    return stepComponent
}

const calculateStepComponentCost = (grossWeight, pricePerKg) => {
    return roundNumber(grossWeight * pricePerKg, 3)
}

export const recalculateCostValues = (stepComponent) => {

    stepComponent.grossWeight = (stepComponent.netWeight / ((stepComponent.transformRate || stepComponent.transformRate === 0 ? stepComponent.transformRate : 100) / 100))

    if (stepComponent.supplierItem) {
        stepComponent.realCost = calculateStepComponentCost(stepComponent.grossWeight, stepComponent.supplierItem.pricePerKg)
    }

    return stepComponent
}

export const recalculateCostValuesPriorStep = (stepComponent) => {
    stepComponent.netWeight = (stepComponent.grossWeight * ((stepComponent.transformRate || stepComponent.transformRate === 0 ? stepComponent.transformRate : 100) / 100))
    stepComponent.realCost = roundNumber(stepComponent.grossWeight * stepComponent.supplierItem.pricePerKg, 3)

    return stepComponent
}

export const recalculateStepComponentsCost = (step) => {
    const stepComponents = step.stepComponents.map(stepComponent => recalculateCostValues(stepComponent))
    const newStep = { ...step, stepComponents }
    return newStep
}

export function getDefaultIngredients() {
    return {
        index: uuidv4(),
        grossWeight: 0,
        cookingMode: null,
        supplierItem: null,
        error: true
    }
}

export const getDefaultStepComponents = () => {
    return {
        index: uuidv4(),
        grossWeight: 0,
        supplierItem: null,
        error: false,
        cost: null,
        netWeight: null,
        realCost: null,
        transformRate: null,
        transformationMode: null,
        emptyComponent: true
    }
}

export const getDefaultProductionSteps = () => {
    return {
        index: uuidv4(),
        grossWeight: 0,
        error: false,
        name: "",
        description: "",
        netWeight: null,
        cost: null,
        realCost: null,
        stepComponents: [getEmptyStepComponent()]
    }
}

export const getDefaultProductionStepObj = () => {
    return {
        step: getDefaultProductionSteps(),
        reusable: false
    }
}

export const getDefaultSteps = (defaultValues = {}) => {
    return {
        index: uuidv4(),
        name: "",
        description: "",
        ingredients: [getDefaultIngredients()],
        stepComponents: [getEmptyStepComponent()],
        error: true,
        isEmpty: true,
        endStepWeighing: true,
        ...defaultValues
    }
}

export function getDefaultReusableSteps(defaultValues = {}) {
    return {
        index: uuidv4(),
        name: "",
        description: "",
        stepComponents: [getEmptyStepComponent()],
        error: true,
        isEmpty: true,
        endStepWeighing: true,
        ...defaultValues
    }
}

export function getDefaultSectionProductionStep(defaultStepValues) {
    return {
        step: getDefaultSteps(defaultStepValues),
        reusable: false,
        coeff: null,
        netWeight: null
    }
}

export const getDefaultSection = () => {
    const productionStep = getDefaultProductionStepObj()
    return {
        id: null,
        index: uuidv4(),
        name: "",
        generic: false,
        reusable: false,
        steps: [getDefaultSteps()],
        productionSteps: [productionStep],
        error: true
    }
}

export function getSupplierItemsFromSections(sections, stepsField = "steps", ingredientsField = "ingredients") {
    const supplierItemsList = []
    sections.forEach(section => {
        const steps = section[stepsField] || []
        steps.forEach(step => {
            const isReusable = step.reusable
            const currentStep = getProductionStepPointerObj(step, ingredientsField === "stepComponents")
            let ingredients = currentStep[ingredientsField] || []
            if (isReusable) {
                const productionSteps = currentStep.productionSteps || []
                productionSteps.forEach(productionStep => {
                    const stepComponents = productionStep[ingredientsField] || []
                    ingredients.push(...stepComponents)
                }
                )
            }
            ingredients.forEach(ingredient => {
                if (ingredient.supplierItem && !supplierItemsList.includes(ingredient.supplierItem.id)) {
                    supplierItemsList.push(ingredient.supplierItem.id)
                }
            })
        })
    })

    return supplierItemsList
}

export function computeIngredientsPercentage(
    recipeNetWeight,
    recipeSections,
    supplierItems,
    stepsField = "steps",
    ingredientsField = "ingredients"
) {
    const ingredientsPercentage = []
    const supplierItemsInformations = supplierItems.map(supplierItem => { return { id: supplierItem.id, supplierItem } })

    let ingredientsAndTheirWeightMap = new Map()

    /** productionSteps = only get last net weight of a given ingredient */
    if (stepsField === "productionSteps") {
        ingredientsAndTheirWeightMap = getIngredientsAndTheirOuputWeight({ recipeSections, stepsField, ingredientsField })
    }

    /** steps = get sum of all net weight of a given ingredient's occurrence  */
    if (stepsField === "steps") {
        for (const section of recipeSections) {
            const steps = section[stepsField] || []
            for (const step of steps) {
                const ingredients = step[ingredientsField] || []
                for (const ingredient of ingredients) {
                    const ingredientData = computeIngredientData(ingredient)
                    const supplierItemId = ingredient.supplierItem && ingredient.supplierItem.id
                    if (supplierItemId) {
                        if (!ingredientsAndTheirWeightMap.has(supplierItemId)) {
                            ingredientsAndTheirWeightMap.set(supplierItemId, ingredientData.netWeight)
                        }
                        else {
                            const currentIngredientWeight = ingredientsAndTheirWeightMap.get(supplierItemId)
                            ingredientsAndTheirWeightMap.set(supplierItemId, currentIngredientWeight + ingredientData.netWeight)
                        }
                    }
                }
            }
        }
    }

    for (const supplierItemInfo of supplierItemsInformations) {
        const ingredientNetWeight = ingredientsAndTheirWeightMap.get(supplierItemInfo.id) || 0
        const percentage = roundNumber((ingredientNetWeight * 1000 / (recipeNetWeight * 1000)) * 100, 2)

        ingredientsPercentage.push({
            supplierItem: supplierItemInfo.supplierItem,
            percentage
        })
    }
    return ingredientsPercentage
}

export function getCommercialNamesFromSections(sections) {
    const ret = []

    for (const idx in sections) {
        const steps = sections[idx].steps || []
        for (const j in steps) {
            const step = steps[j]
            const ingredients = step.ingredients || []
            for (const k in ingredients) {
                const ingredient = ingredients[k].ingredient
                const grossWeight = ingredients[k].grossWeight
                const cookingModeId = ingredients[k].cookingMode
                const cookingModes = ingredient && Array.isArray(ingredient.cookingModes)
                    ?
                    ingredient.cookingModes
                    :
                    []
                const cookingMode = cookingModes.find(elem => elem.cookingMode.id === cookingModeId)
                const transformRate = cookingMode ? cookingMode.transformRate : 0
                const netWeight = grossWeight * transformRate / 100

                if (ingredient) {
                    const index = ret.findIndex(elem => elem.commercialNameId === ingredient.commercialNameId)
                    if (index < 0) {
                        ret.push({
                            commercialName: ingredient.commercialName,
                            complexity: ingredient.complexity,
                            netWeight: netWeight,
                            commercialNameId: ingredient.commercialNameId
                        })
                    } else {
                        ret[index].netWeight += netWeight
                    }
                }
            }
        }
    }
    const totalNetWeight = ret.length > 0
        ?
        ret.map(elem => elem.netWeight).reduce((total, current) => {
            return total + current
        })
        :
        0

    return ret.map(elem => {
        return { ...elem, pct: Number(elem.netWeight / totalNetWeight * 100).toFixed(1) }
    }).sort((a, b) => {
        return b.pct - a.pct
    })
}

export function getAllergensFromSections(sections) {
    const ret = []

    for (const idx in sections) {
        const steps = sections[idx].steps || []
        for (const j in steps) {
            const ingredients = steps[j].ingredients || []
            for (const k in ingredients) {
                const ingredient = ingredients[k].ingredient
                if (ingredient) {
                    const ingredientAllergens = ingredient.allergens
                    for (const l in ingredientAllergens) {
                        const ingredientAllergen = ingredientAllergens[l]
                        if (!ret.find(elem => elem.allergenId === ingredientAllergen.allergenId)) {
                            ret.push(ingredientAllergen)
                        }
                    }
                }
            }
        }
    }

    return ret
}

export function getRecipeStatusToString(statusValue) {
    switch (statusValue) {
        case 1:
            return "En conception"
        case 2:
            return "Conception abandonnée"
        case 3:
            return "Contrôle achats à réaliser"
        case 4:
            return "Contrôle achat NOK"
        case 5:
            return "Informations à renseigner"
        case 6:
            return "Produit prêt à la vente"
        default:
            return ""
    }
}

export function parseHeatingInstructions(heatingInstruction) {
    if (heatingInstruction && !isEqual(heatingInstruction, {})) {
        heatingInstruction.microwave.power = Number(heatingInstruction.microwave.power)
        heatingInstruction.microwave.duration = Number(heatingInstruction.microwave.duration)

        heatingInstruction.oven.power = Number(heatingInstruction.oven.power)
        heatingInstruction.oven.duration = Number(heatingInstruction.oven.duration)

        heatingInstruction.pan.power = Number(heatingInstruction.pan.power)
        heatingInstruction.pan.duration = Number(heatingInstruction.pan.duration)

        return heatingInstruction
    }

    return null
}

export function areHeatingInstructionsEmpty(heatingInstructions) {
    if (heatingInstructions && !isEqual(heatingInstructions, {})) {
        if (heatingInstructions.microwave) {
            if (heatingInstructions.microwave.power || heatingInstructions.microwave.duration || heatingInstructions.microwave.instructions) {
                return false
            }
        }

        if (heatingInstructions.oven) {
            if (heatingInstructions.oven.power || heatingInstructions.oven.duration || heatingInstructions.oven.instructions) {
                return false
            }
        }

        if (heatingInstructions.pan) {
            if (heatingInstructions.pan.power || heatingInstructions.pan.duration || heatingInstructions.pan.instructions) {
                return false
            }
        }
    }

    return true
}

export const emptyHeatingInstruction = {
    microwave: {
        power: "",
        duration: "",
        instructions: ""
    },
    oven: {
        power: "",
        duration: "",
        instructions: ""
    },
    pan: {
        power: "",
        duration: "",
        instructions: ""
    }
}

export const FCHeatingInstruction = {
    microwave: {
        power: 900,
        duration: 2.5,
        instructions: "Retirez le couvercle avant de réchauffer"
    },
    oven: {
        power: 150,
        duration: 12,
        instructions: "Transférez dans un plat adapté"
    },
    pan: {
        power: "",
        duration: "",
        instructions: ""
    }
}

export const SZNHeatingInstruction = {
    microwave: {
        power: 900,
        duration: 2.5,
        instructions: "Soulevez l'opercule et réchauffez"
    },
    oven: {
        power: 150,
        duration: 12,
        instructions: "Transférez dans un plat adapté"
    },
    pan: {
        power: "",
        duration: "",
        instructions: ""
    }
}

export const MONOPHeatingInstruction = {
    microwave: {
        power: 900,
        duration: 2.5,
        instructions: "Soulevez l'opercule et réchauffez"
    },
    oven: {
        power: 150,
        duration: 12,
        instructions: "Transférez dans un plat adapté"
    },
    pan: {
        power: "",
        duration: "",
        instructions: ""
    }
}

export const recipePreparations = [
    { value: "1", label: "Se mange froid", labelFem: "Se mange froide" },
    { value: "2", label: "Se mange chaud", labelFem: "Se mange chaude" },
    { value: "3", label: "Se mange chaud ou froid", labelFem: "Se mange chaude ou froide" },
]

export const recipePreparationsBasic = [
    { value: "1", label: "Se consomme froid", labelFem: "Se consomme froide" },
    { value: "2", label: "Se consomme chaud", labelFem: "Se consomme chaude" },
    { value: "3", label: "Se consomme chaud ou froid", labelFem: "Se consomme chaude ou froide" },
]

export const defaultNutritionInformation = {
    calories: 0,
    fat: 0,
    saturatedFattyAcids: 0,
    carbohydrates: 0,
    sugar: 0,
    proteins: 0,
    salt: 0,
    fibers: 0,
    frenchIngredientsRate: 0,
    certifiedIngredientsRate: 0,
    organicIngredientsRate: 0,
    localIngredientsRate: 0,
    seasonalIngredientsRate: 0,
    sustainableFishing: false,
    carbonFootPrint: 0,
    nutriscore: "Inconnu"
}

export const recipeNutriscores = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "Inconnu", label: "Inconnu" }
]

const january = moment().startOf("year")

export const months = new Array(12).fill(null).map((month, index) => {
    return january.clone().add(index, "months")
})

const SUGGESTED_PRICE = {
    RECIPE: {
        MAIN_COURSE: [
            { min: 0, max: 1.59, pvc: 6.9, hasColor: false },
            { min: 1.6, max: 1.79, pvc: 7.4, hasColor: false },
            { min: 1.8, max: 1.99, pvc: 7.9, hasColor: false },
            { min: 2, max: 2.14, pvc: 8.4, hasColor: false },
            { min: 2.15, max: 2.29, pvc: 9.4, hasColor: false },
            { min: 2.3, max: 2.49, pvc: 9.95, hasColor: false },
            { min: 2.5, max: 2.59, pvc: 10.4, hasColor: false },
            { min: 2.6, max: 2.69, pvc: 10.95, hasColor: false },
            { min: 2.7, max: 2.99, pvc: 11.4, hasColor: false },
            { min: 3, max: 999999999, pvc: 11.95, hasColor: true }
        ],
        STARTER: [
            { min: 0, max: 0.39, pvc: 2.5, hasColor: false },
            { min: 0.4, max: 0.49, pvc: 2.9, hasColor: false },
            { min: 0.5, max: 0.59, pvc: 3.2, hasColor: false },
            { min: 0.6, max: 0.69, pvc: 3.5, hasColor: false },
            { min: 0.7, max: 0.79, pvc: 3.7, hasColor: false },
            { min: 0.8, max: 0.89, pvc: 3.9, hasColor: false },
            { min: 0.9, max: 0.99, pvc: 4.5, hasColor: false },
            { min: 1, max: 999999999, pvc: 4.5, hasColor: true }
        ],
        DESSERT: [
            { min: 0, max: 0.39, pvc: 2.5, hasColor: false },
            { min: 0.4, max: 0.49, pvc: 2.9, hasColor: false },
            { min: 0.5, max: 0.59, pvc: 3.2, hasColor: false },
            { min: 0.6, max: 0.69, pvc: 3.5, hasColor: false },
            { min: 0.7, max: 0.79, pvc: 3.7, hasColor: false },
            { min: 0.8, max: 0.89, pvc: 3.9, hasColor: false },
            { min: 0.9, max: 999999999, pvc: 3.9, hasColor: true }

        ],
        KIDS: [
            { min: 0, max: 0.89, pvc: 3.9, hasColor: false },
            { min: 0.9, max: 0.99, pvc: 4.5, hasColor: false },
            { min: 1, max: 999999999, pvc: 4.9, hasColor: true },
        ],
        BREAKFAST: [
            { min: 0, max: 1.59, pvc: 6.9, hasColor: false },
            { min: 1.6, max: 1.79, pvc: 7.4, hasColor: false },
            { min: 1.8, max: 1.99, pvc: 7.9, hasColor: false },
            { min: 2, max: 2.14, pvc: 8.4, hasColor: false },
            { min: 2.15, max: 2.29, pvc: 9.4, hasColor: false },
            { min: 2.3, max: 2.49, pvc: 9.95, hasColor: false },
            { min: 2.5, max: 2.59, pvc: 10.4, hasColor: false },
            { min: 2.6, max: 2.69, pvc: 10.95, hasColor: false },
            { min: 2.7, max: 2.99, pvc: 11.4, hasColor: false },
            { min: 3, max: 999999999, pvc: 11.95, hasColor: true }
        ],
        MEAL_PREP: [
            { min: 0, max: 1.59, pvc: 6.9, hasColor: false },
            { min: 1.6, max: 1.79, pvc: 7.4, hasColor: false },
            { min: 1.8, max: 1.99, pvc: 7.9, hasColor: false },
            { min: 2, max: 2.14, pvc: 8.4, hasColor: false },
            { min: 2.15, max: 2.29, pvc: 9.4, hasColor: false },
            { min: 2.3, max: 2.49, pvc: 9.95, hasColor: false },
            { min: 2.5, max: 2.59, pvc: 10.4, hasColor: false },
            { min: 2.6, max: 2.69, pvc: 10.95, hasColor: false },
            { min: 2.7, max: 2.99, pvc: 11.4, hasColor: false },
            { min: 3, max: 999999999, pvc: 11.95, hasColor: true }
        ]
    },
    SUBCONTRACTORPRODUCT: {
        MAIN_COURSE: [
            { min: 0, max: 2.59, pvc: 6.9, hasColor: false },
            { min: 2.6, max: 2.79, pvc: 7.4, hasColor: false },
            { min: 2.8, max: 3.14, pvc: 7.9, hasColor: false },
            { min: 3.15, max: 3.29, pvc: 8.4, hasColor: false },
            { min: 3.3, max: 3.49, pvc: 8.9, hasColor: false },
            { min: 3.5, max: 3.59, pvc: 9.1, hasColor: false },
            { min: 3.6, max: 3.69, pvc: 9.9, hasColor: false },
            { min: 3.7, max: 3.99, pvc: 10.9, hasColor: false },
            { min: 4, max: 999999999, pvc: 10.9, hasColor: true }
        ],
        STARTER: [
            { min: 0, max: 1.39, pvc: 1.9 },
            { min: 1.4, max: 1.49, pvc: 2.1, hasColor: false },
            { min: 1.5, max: 1.59, pvc: 2.7, hasColor: false },
            { min: 1.6, max: 1.69, pvc: 2.9, hasColor: false },
            { min: 1.7, max: 1.79, pvc: 3.1, hasColor: false },
            { min: 1.8, max: 1.89, pvc: 3.5, hasColor: false },
            { min: 1.9, max: 1.99, pvc: 3.9, hasColor: false },
            { min: 2, max: 999999999, pvc: 3.9, hasColor: true },
        ],
        DESSERT: [
            { min: 0, max: 1.39, pvc: 1.9, hasColor: false },
            { min: 1.4, max: 1.49, pvc: 2.1, hasColor: false },
            { min: 1.5, max: 1.59, pvc: 2.7, hasColor: false },
            { min: 1.6, max: 1.69, pvc: 2.9, hasColor: false },
            { min: 1.7, max: 1.79, pvc: 3.1, hasColor: false },
            { min: 1.8, max: 1.89, pvc: 3.5, hasColor: false },
            { min: 1.9, max: 999999999, pvc: 3.5, hasColor: true },
        ],
        KIDS: [
            { min: 0, max: 1.89, pvc: 3.5, hasColor: false },
            { min: 1.9, max: 1.99, pvc: 3.9, hasColor: false },
            { min: 2, max: 999999999, pvc: 4.9, hasColor: true },
        ]
    }
}

export function getPVC(type, productType, foodcost) {
    let result = { pvc: foodcost }

    if (SUGGESTED_PRICE.hasOwnProperty(type) && SUGGESTED_PRICE[type].hasOwnProperty(productType)) {
        const array = SUGGESTED_PRICE[type][productType]
        const found = array.find(elem => foodcost >= elem.min && foodcost <= elem.max)
        result = found ? found : { ...array[array.length - 1], hasColor: true }
    }

    return result
}

export function mapSeasonsToPeriodsLabels(season) {
    const seasonsValues = season.map(elem => Number(elem)).sort((a, b) => a - b)
    const sznOrdered = []
    let currentPeriod = []

    for (const month of seasonsValues) {
        if (currentPeriod.length > 0 &&
            currentPeriod[currentPeriod.length - 1] !== month - 1) {
            sznOrdered.push(cloneDeep(currentPeriod))
            currentPeriod = []
        }
        currentPeriod.push(month)
        currentPeriod.sort((a, b) => a - b)
    }

    if (currentPeriod.length > 0) {
        sznOrdered.push(currentPeriod)
    }

    if (sznOrdered.length > 1) {
        const first = sznOrdered[0]
        const last = sznOrdered[sznOrdered.length - 1]
        if (last[last.length - 1] === 12 && first[0] === 1) {
            const merge = last.concat(first)
            sznOrdered.shift()
            sznOrdered[sznOrdered.length - 1] = merge
        }
    }

    return sznOrdered.map(period => {
        if (period.length === 1) {
            return moment.months(period[0] - 1, "mmmm")
        } else {
            return `De ${moment.months(period[0] - 1, "mmmm")} à ${moment.months(period[period.length - 1] - 1, "mmmm")}`
        }
    })
}

export function getMergedCommercialNames(ingredients) {
    let mergedCommercialNames = []

    if (Array.isArray(ingredients)) {
        for (const ingredient of ingredients) {
            const existingCommercialName = ingredient.supplierItem && ingredient.supplierItem.commercialName &&
                mergedCommercialNames.find(elm => elm.commercialName && (elm.commercialName.name === ingredient.supplierItem.commercialName.name))

            if (!existingCommercialName) {
                mergedCommercialNames.push(
                    {
                        commercialName: ingredient.supplierItem && ingredient.supplierItem.commercialName ? ingredient.supplierItem.commercialName : null,
                        percentage: ingredient.percentage
                    }
                )
            } else {
                existingCommercialName.percentage = existingCommercialName.percentage + ingredient.percentage
            }
        }

        mergedCommercialNames = mergedCommercialNames.filter(Boolean).sort((a, b) => b.percentage - a.percentage)
    }

    return mergedCommercialNames
}

export const RecipeStatus = [
    { value: "1", label: "En conception" },
    { value: "2", label: "Conception abandonnée" },
    { value: "3", label: "Contrôle achats à réaliser" },
    { value: "4", label: "Contrôle achat NOK" },
    { value: "5", label: "Informations à renseigner" },
    { value: "6", label: "Produit prêt à la vente" }
]

export const RecipeIsActive = [
    { value: "true", label: "Actif" },
    { value: "false", label: "Inactif" },
]

export const RecipeSpicy = [
    { value: "0", label: "Aucune épice" },
    { value: "1", label: "" },
    { value: "2", label: "" }
]

export const detailsField = {
    appImage: "Photo vue client",
    commercialName: "Nom commercial",
    description: "Description",
    dlc: "Date limite de consommation (en jours)",
    instructions: "Instruction de dressage",
    price: "Prix de vente",
    tva: "TVA",
    commercialNames: "Nom commercial",
    packaging: "Packaging",
    subPackaging: "Sous-Packaging",
    reusablePackaging: "Packaging réutilisable",
    reusableSubPackaging: "Sous-Packaging réutilisable",
    specialInstruction: "Instruction spécifique",
    preparation: "Préparation",
    gesters: "Nombre de gestes",
    portionPerPlate: "Portion / Plaque",
    difficulty: "Difficulté de production"
}

export function getSpicyImg(count) {
    let result = []

    for (let i = 1; i <= count; i++) {
        result.push(<><img width="20" height="20" src="/img/spicy.png" alt="épice" /></>)
    }

    return result
}

export function getRecipeCostDataColumn(jsonRecipe) {

    const recipeBrands = jsonRecipe && Array.isArray(jsonRecipe.brands)
        ? jsonRecipe.brands
        : []
    const brands = recipeBrands.filter((brand) => ACTIVE_KFC_BRANDS_NAMES.includes(brand))
    const data = [
        { label: "FoodCost" },
        { label: "Packaging" },
        { label: "Total des coûts" },
        { label: "Prix de vente conseillé" },
        { label: "Prix de vente" }
    ].map(elem => {
        brands.forEach(brand => {
            elem[brand] = "--"
        })
        return elem
    })

    for (const brand of brands) {
        const KFCBRAND = ACTIVE_KFC_BRANDS.find(elem => elem.name === brand)

        //PRICE
        const price = KFCBRAND && KFCBRAND.priceEnabled ? getRecipePrice(jsonRecipe, brand) : ""
        const tva = getRecipeTva(jsonRecipe, brand)
        const HTPrice = tva && price && price !== 0 ? price / (1 + (parseFloat(tva) / 100)) : null

        //PVC
        const pvcValue = KFCBRAND && KFCBRAND.priceEnabled
            ? getPVC("RECIPE", jsonRecipe.type, jsonRecipe.foodcost).pvc
            : null
        const pvc = pvcValue
            ? `${pvcValue}€`
            : "--"
        const HTPVC = tva && (!price || price === 0) ? pvcValue / (1 + (parseFloat(tva) / 100)) : null
        const finalPrice = HTPrice || HTPVC

        //PACKAGING / SUB_PACKAGING
        const packaging = (Array.isArray(jsonRecipe.packaging) ? jsonRecipe.packaging : [])
            .filter(elem => elem.brand === brand).map(elem => elem.value).flat()
        const subPackaging = (Array.isArray(jsonRecipe.subPackaging) ? jsonRecipe.subPackaging : [])
            .filter(elem => elem.brand === brand).map(elem => elem.value).flat()

        const brandPackaging = packaging.concat(subPackaging)
        const packagingCost = roundNumber(brandPackaging.map(elem => Number(elem ? elem.price : 0)).reduce((a, b) => a + b, 0), 2)
        const packagingCostPct = KFCBRAND && KFCBRAND.priceEnabled && finalPrice
            ? roundNumber(packagingCost / finalPrice * 100, 2) + "%"
            : ""

        //FOODCOST
        const foodCost = jsonRecipe.foodcost
        let foodCostPct
        if (brand === "FOODCHERI") {
            KFCBRAND && KFCBRAND.priceEnabled && finalPrice
                ? foodCostPct = roundNumber(foodCost / finalPrice * 100, 2) + "%"
                : foodCostPct = ""
        } else if (brand === "FRIGONU") {
            foodCostPct = roundNumber((foodCost / 6.27) * 100, 2) + "%"
        } else if (brand === "MONOPRIX") {
            foodCostPct = roundNumber((foodCost / 6.54) * 100, 2) + "%"
        } else {
            foodCostPct = roundNumber((foodCost / 7.6) * 100, 2) + "%"
        }

        data[0][brand] = { pct: foodCostPct, value: roundNumber(foodCost, 2), redPCT: KFCBRAND.pctLimit }
        data[1][brand] = { pct: packagingCostPct, value: packagingCost }
        data[2][brand] = { value: roundNumber(foodCost + packagingCost, 3), redPCT: 50 }
        data[3][brand] = pvc
        data[4][brand] = price ? price + "€" : "--"
    }
    return data
}

export const difficultiesChooser = [
    1,
    2,
    3
]

export const tva = [
    "5.5",
    "10",
    "20"
]

export function getRecipeFoodcost(sections) {
    let count = 0

    for (const section of sections) {
        count += section.cost
    }

    return count
}

export function getRecipeDlc(recipe, brand) {
    const specificDlc = recipe.dlc.find(p => brand === p.brand)

    return specificDlc ? specificDlc.value : recipe.defaultValues ? recipe.defaultValues.dlc : 0
}

export function getRecipePrice(recipe, brand) {
    const specificPrice = recipe.price && recipe.price.find(p => brand === p.brand)
    return (specificPrice && null !== specificPrice.value) ? specificPrice.value : recipe.defaultValues ? recipe.defaultValues.price : 0
}

export function getRecipePackagingNumber(recipe, brand) {
    const specificPackagingNumber = recipe.packaginNumber && recipe.packagingNumber.find(p => brand === p.brand)
    return (specificPackagingNumber && null !== specificPackagingNumber.value) ? specificPackagingNumber.value : recipe.defaultValues ? recipe.defaultValues.packagingNumber : 1
}

export function getRecipePackagingNumberUnit(recipe, brand) {
    const specificPackagingNumberUnit = recipe.packaginNumberUnit && recipe.packagingNumberUnit.find(p => brand === p.brand)
    return (specificPackagingNumberUnit && null !== specificPackagingNumberUnit.value) ? specificPackagingNumberUnit.value : recipe.defaultValues ? recipe.defaultValues.packagingNumberUnit : "piece"
}

export function getRecipeTva(recipe, brand) {
    const specificTva = recipe.tva && recipe.tva.find(p => brand === p.brand)

    return (specificTva && null !== specificTva.value) ? specificTva.value : recipe.defaultValues ? recipe.defaultValues.tva : 0
}

export function getRecipeImage(recipe, brand) {
    const specificImage = Array.isArray(recipe.appImage) && recipe.appImage.length > 0 ? recipe.appImage.find(p => brand === p.brand) : null

    return Array.isArray(specificImage) && specificImage.length > 0 ? specificImage.value : recipe.defaultValues ? recipe.defaultValues.appImage : null
}

export function getRecipeDescription(recipe, brand) {
    const specificDescription = recipe.description && recipe.description.find(p => brand === p.brand)

    return (specificDescription && null !== specificDescription.value) ? specificDescription.value : recipe.defaultValues ? recipe.defaultValues.description : ""
}

export function getRecipeHeatingInstructions(recipe, brand) {
    const specificHeatingInstructions = recipe.heatingInstructions && recipe.heatingInstructions.find(p => brand === p.brand)

    return (specificHeatingInstructions && null !== specificHeatingInstructions.value) ? specificHeatingInstructions.value : recipe.defaultValues ? recipe.defaultValues.heatingInstructions : ""
}

export function getRecipeCommercialName(recipe, brand) {
    const specificCommercialName = recipe.commercialNames && recipe.commercialNames.find(p => brand === p.brand)

    return (specificCommercialName && null !== specificCommercialName.value) ? specificCommercialName.value : recipe.defaultValues ? recipe.defaultValues.commercialName : ""
}

export function getEmptyStepComponent() {
    return {
        cost: null,
        error: false,
        grossWeight: null,
        index: uuidv4(),
        netWeight: null,
        realCost: null,
        supplierItem: null,
        transformRate: null,
        transformationMode: null,
        emptyComponent: true
    }
}

export const loopSupplierItemOnPriorSteps = (stepComponents) => {
    if (stepComponents.length > 0) {
        for (let copyPriorStepComponent of stepComponents) {
            if (copyPriorStepComponent.priorSteps && copyPriorStepComponent.priorSteps.stepComponents?.length > 0 && !copyPriorStepComponent.supplierItem) {
                loopSupplierItemOnPriorSteps(copyPriorStepComponent.priorSteps.stepComponents)
            } else {
                copyPriorStepComponent = updateCopyStepComponentCost(copyPriorStepComponent)
            }
        }

    }

    return stepComponents
}

export const formatSupplierItemInPriorSteps = async (supplierItem) => {
    const commercialName = supplierItem.commercialName || {}
    return {
        className: "SupplierItems",
        objectId: supplierItem.objectId || supplierItem.id,
        name: supplierItem.name,
        commercialName: await formatCommercialNameInPriorSteps(commercialName),
        pricePerKg: supplierItem.pricePerKg,
        transformationModes: supplierItem.transformationModes
    }
}

export const updateCopyStepComponentCost = (stepComponent) => {
    /** the copy step gets the netWeight as the grossWeight (poids en sortie => poids en entrée) **/
    stepComponent.grossWeight = stepComponent.netWeight
    stepComponent.transformationMode = null
    stepComponent.transformRate = 100
    stepComponent.netWeight = stepComponent.grossWeight * ((stepComponent.transformRate ? stepComponent.transformRate : 100) / 100)
    stepComponent.realCost = roundNumber(stepComponent.grossWeight * (stepComponent.supplierItem ? stepComponent.supplierItem.pricePerKg : 1), 3)

    return stepComponent
}

export const loopUpdateNetWeightOnPriorSteps = (originalStepComponent, priorStepComponent) => {
    if (!priorStepComponent.supplierItem && priorStepComponent.priorSteps && priorStepComponent.priorSteps.stepComponents.length > 0) {
        priorStepComponent.priorSteps.stepComponents.forEach(currentPriorStepComponent => {
            loopUpdateNetWeightOnPriorSteps(originalStepComponent, currentPriorStepComponent)
        })
    } else {
        updateStepComponentNetWeight(originalStepComponent, priorStepComponent)
    }
}

export const updateStepComponentNetWeight = (originalStepComponent, priorStepComponent) => {
    if (originalStepComponent.index === priorStepComponent.index) {
        priorStepComponent.grossWeight = originalStepComponent.netWeight
        priorStepComponent.netWeight = priorStepComponent.grossWeight * (priorStepComponent.transformRate / 100)
    }
}

export const updateWeightOnAllSteps = (allSteps, parentStepUpdated, stepComponentOriginal, isProductionSteps) => {
    if (!stepComponentOriginal.supplierItem && stepComponentOriginal.priorSteps) {
        stepComponentOriginal = findCorrectStepComponent(stepComponentOriginal)
    }

    if (stepComponentOriginal) {
        /* When we update prior steps grossWeight, we need to update only child priorSteps netWeight */
        const currentStepToUpdateIndex = allSteps.findIndex((step) => {
            const stepPointerObj = getProductionStepPointerObj(step, isProductionSteps)
            if (parentStepUpdated.index) {
                return stepPointerObj.index === parentStepUpdated.index
            }
            return stepPointerObj.objectId === parentStepUpdated.objectId
        })

        const nextStepToUpdateIndex = currentStepToUpdateIndex + 1
        const stepsToUpdate = allSteps.slice(
            nextStepToUpdateIndex, // +1 because current step does not need to be updated as it's the one we changed
            nextStepToUpdateIndex + 1
        )
        /* When net weight is updated, we need to update all prior steps linked to this stepComponent */
        for (const step of stepsToUpdate) {
            const currentStep = getProductionStepPointerObj(step, isProductionSteps)

            if (currentStep.stepComponents) {
                for (const currentStepComponent of currentStep.stepComponents) {
                    if (currentStepComponent.priorSteps) {
                        for (const priorStepComponent of currentStepComponent.priorSteps.stepComponents) {
                            loopUpdateNetWeightOnPriorSteps(stepComponentOriginal, priorStepComponent)
                            updateWeightOnAllSteps(allSteps, currentStep, priorStepComponent, isProductionSteps)
                        }
                    } else {
                        updateWeightOnAllSteps(allSteps, currentStep, stepComponentOriginal, isProductionSteps)
                    }
                }
            }
            else {
                updateWeightOnAllSteps(allSteps, currentStep, stepComponentOriginal, isProductionSteps)
            }

        }
    }
}

export function findCorrectStepComponent(stepComponentOriginal) {
    let stepComponentFound = null
    stepComponentOriginal.priorSteps && stepComponentOriginal.priorSteps.stepComponents.forEach(priorStepComponent => {
        if (priorStepComponent.supplierItem) {
            stepComponentFound = priorStepComponent
        } else if (priorStepComponent.priorSteps) {
            stepComponentFound = findCorrectStepComponent(priorStepComponent)
        }
    })

    return stepComponentFound
}

export function updatePriorStepValuesForEachChild(stepComponent, sumIngredients) {
    if (stepComponent.priorSteps?.stepComponents) {
        stepComponent.priorSteps.stepComponents.forEach(priorStepComponent => {
            if (priorStepComponent.supplierItem) {
                sumIngredients.grossWeight += priorStepComponent.grossWeight
                sumIngredients.netWeight += priorStepComponent.netWeight
            }

            if (priorStepComponent.priorSteps) {
                updatePriorStepValuesForEachChild(priorStepComponent, sumIngredients)
            }
        })
    }

    return sumIngredients
}

export const updateAllRecipesFieldsOnChange = ({
    section,
    steps,
    parentStep,
    currentStepComponentChanged,
    recipe = null,
    isProductionSteps = false
}) => {
    const indexOrigin = isProductionSteps ? steps.findIndex(step => step.step.index === parentStep.index) : steps.findIndex(step => step.index === parentStep.index)
    const modifiedStepComponents = [currentStepComponentChanged]
    updateWeightOnAllStepsV2({ productionSteps: steps, indexOrigin, modifiedStepComponents, isProductionSteps })

    let reusableStepTotalGrossWeight = 0
    steps.forEach(currentStep => {
        if (!currentStep.reusable) {
            computeStepData(currentStep, "stepComponents", isProductionSteps)
        }
        // update the reusable step parent (section version) when in Reusable Step BO
        if (!isProductionSteps) {
            currentStep.stepComponents.forEach((stepComponent) => {
                if (stepComponent.supplierItem) {
                    reusableStepTotalGrossWeight += stepComponent.grossWeight
                }
            })
        }
    })

    if (section) {
        computeSectionData(section, "productionSteps", "stepComponents", isProductionSteps)
    }

    if (recipe) {
        computeRecipeData(recipe)
    }

    return {
        reusableStepTotalGrossWeight
    }
}

export const getStepComponentsWithSupplierItemsFromSteps = (array = [], stepComponents = []) => {
    stepComponents.forEach((stepComponent) => {
        if (stepComponent.supplierItem) {
            array.push(stepComponent)
        }
        else if (stepComponent.priorSteps) {
            getStepComponentsWithSupplierItemsFromSteps(array, stepComponent.priorSteps.stepComponents)
        }
    })
    return array
}

export const updateRecipeAfterReusableStepChange = ({
    reusableStepChanged,
    section,
    recipe = null,
    isProductionSteps = true
}) => {

    // retrieve last transformation for each ingredient of reusable step
    let modifiedStepComponents = []

    const indexStepChanged = section.productionSteps.indexOf(reusableStepChanged)

    const reusableStepCopy = cloneDeep(reusableStepChanged)
    const reversedProductionSteps = reusableStepCopy.step.productionSteps.reverse()

    reversedProductionSteps.forEach((productionStep) => {
        const retrievedStepComponents = getStepComponentsWithSupplierItemsFromSteps([], productionStep.stepComponents)
            .filter((stepComponent) => !modifiedStepComponents.some(item => item.index === stepComponent.index))

        modifiedStepComponents = modifiedStepComponents.concat(retrievedStepComponents)
    })

    updateWeightOnAllStepsV2({ productionSteps: section.productionSteps, indexOrigin: indexStepChanged, modifiedStepComponents, isProductionSteps })

    // recalculate step and section
    const steps = section.productionSteps
    steps.forEach(currentStep => {
        if (!currentStep.reusable) {
            computeStepData(currentStep, "stepComponents", isProductionSteps)
        }
    })
    if (section) {
        computeSectionData(section, "productionSteps", "stepComponents", isProductionSteps)
    }
    if (recipe) {
        computeRecipeData(recipe)
    }
}

export function updateRecipeFoodcost(recipe, formValues, isProductionSteps) {
    if (!isProductionSteps) {
        /** Old recipe foodcost **/
        recipe.set("foodcost", getRecipeFoodcost(formValues.sections))
    } else {
        /** New recipe foodcost **/
        const recipeValues = recipeSectionsFormInitialValues(recipe, true)
        recipe.set("foodcost", recipeValues.cost)
    }
}

export const getProductionStepPointerObj = (step, isObject) => {
    if (isObject) {
        return step.step
    }

    return step
}

const getProductionStepFieldsToSave = (step, stepObj, originalReusableStep) => {
    if (stepObj.reusable) {
        return {
            step, // pointer
            reusable: stepObj.reusable,
            coeff: roundNumber(stepObj.netWeight / originalReusableStep.netWeight, 3),
            grossWeight: stepObj.grossWeight,
            cost: stepObj.cost,
            netWeight: stepObj.netWeight
        }
    }

    return {
        step, // pointer
        reusable: stepObj.reusable,
    }
}


const updatePriorStepsInUlteriorComponentsByMethod = ({
    stepComponents,
    currentStepModifier,
    currentStepName,
    method = "CREATE"
}) => {
    stepComponents?.forEach((stepComponent, index) => {
        if (stepComponent.priorSteps) {
            if (stepComponent.priorSteps.name === currentStepName) {
                let objectToUpdate = cloneDeep(stepComponent)
                switch (method) {
                    case "CREATE":
                        objectToUpdate.priorSteps.stepComponents.push(currentStepModifier)
                        break
                    case "DELETE":
                        const indexOfStepComponentToDelete = objectToUpdate.priorSteps.stepComponents.findIndex(stepComponent => stepComponent.index === currentStepModifier.index)
                        if (index !== -1) {
                            objectToUpdate.priorSteps.stepComponents.splice(indexOfStepComponentToDelete, 1)
                        }
                        break
                    case "UPDATE": // ====> it's a supplierItem change
                        const stepComponentToUpdate = objectToUpdate.priorSteps.stepComponents.find(stepComponent => stepComponent.index === currentStepModifier.index)
                        resetStepComponent(stepComponentToUpdate)
                        stepComponentToUpdate.supplierItem = currentStepModifier.supplierItem
                        break
                    default:
                        break
                }
                stepComponents.splice(index, 1, objectToUpdate)
            }
            else {
                updatePriorStepsInUlteriorComponentsByMethod({
                    stepComponents: stepComponent.priorSteps.stepComponents,
                    currentStepModifier,
                    currentStepName,
                    method
                })
            }
        }
    })

}

export const updatePriorStepsInUlteriorComponents = ({
    currentStepComponent,
    currentStepName,
    currentStepIndex,
    allSteps,
    section = null,
    method = "CREATE",
    isProductionSteps = true,
}) => {

    allSteps.forEach((step, index) => {
        if (index > currentStepIndex) {
            const currentStep = getProductionStepPointerObj(step, isProductionSteps)
            const { stepComponents } = currentStep
            updatePriorStepsInUlteriorComponentsByMethod({
                stepComponents,
                currentStepModifier: currentStepComponent,
                currentStepName,
                method,
            })
        }
    })
    allSteps.forEach(currentStep => {
        if (!currentStep.reusable) {
            computeStepData(currentStep, "stepComponents", isProductionSteps)
        }
    })

    if (section) {
        computeSectionData(section, "productionSteps", "stepComponents", isProductionSteps)
    }
}

export const getRecipeFileExtension = (file) => {
    const fileName = file.label
    const splittedFileNames = fileName.split(".")
    const extension = splittedFileNames.at(-1)

    return extension
}

/**
 * 
 * @param {*} file 
 * @returns 
 */
export const addRecipeFileExtension = (file) => {
    const newFile = { ...file }
    const inputExtension = getRecipeFileExtension(file)

    if (!["png", "jpg", "jpeg"].includes(inputExtension)) {
        newFile.label = newFile.label + "." + newFile.extension
    }

    if (newFile.extension) {
        delete newFile.extension
    }

    return newFile
}

const findAndUpdateStepComponent = (step, index, newNetWeight, newGrossWeight) => {
    if (!step) {
        return
    }
    if (step.index === index && step.supplierItem) {
        step.netWeight = newNetWeight
        step.grossWeight = newGrossWeight
        return
    }
    if (step.stepComponents && step.stepComponents.length > 0) {
        for (const component of step.stepComponents) {
            findAndUpdateStepComponent(component, index, newNetWeight, newGrossWeight)
        }
    }
    if (step.priorSteps) {
        findAndUpdateStepComponent(step.priorSteps, index, newNetWeight, newGrossWeight)
    }
}

/**
 * 
 * @param {Array} productionSteps
 * @param {Number} indexOrigin
 * @param {Array} modifiedStepComponents
 * @param {Boolean} isProductionSteps  // isProductionSteps = from recipe and not from reusable step form
 */

const updateWeightOnAllStepsV2 = ({ productionSteps, indexOrigin, modifiedStepComponents, isProductionSteps = false }) => {
    if (indexOrigin === -1) {
        return // shouldn't happen
    }
    const tempIngredientStorage = new Map()
    // group step components by "index" field (= custom index)
    // it's the field that does the link between step components that share same ingredient (= are prior steps of the other)
    productionSteps.forEach((productionStep, productionStepIndex) => {
        if (productionStepIndex <= indexOrigin) return // only update those after the one just modified by user
        const step = getProductionStepPointerObj(productionStep, isProductionSteps)
        const { stepComponents } = step
        if (!stepComponents) {
            return
        }
        const ingredients = getStepComponentsWithSupplierItemsFromSteps([], stepComponents)
        ingredients.forEach((ingredient) => {
            const customIndex = ingredient.index
            const object = {
                ingredient,
                stepIndex: productionStepIndex,
                name: ingredient.supplierItem.name,
                netWeight: ingredient.netWeight,
                grossWeight: ingredient.grossWeight,
                transformRate: ingredient.transformRate
            }
            if (tempIngredientStorage.has(customIndex)) {
                const ingredientInStorage = tempIngredientStorage.get(customIndex)
                ingredientInStorage.push(object)
                tempIngredientStorage.set(customIndex, ingredientInStorage)
            }
            else {
                tempIngredientStorage.set(customIndex, [object])
            }
        })
    })
    // for all step components that call another via prior steps structure, 
    //use their share "index" field to modify gross weight with the net weight of the previous one
    // only within the map so far
    const updatedIngredientsStorage = new Map(tempIngredientStorage)
    for (const [customIndex, ingredients] of updatedIngredientsStorage.entries()) {
        let justModifiedIngredient = modifiedStepComponents.find(elem => elem.index === customIndex)
        for (const ingredient of ingredients) {
            if (!justModifiedIngredient) continue
            ingredient.grossWeight = justModifiedIngredient.netWeight
            ingredient.netWeight = ingredient.grossWeight * (ingredient.transformRate / 100)
            ingredient.modified = true
            justModifiedIngredient = ingredient // prepare for next iteration
        }
    }
    const sanitizedMap = new Map()
    for (const [customIndex, ingredients] of updatedIngredientsStorage.entries()) {
        const modifiedIngredients = ingredients.filter(elem => elem.modified)
        if (modifiedIngredients.length > 0) {
            sanitizedMap.set(customIndex, modifiedIngredients)
        }
    }
    // report weight modifications in the structure of the form, 
    // meaning on each step (productionStep)
    for (const [customIndex, ingredients] of sanitizedMap.entries()) {
        for (const ingredient of ingredients) {
            const { stepIndex, netWeight, grossWeight } = ingredient
            const step = getProductionStepPointerObj(productionSteps[stepIndex], isProductionSteps)
            findAndUpdateStepComponent(step, customIndex, netWeight, grossWeight)
        }
    }
}

export const searchRecipesByUniqueCodeOrName = (packagingExecutions = [], search) => {
    return packagingExecutions.filter((pe) => {
      return pe.uniqueCode.toLowerCase().includes(search.toLowerCase()) || pe.recipeName.toLowerCase().includes(search.toLowerCase())
    })
}

const setServingUnit = async (item, supplierItemId) => {
    const formattedSupplierItem = await getIngredient(supplierItemId)
    if (formattedSupplierItem && formattedSupplierItem.get("units")) {
        const supplierItemUnits = formattedSupplierItem.get("units")
        item.servingUnit = supplierItemUnits.servingUnit || "weight"
    }
}

export const getSupplierItemPieceUnitWeight = (stepComponent, supplierItem) => {
    if (
        supplierItem 
        && stepComponent
        && supplierItem.units
        && supplierItem.units.conversions
        && supplierItem.units.conversions.length > 0
        && (stepComponent.servingUnit || supplierItem.units.servingUnit) === "piece" 
        && !stepComponent.priorSteps) {
            const pieceConversion = convertKilosIntoGrams(supplierItem.units.conversions.find(unit => unit.type === 2).value || 1)
            return convertKilosIntoGrams(stepComponent.grossWeight || 0) / pieceConversion
    }
    return null
}

/**
 * Initializes the base unitGrossWeight and unitNetWeight for an ingredient 
 * @param {Object} ingredient
 * @param {boolean} isPriorSteps 
 * @param {Map} unitWeightsCache
 */
export const setUnitWeightInStepComponent = async (ingredient, unitWeightsCache) => {
    
    // Retrieve the supplier item to get the conversion data.
    if (ingredient.supplierItem) {
        const formattedSupplierItem = await getIngredient(ingredient.supplierItem.id || ingredient.supplierItem.objectId)
        const supplierItemUnits = formattedSupplierItem.get("units")
    
        if (ingredient.servingUnit === "piece") {
            // Compute the base unit values from the supplier conversion.
            const baseUnitGross = supplierItemUnits.conversions.find(item => item.type === 2)?.value || null
            const baseUnitNet = baseUnitGross ? baseUnitGross * ingredient.transformRate / 100 : null
            ingredient.unitGrossWeight = baseUnitGross
            ingredient.unitNetWeight = baseUnitNet
            // Store the base values in our map so that recursive calls can use them.
            unitWeightsCache.set(ingredient.index, { unitGross: baseUnitGross, unitNet: baseUnitNet })
        }
    }
}

/**
 * Set the unitGrossWeight and unitNetWeight fields in each item of the priorStepsSupplierItems array.
 * @param {Array} priorStepsSupplierItems
 * @param {Map} unitWeightsCache 
 */
export const setUnitWeightsInPriorStepsSupplierItems = (priorStepsSupplierItems, unitWeightsCache) => {
    priorStepsSupplierItems.forEach(item => {
        if (unitWeightsCache.has(item.index)) {
            const { unitNet } = unitWeightsCache.get(item.index)
            const newUnitGross = unitNet
            const newUnitNet = newUnitGross * item.transformRate / 100
            // Set value in the priorStepsSupplierItem
            item.unitGrossWeight = newUnitGross
            item.unitNetWeight = newUnitNet
            // Update the map so deeper recursion sees the new values
            unitWeightsCache.set(item.index, { unitGross: newUnitGross, unitNet: newUnitNet })
        }
    })
}

const updateUnitWeightsCacheForReusableStep = (step, unitWeightsCache) => {
    step.stepComponents.forEach(stepComponent => {
        if ( stepComponent.servingUnit === "piece" && stepComponent.unitGrossWeight && stepComponent.unitNetWeight ) {
            unitWeightsCache.set(stepComponent.index, {
                unitGross: stepComponent.unitGrossWeight,
                unitNet: stepComponent.unitNetWeight,
            })
        }
        if (stepComponent.priorStepsSupplierItems) setUnitWeightsInPriorStepsSupplierItems(stepComponent.priorStepsSupplierItems, unitWeightsCache)
    })
}
