import Parse from "parse"

import { actionWithLoader } from "../Utils/utils"

export const generatePackagingExecutionReprodBySections = (values) => {
  return actionWithLoader(async () => {
    const packagingExecutionId = values.objectId
    const sections = values.sections.map((section) => {
      const sectionId = section.section?.id || section.section?.objectId
      const productionStepExecutionId = section.productionStepExecution?.id || section.productionStepExecution?.objectId
      const counterWeighing = section.counterWeighing
        ? {
          weight: section.counterWeighing.weight,
          reason: section.counterWeighing.reason,
        } : {}
      return {
        sectionId,
        productionStepExecutionId,
        counterWeighing,
      }
    })

    const sectionsTest = sections.filter(s => s.sectionId === "9GUUVtGagC")
    const result = await Parse.Cloud.run("generatePackagingExecutionReprodBySections", {
      packagingExecutionId,
      sections: sectionsTest
    })

    return result
  })
}