import React from "react"
import { Stack, Typography } from "@mui/material"
import PackagingExecutionStatus from "../PackagingExecutionStatus"
import { PE_STATUSES_AND_LABELS } from "../../../utils/packagingExecutions"
import { PEPSEResumeLabel, PEPSEMultiplePackagingInfo, PEPSEResumeInfo, PEPSEResumeContainer } from "../../ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import { capitalizeFirstLetter } from "../../../utils"

const PackagingExecutionResume = ({ recipeLabel, status, packagingLine = "_", expectedPackagingNumber = 0, uniqueCode, packagingNumbers = [] }) => {

	const packagingNumber = packagingNumbers.find(item => item.brand === "FOODCHERI") && packagingNumbers.find(item => item.brand === "FOODCHERI").value || "1"
	return (<PEPSEResumeContainer>
		<Stack spacing={5}>
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="h6">
					{uniqueCode} - {recipeLabel}
				</Typography>
				<PackagingExecutionStatus
					statusValue={status}
					statusName={PE_STATUSES_AND_LABELS.find(item => item.key === status)?.label} />
			</Stack>
			<Stack spacing={3} direction="row">
				<Stack spacing={1}>
					<PEPSEResumeLabel>
						Ligne de barquettage
					</PEPSEResumeLabel>
					<PEPSEResumeInfo>
						{capitalizeFirstLetter(packagingLine)}
					</PEPSEResumeInfo>
				</Stack>
				<Stack spacing={1}>
					<PEPSEResumeLabel>
						Nb théorique de produits
					</PEPSEResumeLabel>
					<PEPSEResumeInfo>
						{expectedPackagingNumber}
					</PEPSEResumeInfo>
				</Stack>
				{packagingNumber>1 && 
					<Stack spacing={1}>
						<PEPSEResumeLabel>
							Nb de produits par barquette
						</PEPSEResumeLabel>
						<PEPSEMultiplePackagingInfo>
							{packagingNumber} par barquette
						</PEPSEMultiplePackagingInfo>
					</Stack>}
			</Stack>
		</Stack>
	</PEPSEResumeContainer>)
}

export default PackagingExecutionResume