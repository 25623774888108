//--------------------------------------------------------------------//
//----------------------- Parsers/Formatters -------------------------//
//--------------------------------------------------------------------//
import { grey } from "@mui/material/colors"
import { escapeRegExp } from "lodash"
import moment from "moment"
import dayjs from "dayjs"
import { isDate } from "date-fns"

export function getServerUrl() {
	if (process.env.REACT_APP_ENV === "DEV") {
		return "http://localhost:8080"
	}
	else if (process.env.REACT_APP_ENV === "PREPROD") {
		return "https://server-dot-kfc-preprod.appspot.com"
	}
	else if (process.env.REACT_APP_ENV === "PROD_KITCHEN") {
		return "https://kfc-api-kitchen.foodcheri.com"
	}
	else if (process.env.REACT_APP_ENV === "PROD_PRODUCT") {
		return "https://kfc-api-product.foodcheri.com"
	}
	else if (process.env.REACT_APP_ENV === "PROD_LOGISTIC") {
		return "https://kfc-api-logistic.foodcheri.com"
	}
}

export function getDataServerUrl() {
	if (process.env.REACT_APP_ENV === "DEV" ||
		process.env.REACT_APP_ENV === "PREPROD"
	) {
		return "https://data-api-preprod-dot-foodcheri-gae.appspot.com"
	}
	else if (process.env.REACT_APP_ENV === "PROD_KITCHEN" ||
		process.env.REACT_APP_ENV === "PROD_PRODUCT" ||
		process.env.REACT_APP_ENV === "PROD_LOGISTIC"
	) {
		return "https://data-api-prod-dot-foodcheri-gae.appspot.com"
	}
}

//--------------------------------------------------------------------//
//----------------------------- Misc ---------------------------------//
//--------------------------------------------------------------------//
export function sort(array, keySupplier) {
	array.sort((item1, item2) => {
		const item1Key = keySupplier(item1)
		const item2Key = keySupplier(item2)
		if (item1Key < item2Key) return -1
		if (item1Key > item2Key) return 1
		return 0
	})
}

export function sortCommercialNames(a, b) {
	if (a.data.get("name") < b.data.get("name")) { return -1 }
	if (a.data.get("name") > b.data.get("name")) { return 1 }
	return 0
}

export function first(array) {
	return (array && array.length) ? array[0] : null
}
export function remove(array, itemOrFunction) {
	let i
	if (typeof itemOrFunction === "function") {
		i = array.findIndex(itemOrFunction)
	} else {
		i = array.indexOf(itemOrFunction)
	}
	if (i !== -1) {
		removeIndex(array, i)
		return true
	}
	return false
}
export function removeIndex(array, index) {
	array.splice(index, 1)
}

export function clone(instance) {
	return Object.assign(Object.create(instance), instance)
}

/**
 * @param object
 * @param {array|Set} names
 * @returns {*}
 */
export function filter(object, names) {
	return Object.keys(object)
		.filter(key => names.has ? names.has(key) : names.includes(key))
		.reduce((obj, key) => {
			obj[key] = object[key]
			return obj
		}, {})
}


//to capitalize only first letter
export function capitalizeFirstLetter(string) {
	if (string == null || !string.length) {
		return string
	}
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function intersectionSet(set1, set2) {
	return new Set([...set1].filter(x => set2.has(x)))
}


//--------------------------------------------//
//------------------- Forms ------------------//
//--------------------------------------------//
export function required({ values, fieldNames, errors = {} }) {
	fieldNames.forEach(fieldName => {
		if (!values[fieldName]) {
			errors[fieldName] = "Champ obligatoire"
		}
	})
	return errors
}

/**
 *
 * @param password
 * @param errors (optional)
 * @param name (default : 'password') the name of the field in errors
 * @returns {String | Object} if errors is passed, it returns it, otherwise it returns the eventual error message
 */
export function validatePassword(password, errors, name = "password") {
	//---- errorMessage ----//
	let errorMessage
	if (password == null || password.length < 8) {
		errorMessage = "Votre mot de passe doit faire au moins 8 caractères."
	}

	//---- errors ----//
	if (errors) {
		if (errorMessage) {
			errors[name] = errorMessage
		}
		return errors
	} else {
		return errorMessage
	}
}

export function roundNumber(number, decimal = 4) {
	if (isNaN(number)) {
		return ""
	}
	const x = Math.pow(10, decimal)
	return (Math.round(number * x)) / x
}

export function roundCurrency(number) {
	return roundNumber(number, 3)
}

export const isNumber = (value) => typeof value === "number"

/** by default parse limit the number of returned rows to 100
* we have to pass this parseLimitRequest to every .(limit) if we need to retrieve more
* parse method "defaultLimit" exists (it could be configured globally server-side) but is only available from v. 5.4.0 onwards
* @see https://github.com/parse-community/parse-server/pull/8152/files
**/

export const parseLimitRequest = 100000

export const fullSeason = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
export const recipeFields = [
	"name", "commercialName", "status", "type", "brands", "isActive", "internalTag", "season", "description",
	"tva", "dlc", "preparation", "specialInstruction", "heatingInstructions", "gesters", "portionPerPlate", "packaging",
	"subPackaging", "instructions", "sameInstructions", "difficulty", "sections", "sameDescriptions", "price", "ean",
	"news", "ingredientsComplexity", "reusablePackaging", "reusableSubPackaging", "defaultValues"
]

export const recipeDetailsFields = [
	"status", "type", "brands", "description", "dlc", "preparation", "heatingInstructions", "gesters", "packaging",
	"subPackaging", "reusablePackaging", "reusableSubPackaging", "instructions", "price", "portionPerPlate", "appImage",
	"kitchenImage", "difficulty", "specialInstruction", "defaultValues", "tva", "commercialNames",
	"packagingNumber", "packagingNumberUnit"
]

export const ingredientFields = [
	"name", "supplierArticleId", "supplierName", "storageArea", "genericId", "pricePerKg", "weightPerKg", "orderUnitWeight",
	"commercialName", "group", "allergens", "cookingModes"
]

export const packagingFields = [
	"name", "price", "type", "weight", "brands", "quantity", "reference", "categories", "height", "deepth", "width",
	"nature", "supplierItem"
]

export const subcontractorFields = [
	"name", "address", "contact", "minimumOrder", "sanitaryApprovalNumber", "countryCode", "continentCode", "canExportEtiquettable"
]

export const subcontractorProductFields = [
	"name", "commercialName", "status", "type", "brand", "subcontractor", "isActive", "internalTag",
	"subcontractorCommercialNames", "season", "description", "tva", "dlc", "preparation", "specialInstruction",
	"sections", "heatingInstructions", "packaging", "subPackaging", "reusablePackaging", "reusableSubPackagings", "totalCost", "price", "ean", "chef", "uniqueCode",
	"spicy"
]

export const allergenIngredientFields = [
	"name"
]

export const familyIngredientFields = [
	"name"
]

export const groupIngredientFields = [
	"name", "family"
]

export const subcontractorCommercialNameFields = [
	"name", "subcontractor", "group", "allergens", "conservationMode", "firstOrigin", "secondOrigin", "label",
	"firstOriginAverage", "secondOriginAverage", "season"
]

export const commercialNameFields = [
	"name", "group", "allergens", "conservationMode", "firstOrigin", "secondOrigin", "label", "firstOriginAverage",
	"secondOriginAverage", "season", "complexity"
]

export const productsTagsFields = [
	"name", "type"
]

export const PRODUCTION_SCHEMA_FIELDS = ["name", "batches"]

export const COLORS = {
	BACKGROUND_GREY: "#F8F8F8",
	BACKGROUND_LIGHT_GREY: "#f0f0f0",
	DRAFT_GREY: "#7C7C7C",
	BLACK: "rgba(0, 0, 0, 0.87)",
	LIGHT_BLACK: "#212121",
	BLUEGREY: "#607d8b",
	BLUEGREY_700: "#455A64",
	BLUEGREY_500: "#607d8b",
	BLUEGREY_900: "#263238",
	BLACK_400: "#9B9B9B",
	BLACK800: "#262626",
	BLUEGREY_CUSTOM: "#E1EAEE",
	BLUE_50: "#e3f2fd",
	BLUE_FOODCHERI: "#151858",
	DARK_GREY: "#757575",
	DEFAULT_GREY: "#D8D8D8",
	DISABLED: "#a0a0a0",
	GREY_700: "#616161",
	BLACK_500: "#7C7C7C",
	DISABLED_DRAG: "#FFBDBD",
	DISABLED_INPUT_COLOR: "#F9F9F9",
	ENABLED_DRAG: "#DCFFEA",
	GREEN_100: "#c8e6c9",
	GREEN_200: "#a5d6a7",
	GREEN_400: "#66bb6a",
	GREEN_NUTRITION: "#43A741",
	GREEN_PLANNING: "#E6F4E6",
	GREEN_SEAZON: "#25B598",
	GREEN_STATUS: "#007E65",
	GREY_RECIPE_BACKGROUND: "#e5e5e5",
	LIGHT_GREY: "#F7F7F7",
	LIGHT_GREY_2: "#FAFAFA",
	LIGHT_GREY_3: "#EEEEEE",
	LIGHT_GREEN: "#CEECD6",
	LIGHT_PRIMARY_COLOR: "#68B6F4",
	MEDIUM_GREY: "#E7E7E7",
	ORANGE_CREDIT_NOTE: "#FB7311",
	ORANGE_PLANNING: "#FFF1DB",
	ORANGE_INVENTORY_BACKGROUND: "rgba(255, 115, 15, 0.5)",
	ORANGE_INVENTORY_BORDER: "rgba(255, 115, 15, 1)",
	PRIMARY_COLOR: "#2196f3",
	RED: "#FF5D5A",
	RED_50: "#ffebee",
	RED_100: "#ffcdd2",
	RED_200: "#ef9a9a",
	RED_ERROR_LIGHT: "#F44259",
	RED_ERROR: "#f44336",
	RED_WARNING: "#F53531",
	RED_PLANNING: "#FFDCDC",
	RED_NUTRITION: "#E10050",
	RED_RASPBERRY: "#E50050",
	RED_INVENTORY_BACKGROUND: "rgba(255, 0, 0, 0.5)",
	RED_BROKEN: "#F53635",
	SECONDARY_COLOR: "#e91e63",
	SELECTED_GREY: "#d0d0d0",
	TEXT_COLOR_FOR_PRIMARY: "#ffffff",
	WHITE: "#ffffff",
	DARK_BLACK: "#000000",
	RED_SECONDARY: "#D50000",
	GREY_BORDER: "#BDBDBD",
	GREY_SEARCH: "rgba(0, 0, 0, 0.54)",
	BLACK_CARD: "rgba(0, 0, 0, 0.87)",
	RED_INVENTORY_WARNING: "#E53935",
	BLUE_PAPER_SELECTED: "#898989",
	GREEN_INVENTORY: "#00C853",
	GREEN_CARD_INVENTORY: "#E8F5E9",
	BROWN: "#FF6F00",
	RED_FLAW: "#FF1744",
	YELLOW_OUTPUT: "#FFBE15",
	GREY_FLAW_ICON: "#C4C4C4",
	GREY_DISABLED: "#4C4C4C",
	GREEN_CARD_TEXT: "#689F38",
	GREY_BACK_BUTTON: "#9E9E9E",
	GREY_CARD_BLURED: "#E3E3E3",
	GREY_ORDER_BORDER: "#DCDCDC",
	HEAD_FC: "#151858",
	HEAD_SEAZON: "#25B598",
	HEAD_MONOP: "#f52b27",
	HEAD_FRIGO: "#D2B800",
	WHITE_INPUT: "rgba(255, 255, 255, 0.83)",
	SECTION_INHERITED: "#D7C6ED",
	STEP_INHERITED: "#E7DCF7",
	INGREDIENT_INHERITED: "#F8F3FF",
	BUTTON_INHERITED: "#603598",
	GREY_NAT_BORDER: grey[300],
	LIGHT_SLIDER: "#bfbfbf",
	LIGHT_BLUE_SLIDER: "#3880ff",
	LEFT_FILTER_SEARCH: "#d9eef4",
	RIGHT_DESCRIPTION_BKG: "#F5F5F5",
	RIGHT_TAG_CHIP: "#F6F7F7",
	PACKAGING_REUSABLE: "#CEECD6",
	PACKAGING_DISPOSABLE: "#D0B298",
	PACKAGING_CAPPED: "#FEE39E",
	PACKAGING_ROW_SELECTED: "rgba(0, 0, 0, 0.04)",
	SITE_INFORMATION_VALUE: "#555555",
	SITE_SUBTITLE: "#C0C0C0",
	SITE_BORDER: "#DADADA",
	SITE_BOX_SHADOW: "rgba(0, 0, 0, 0.05)",
	INVENTORY_BOX_SHADOW: "rgba(0, 0, 0, 0.2)",
	SUPPLIER_DELIVERY_COlOR: "#4CAF50",
	GREY_SUPPLIER_BACKGROUND: "#FCFCFC",
	INFORMATION_TITLE: "rgba(0, 0, 0, 0.6)",
	GREY_IMAGE_LIST: "#F6F6F6",
	GREEN_VALIDATOR: "#0BA812",
	GREY_900: "#1A1A1A",
	GREEN_LOT_INVENTORY: "#F1FFF2",
	GREEN_LOT_INVENTORY_TEXT: "#368F50",
	ORDER_BLUE: "rgba(33, 150, 243, 0.5)",
	DELIVERY_NOTE_CARD_SHADOW1: "rgba(0, 0, 0, 0.12)",
	DELIVERY_NOTE_CARD_SHADOW2: "rgba(0, 0, 0, 0.24)",
	DELIVERY_NOTE_CARD_TITLE: "#A3A3A3",
	BLUE_CREDIT_DELETE: "#D4E7F7",
	BLUE_CREDIT_TOOLBAR: "#0075DA",
	BLUE_LOT_GROUP: "#3390dd",
	VIOLET_DISPATCH: "#f200ff",
	PRODUCTION_STEPS_BLUE: "#E3F2FD",
	PRODUCTION_STEPS_TEXT_GREY: "#414141",
	PRODUCTION_STEPS_DISABLE_TEXT: "#9B9B9B",
	PRODUCTION_STEPS_GREY: "#F8F8F8",
	PRODUCTION_STEPS_COMPONENT_WHITE: "#FFF",
	YELLOW_REUSABLE_STEP_PARENT: "#FDF9C9",
	DARK_YELLOW_REUSABLE_STEP_PARENT: "#F6D74F",
	DOWNLOAD_DOCUMENT_BLUE: "#2196F3",
	UTILITARY_PURPLE: "#7900D9",
	PRODUCTION_SCHEMA_BORDER_COLOR: "#E6E6E6",
	HEADER_BLUE: "#F0F9FF",
	LABEL_GREY: "#555555",
	THEORICAL_TIME_PSE: "#9B9B9B",
	PACKAGING_EXECUTION_YELLOW: "#F2C046",
	SECONDARY_DLC_BLUE: "#1045A1",
	PRODUCTION_RECEPTION_COLOR: "#2196F3"
}

export const KFC_BRANDS = [
	{
		name: "FOODCHERI",
		shortname: "FC",
		label: "FoodChéri",
		isActive: true,
		color: "#000000",
		image: "/img/FC_Black@2x.png",
		whiteImage: "/img/FC_white@2x.png",
		nationalSendField: true, // true if we can disable national send in meal planner
		sendCapitalField: true,
		smallRetailField: false,
		lunchbagField: true, // true if we can set an item to lunchbag in meal planner
		priceEnabled: true, // If false we cannot change the price in recipe form
		sellPageTags: [ // Count by tags to display in sell planning section headers
			{ key: "Vegan", displayName: "Vegans", sections: [] }, // If sections is empty, it displays it everywhere
			{ key: "Végétarien", displayName: "Végétariens", sections: [] },
			{ key: "Froid", displayName: "Froids", sections: [] },
			{ key: "FC Corner", displayName: "FC corner", sections: [] },
			{ key: "Petit prix", displayName: "Petit prix", sections: ["MAIN_COURSE"] }
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Retirez le couvercle avant de réchauffer"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: true
		},
		monitoring: {
			types: ["STARTER", "MAIN_COURSE+MEAL_PREP", "DESSERT", "BREAD", "CHEESE"] // Types of item to display in monitoring view
		},
		applyWaste: true,
		pctLimit: 22
	},
	{
		name: "SEAZON",
		shortname: "SZ",
		label: "Seazon",
		isActive: true,
		color: "#25B598",
		image: "/img/logoszn.png",
		whiteImage: "/img/whitelogoszn.png",
		nationalSendField: false,
		sendCapitalField: true,
		smallRetailField: false,
		lunchbagField: false,
		priceEnabled: true,
		sellPageTags: [
			{ key: "Vegan", displayName: "Vegans", sections: [] },
			{ key: "Végétarien", displayName: "Végétariens", sections: [] },
			{ key: "Froid", displayName: "Froids", sections: [] },
			{ key: "SZ Athletics", displayName: "SZN Athletics", sections: [] }
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Soulevez l'opercule et réchauffez"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: false
		},
		monitoring: {
			types: ["BREAKFAST", "MAIN_COURSE+MEAL_PREP"]
		},
		applyWaste: false,
		pctLimit: 26.5
	},
	{
		name: "MONOPRIX",
		shortname: "MN",
		label: "Monop'",
		isActive: false,
		color: "#f52b27",
		image: "/img/logomonop.png",
		whiteImage: "/img/whitelogomonop.png",
		nationalSendField: true,
		sendCapitalField: true,
		smallRetailField: true,
		lunchbagField: false,
		priceEnabled: true,
		sellPageTags: [
			// TODO: fill this array
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Soulevez l'opercule et réchauffez"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: false
		},
		monitoring: {
			types: ["MAIN_COURSE+MEAL_PREP"]
		},
		applyWaste: false,
		pctLimit: 31
	},
	{
		name: "FRIGONU",
		shortname: "NU",
		label: "FrigoCo",
		isActive: true,
		color: "#D2B800",
		image: "/img/logofrigoco.png",
		whiteImage: "/img/whitelogofrigoco.png",
		nationalSendField: true,
		sendCapitalField: true,
		smallRetailField: true,
		lunchbagField: false,
		priceEnabled: true,
		sellPageTags: [
			// TODO: fill this array
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Soulevez l'opercule et réchauffez"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: false
		},
		monitoring: {
			types: ["MAIN_COURSE+MEAL_PREP", "DESSERT+YAOURT"] // Types of item to display in monitoring view
		},
		applyWaste: false,
		pctLimit: 31
	},
	{
		name: "SEAZON_BE",
		shortname: "SZ_BE",
		label: "Seazon BE",
		isActive: false,
		color: "#9C2762",
		image: "/img/logosznbe.png",
		whiteImage: "/img/whitelogosznbe.png",
		nationalSendField: false,
		sendCapitalField: true,
		smallRetailField: false,
		lunchbagField: false,
		priceEnabled: true,
		sellPageTags: [
			{ key: "Vegan", displayName: "Vegans", sections: [] },
			{ key: "Végétarien", displayName: "Végétariens", sections: [] },
			{ key: "Froid", displayName: "Froids", sections: [] },
			{ key: "SZ Athletics", displayName: "SZN Athletics", sections: [] }
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Soulevez l'opercule et réchauffez"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: false
		},
		monitoring: {
			types: ["MAIN_COURSE+MEAL_PREP", "BREAKFAST"]
		},
		applyWaste: false,
		pctLimit: 26.5
	},
	{
		name: "EMPLOYEES_MEALS",
		shortname: "PERSO",
		label: "Repas Perso",
		isActive: true,
		color: "#FFAF37",
		image: "/img/logorepasperso.png",
		whiteImage: "/img/whitelogorepasperso.png",
		nationalSendField: true,
		sendCapitalField: true,
		smallRetailField: false,
		lunchbagField: false,
		priceEnabled: false,
		sellPageTags: [
			// TODO: fill this array
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Soulevez l'opercule et réchauffez"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: false
		},
		monitoring: {
			types: ["MAIN_COURSE"] // Types of item to display in monitoring view
		},
		applyWaste: false,
		pctLimit: 31
	},
	{
		name: "OLYMPIC_GAMES",
		shortname: "JO",
		label: "Jeux Olympiques",
		isActive: true,
		color: "#FF69DD",
		image: "/img/brands/og_logo.svg",
		whiteImage: "/img/brands/og_logo.svg",
		nationalSendField: true,
		sendCapitalField: true,
		smallRetailField: false,
		lunchbagField: false,
		priceEnabled: false,
		sellPageTags: [
			// TODO: fill this array
		],
		defaultHeatingInstructions: {
			microwave: {
				power: 900,
				duration: 2.5,
				instructions: "Retirez le couvercle avant de réchauffer"
			},
			oven: {
				power: 150,
				duration: 12,
				instructions: "Transférez dans un plat adapté"
			},
			pan: {
				power: "",
				duration: "",
				instructions: ""
			}
		},
		recipesList: {
			displayFoodcostPCT: true
		},
		monitoring: {
			types: ["MAIN_COURSE"] // Types of item to display in monitoring view
		},
		applyWaste: false,
		pctLimit: 31
	},
]

export const ACTIVE_KFC_BRANDS = KFC_BRANDS.filter(brand => brand.isActive)

export const ACTIVE_KFC_BRANDS_NAMES = ACTIVE_KFC_BRANDS.map(brand => brand.name)

export function forEachBrand(callback, activeOnly = false) { // shortcut, avoid to write loops everywhere
	const BRANDS = activeOnly ? ACTIVE_KFC_BRANDS : KFC_BRANDS
	for (const brand of BRANDS) {
		callback(brand)
	}
}

export const FORCE_DISPLAY_SIDEBAR_URLS = [
	"/productionStepExecutions/filter",
	"/productionReception/filter",
]

export const HIDE_SIDEBAR_STANDARD_URLS = [
	"/settings/administration/productTypes/edition",
	"/settings/production/productionSchemas/form",
	"/productionStepExecutions/consultation",
	"/productionStepExecutions/hours",
	"lotInventory/lotEventsHistory"
]

export const HIDE_SIDEBAR_DYNAMIC_URLS = [
	/** BRIQUE PLANIFICATION **/
	{path: "/mealplanner/", minLength: 25},
	{path: "/weeklyMealplanner/", minLength: 25},

	/** BRIQUE APPRO  **/
	{path: "/order/", minLength: 19}, // ex: order/UPK3lbT78C/BfsuV39E8o
	{path: "/order/", minLength: 7}, // ex: order/UPK3lbT78C
	{path: "/orders/", minLength: 9}, // ex: orders/UPK3lbT78C
	{path: "/orderReceptions/", minLength: 43}, // ex: /orderReceptions/UPK3lbT78C/1706832000000/P65NdYrLmY
	{path: "/billingCreditNotes/", minLength: 57}, // ex: /billingCreditNotes/UPK3lbT78C/1706832000000/P65NdYrLmY/control
	{path: "/orderReceptions/", minLength: 54}, // ex: /orderReceptions/UPK3lbT78C/1709856000000/FGsLhpGrAI/deliveryNoteControl

	/** BRIQUE APPRO**/
	{path: "/lotInventory/lotEventsHistory/", minLength: 30 },

	/** BRIQUE PRODUCTION **/
	{path: "/packagingExecutions/read/", minLength: 27}, // ex: /packagingExecutions/read/UPK3lbT78C/2024-03-19
	{path: "/packagingExecutions/hours/", minLength: 27}, // ex: /packagingExecutions/hours/UPK3lbT78C/2024-03-19
	{path: "/packagingExecutions/packagingExecution/", minLength: 63}, // ex: /packagingExecutions/packagingExecution/UPK3lbT78C/2024-03-18/gCh0IUsm47
	{path: "/productionStepExecutions/hours/", minLength: 33},
	{path: "/productionStepExecutions/preparation/", minLength: 39},
	{path: "/productionStepExecutions/", minLength: 27}, // productionStepExecutions/yvtFPBJgdd

	/** BRIQUE SETTINGS  **/
	{path: "/settings/resources/section/", minLength: 29},
	{path: "/settings/resources/reusableSteps/edit/", minLength: 40},

	/** PRODUCTION RECEPTION **/
	{path: "/productionReception/", minLength: 21},
]
/**
 * get brand label from list of brand value (from db)
 * @param {*} brands 
 * @returns 
 */
export const getBrandsLabels = (brands = []) => {
	const filteredBrands = ACTIVE_KFC_BRANDS.filter(brand => brands.includes(brand.name))
	const labels = filteredBrands.map(brand => brand.label) || []
	return labels.join(", ")
}

export function getBrandMonitoringName(brand, type) {
	return KFC_BRANDS.find(b => b.name === brand).monitoring.types.find(typ => typ.split("+").includes(type))
}

/**
 * get database product brands data (ex: image, label)
 * @param {*} brands 
 * @returns 
 */
export const getBrandsData = (brands) => KFC_BRANDS.filter((brand) => brands.includes(brand.name))

export function getMenuProps() {
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8

	return {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	}
}

export function getBrandBy(criteria, value) {
	return KFC_BRANDS.find(brand => brand[criteria] === value)
}

export const getBrandsOptions = (brands = ACTIVE_KFC_BRANDS) => {
	return brands.map(brand => ({ value: brand.name, label: brand.label }))
}

export const DEFAULT_DASHBOARD_VIEW = "MONITORING"

// check connecte device tablet or
export function checkTablet() {
	const width = window.innerWidth
	const height = window.innerHeight

	// display screen for tablet or mobile
	if (width < 1024 && height <= 1280) {
		return true
	}

	return false
}

export function setSearchValue(value, scope) {
	let storage = JSON.parse(localStorage.getItem("searchValue"))
	if (!storage) storage = {}

	storage[scope] = value
	localStorage.setItem("searchValue", JSON.stringify(storage))
}

export function setRecipeListStorage(value, scope) {
	let storage = JSON.parse(localStorage.getItem("recipeList"))
	if (!storage) storage = {
		brands: null,
		types: null,
		Status: null,
		isActive: null
	}

	storage[scope] = value
	localStorage.setItem("recipeList", JSON.stringify(storage))
}

export function resetRecipeListStorage() {
	let storage = JSON.parse(localStorage.getItem("recipeList"))
	if (storage) storage = {
		brands: null,
		types: null,
		Status: null,
		isActive: null
	}

	localStorage.setItem("recipeList", JSON.stringify(storage))
}

export function setSubcontractorProductListStorage(value, scope) {
	let storage = JSON.parse(localStorage.getItem("subcontractorProductList"))
	if (!storage) storage = {
		brands: null,
		types: null,
		Status: null,
		isActive: null,
		subcontractors: null
	}

	storage[scope] = value
	localStorage.setItem("subcontractorProductList", JSON.stringify(storage))
}

export function resetSubcontractorProductListStorage() {
	let storage = JSON.parse(localStorage.getItem("recipeList"))
	if (storage) storage = {
		brands: null,
		types: null,
		Status: null,
		isActive: null,
		subcontractors: null
	}

	localStorage.setItem("subcontractorProductList", JSON.stringify(storage))
}

export function setLotInventoryOutputStorage(value, scope, mode) {
	let storage = JSON.parse(localStorage.getItem("filterLotInventoryOutput"))

	if (!storage || (storage && !storage[mode])) {
		if (!storage) storage = {}

		storage[mode] = {
			dlc: null,
			groupIngredients: null
		}
	}

	storage[mode][scope] = value
	localStorage.setItem("filterLotInventoryOutput", JSON.stringify(storage))
}

export function resetLotInventoryOutputStorage(mode) {
	let storage = JSON.parse(localStorage.getItem("filterLotInventoryOutput"))
	if (storage && storage[mode]) storage = {
		dlc: null,
		groupIngredients: null
	}

	localStorage.setItem("filterLotInventoryOutput", JSON.stringify(storage))
}

export const filterWithSearchValue = (searchValue, toFiltered, filterOn = "name", ingredient = false) => {
	let newFilter = []
	if (searchValue !== null && searchValue !== "") {
		const regex = new RegExp(escapeRegExp(searchValue), "ig")
		if (!ingredient) {
			newFilter = toFiltered.filter(elm => elm[filterOn] && elm[filterOn].toString().match(regex) !== null)
		} else {
			newFilter = toFiltered.filter(elem => (elem.commercialName && elem.commercialName.name.match(regex)))
		}
	} else {
		newFilter = toFiltered
	}

	return newFilter
}

export const filterOnSomeFields = (searchValue, toFiltered, filterOn) => {
	let newFilter = []
	if (searchValue !== null && searchValue !== "") {
		const regex = new RegExp(searchValue, "ig")
		newFilter = toFiltered.filter(elm => filterOn.some(f => elm[f] && elm[f].match(regex) !== null))
	} else {
		newFilter = toFiltered
	}

	return newFilter
}

/**
 * check if a filters is saved to the store
 * @param {*} filters name, productTypes, suppliers
 * @returns 
 */
export const hasSupplierItemsSavedFilter = (filters) => {
	return Object.keys(filters).some(key => {
		// name field
		if (typeof filters[key] === "string") {
			return filters[key]
		}
		// productTypes & suppliers field
		return filters[key].length > 0
	})
}

export const checkMultiSelectInclude = (source, selected, value) => {
	let newSelected = value

	if (!selected.includes("ALL") && value.includes("ALL")) {
		newSelected = source.map(elem => elem.value)
	}
	else if (selected.includes("ALL") && !value.includes("ALL")) {
		newSelected = []
	}
	else if (!newSelected.includes("ALL") && newSelected.length === source.length - 1) {
		newSelected.push("ALL")
	}
	else if (newSelected.includes("ALL") && newSelected.length < source.length) {
		newSelected = newSelected.filter(elem => elem !== "ALL")
	}

	return newSelected
}

export const checkSubcontractorMultiInclude = (source, selected, value) => {
	let newSelected = value

	if (!selected.includes("ALL") && value.includes("ALL")) {
		newSelected = [...source, "ALL"]
	}
	else if (selected.includes("ALL") && !value.includes("ALL")) {
		newSelected = []
	}
	else if (!newSelected.includes("ALL") && newSelected.length === source.length) {
		newSelected.push("ALL")
	}
	else if (newSelected.includes("ALL") && newSelected.length === source.length) {
		newSelected = newSelected.filter(elem => elem !== "ALL")
	}

	return newSelected
}

export const concatAddress = (address, address1 = "", zipCode = "", city = "", country = "") => {
	let longAddress = address

	if (address1) longAddress += `, ${address1}`
	if (zipCode) longAddress += `, ${zipCode}`
	if (city) longAddress += `, ${city}`
	if (country) longAddress += `, ${country}`

	return longAddress
}

export const getDiffArrays = (a, b) => {
	const res = []

	for (const i in a) {
		const current = a[i]
		if (!b.includes(current)) {
			res.push(current)
		}
	}

	return res
}

export const isAmbientProduct = (element) => {
	if (element.productType === "SNACK" || element.productType === "YAOURT" || element.productType === "DRINK") {
		return true
	}
}

/**
 * generate random string with given length
 * @param {*} length 
 * @returns 
 */
export const generateRandomToken = (length = 12) => {
	return Math.random().toString(20).substr(2, length)
}

export const getCellAlignment = (align) => {
	switch (align) {
		case "center":
			return "center"
		case "right":
			return "flex-end"
		default:
			return "flex-start"
	}
}

export const isFalsyOtherThanZero = (field) => {
	return field === 0 || field
}

/**
 * Generate a string which vowels are replaced with accentuated equivalents
 * @param {String} value 
 * @returns {String}
 */
export const generateAccentuatedStringPattern = value => {
	if (!value || typeof value !== "string") return ""
	return value
		.replace(/[aáàäâ]/gi, "[aáàäâ]")
		.replace(/[eéëèê]/gi, "[eéëèê]")
		.replace(/[iíïìî]/gi, "[iíïìî]")
		.replace(/[oóöòô]/gi, "[oóöòô]")
		.replace(/[uüúùû]/gi, "[uüúùû]")
}

/**
 * change csv separator in column text to avoid column decalage
 * @param {*} str 
 * @returns 
 */
export const replaceCSVSeparatorInText = (str) => {
	let formattedValue = ""
	if (str) {
		formattedValue = str.replaceAll(":", ".")
		formattedValue = formattedValue.replaceAll(";", ".")
	}

	return formattedValue
}

/**
 * 
 * @param {*} date timestamp
 */
export const getTimezoneDate = (date, returnType = "timestamp", startOfDay = true) => {
	const timezoneOffset = moment(date).utcOffset()
	const dateWithOffset = startOfDay ? moment(date).utc().add(timezoneOffset, "minutes").startOf("day") : moment(date).utc().add(timezoneOffset, "minutes")

	if (returnType === "moment") {
		return dateWithOffset
	}
	return dateWithOffset.valueOf()
}

export const dateToTimestamp = (date) => {
  return dayjs.utc(date).valueOf()
}

export const timestampToDate = (timestamp, format = "YYYY-MM-DD") => {
	if (!timestamp) return ""
	return dayjs(timestamp).format(format)
}

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))


export const isDateObject = (value) => {
	return isDate(value) || moment.isMoment(value) || dayjs.isDayjs(value)
}

export const camelToSnakeCase = (str) => {
	return str.replace(/([A-Z])/g, letter => `_${letter.toLowerCase()}`)
}

export const convertKeysToSnakeCase = (obj) => {
	const newObj = {}
	
	for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
					const snakeCaseKey = camelToSnakeCase(key)
					newObj[snakeCaseKey] = obj[key]
			}
	}
	
	return newObj
}

export const composes = (...fns) => (inputValue) => fns.reduce((acc, fn) => fn(acc), inputValue)

export const getNameInEmail = (email) => {
	const [name] = email.split("@")
	return name
}