import React, { useState } from "react"

import { Field } from "formik"
import {
  Autocomplete,
  CircularProgress,
  styled,
  TextField
} from "@mui/material"
import { debounce } from "lodash"

const autocompleteSx = {
  textField: {
    "& .MuiInput-input": {
      cursor: "pointer"
    }
  }
}

export const StyledAutocompleteTextField = styled(TextField)({
  "& .MuiAutocomplete-inputRoot.MuiInputBase-root": {
    "&:before": {
      borderBottom: "none",
      "&:hover": {
        borderBottom: "none"
      }
    },
    "& .MuiAutocomplete-input": {
      padding: 4
    }
  },
  "& .MuiInput-input": {
    fontWeight: 600,
    fontSize: 14,
    color: "#414141"
  }
})

const FormikAutocomplete = ({
  form,
  loading,
  label,
  field,
  key = "1",
  readOnly = false,
  ...props
}) => {
  const { value } = field
  const { handleChange } = form

  return (
    <Autocomplete
      {...props}
      sx={{ flex: 1, pointer: "cursor" }}
      options={props.options}
      value={value}
      key={key}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label={label}
          sx={readOnly ? autocompleteSx.textField : null}
          onChange={handleChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

const FormikAutocompleteField = ({
  setFieldValue,
  name,
  label,
  options,
  getOptionLabel, // { value (ex: objectId), data (parse object json)}
  onSearch,
  onBlur,
  onFocus,
  onChange = null,
  key = null
}) => {
  const [loading, setLoading] = useState(false)

  const handleChange = (_, newValue) => {
    setFieldValue(name, newValue.data)
    if (onChange) {
      onChange()
    }
  }

  const handleInputChange = debounce(
    async (event, newValue) => {
      if (newValue && event?.type === "change") {
        setLoading(true)
        await onSearch(newValue)
        setLoading(false)
      }
    },
    700
  )

  return (
    <Field
      name={name}
      label={label}
      loading={loading}
      component={FormikAutocomplete}
      options={options}
      isOptionEqualToValue={(option, value) =>
        value && option.value === value.objectId
      }
      getOptionLabel={option => getOptionLabel(option.data || option)}
      onChange={handleChange}
      onInputChange={handleInputChange}
      disableClearable
      onFocus={onFocus}
      onBlur={onBlur}
      key={key}
    />
  )
}

export default FormikAutocompleteField
