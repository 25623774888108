import React, { useState, useEffect } from "react"
import { Add } from "@mui/icons-material"
import {
    Box,
    Fab
} from "@mui/material"
import dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux"
import DataGrid from "../../components/DataGrid/DataGrid"
import { showReusableProductionStepCreation, showReusableProductionStepView } from "../../actions/Steps/reusableProductionSteps."
import { getReusableProductionStepsSelector, getReusableStepsSupplierItemsSelector } from "../../reducers/Steps/steps"
import { getSupplierItemByName } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

const filterSupplierItems = (ingredients = []) => {
    const filteredSupplierItems = ingredients.filter((ingredient) => ingredient.supplierItem.isActive && !ingredient.supplierItem.deleted)

    return filteredSupplierItems
}

const ReusableSteps = () => {
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])

    const dispatch = useDispatch()
    const steps = useSelector(getReusableProductionStepsSelector)
    const supplierItems = useSelector(getReusableStepsSupplierItemsSelector)

    const goToReusableStepCreation = () => dispatch(showReusableProductionStepCreation())

    useEffect(() => {
        setColumns([
            {
                key: "id",
                label: "ID",
                dataType: "text",
                sortable: false,
                filter: {
                    filterable: true,
                    type: "text",
                    strict: true
                },
                width: 120
            },
            {
                key: "name",
                label: "Nom",
                dataType: "text",
                sortable: false,
                filter: {
                    filterable: true,
                    type: "text",
                    fullText: true
                }
            },
            {
                key: "supplierItems",
                label: "Articles fournisseur",
                dataType: "text",
                sortable: false,
                filter: {
                    filterable: true,
                    type: "select",
                    withSearch: true,
                    options: supplierItems
                        .map((supplierItem) => ({
                            key: supplierItem.objectId,
                            value: supplierItem.name,
                            label: supplierItem.name
                        }))
                },
            },
            {
                key: "transformationModes",
                label: "Mode de transformation",
                dataType: "text",
                sortable: false,
                filter: {
                    filterable: true,
                    type: "select",
                    options: []
                },
            },
            {
                key: "updatedAt",
                label: "Date de modification",
                dataType: "date",
                type: "date",
                sortable: true,
                filter: {
                    filterable: false,
                    type: "date"
                },
                width: 130
            }
        ])
    }, [supplierItems])

    useEffect(() => {
        const formattedSteps = steps.map(step => ({
            id: step.objectId,
            name: step.name,
            supplierItems: filterSupplierItems(step.ingredients).map((ingredient) => ingredient.supplierItem.name).join(", "),
            transformationModes: step.transformations?.map((transformationMode) => transformationMode.transformationMode.name).join(", ") || "",
            updatedAt: dayjs(step.updatedAt).format("DD/MM/YYYY")
        }))
    
        setData(formattedSteps)
    }, [steps])

    const handleFilterChange = async (values) => {
        if (!values.supplierItems) return

        const selectedSupplierItem = await getSupplierItemByName(values.supplierItems, ["transformationModes.transformationMode"], false)

        if (!selectedSupplierItem) return

        const transformationModes = selectedSupplierItem.get("transformationModes") || []

        const transformationModesOptions = transformationModes.map((transformationMode) => ({
            key: transformationMode.transformationMode.id,
            value: transformationMode.transformationMode.get("name"),
            label: transformationMode.transformationMode.get("name")
        }))
    
        // get transformation modes options by selected supplier item filter
        setColumns(prev => prev.map(column => {
            if (column.key === "transformationModes") {

                column.filter.options = transformationModesOptions

                return column
            }
            return column
        }))
    }

    const handleRowClick = (step) => {
        dispatch(showReusableProductionStepView(step.id))
    }

    const handleResetFilters = (key) => {
        if (key !== "supplierItems") return

        setColumns(prev => prev.map(column => {
            if (column.key === "transformationModes") {
                column.filter.options = []

                return column
            }
            return column
        }))
    }

    return (
        <>
            <DataGrid
                title="Étapes réutilisables"
                columns={columns}
                data={data}
                withFilters
                rowLabel="étapes réutilisables"
                onRowClick={handleRowClick}
                onFilterChange={handleFilterChange}
                onResetFilters={handleResetFilters}
            />
            {/* --------- add button --------- */}
            <Box sx={{ position: "fixed", left: 260, bottom: 20 }}>
                <Fab color="primary" onClick={goToReusableStepCreation}>
                    <Add />
                </Fab>
            </Box>
        </>
    )
}

export default ReusableSteps
