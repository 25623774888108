import React, { useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Box } from "@mui/material"
import {
	PSE_STATUS,
	updateProductionStepExecutionComments
} from "../../actions/ProductionSteps/ProductionStepExecutions"
import {
	getAllPSECommentsExecutionSelector,
	getProductionStepExecutionSelector
} from "../../reducers/Production/ProductionSteps"
import ProductionStepExecutionTodo from "./ProductionStepExecutionTodo"
import ProductionStepExecutionInProgress from "./ProductionStepExecutionInProgress"
import ProductionStepExecutionToTest from "./ProductionStepExecutionToTest"
import ProductionStepExecutionDone from "./ProductionStepExecutionDone"
import CommentsDrawer from "../../components/Comments/CommentsDrawer"

const ProductionStepExecution = () => {
	const dispatch = useDispatch()
	const productionStepExecution = useSelector(getProductionStepExecutionSelector)
	const pseComments = useSelector(getAllPSECommentsExecutionSelector)

	const [commentsDrawerShown, setCommentsDrawerShown] = useState(false)

	const handleShowComments = () => setCommentsDrawerShown(true)

	const handleCommentEdit = useCallback((newComment) => {
		const currentComments = productionStepExecution.comments || []
		currentComments.push(newComment)
		dispatch(updateProductionStepExecutionComments(productionStepExecution.objectId, currentComments))
	}, [productionStepExecution])

	const status = useMemo(() => productionStepExecution.status, [productionStepExecution])

	const getContent = useCallback(() => {
		if ([PSE_STATUS.locked, PSE_STATUS.todo].includes(status)) {
			return (
				<ProductionStepExecutionTodo
					productionStepExecution={productionStepExecution}
					onClickCommentsIcon={handleShowComments}
					commentsCount={(pseComments || []).length}
				/>
			)
		}
		if (status === PSE_STATUS.inProgress) {
			return (
				<ProductionStepExecutionInProgress
					productionStepExecution={productionStepExecution}
					onClickCommentsIcon={handleShowComments}
					commentsCount={(pseComments || []).length}
				/>
			)
		}
		if (status === PSE_STATUS.toTest) {
			return (
				<ProductionStepExecutionToTest
					productionStepExecution={productionStepExecution}
					onClickCommentsIcon={handleShowComments}
					commentsCount={(pseComments || []).length}
				/>
			)
		}
		if (status === PSE_STATUS.done) {
			return (
				<ProductionStepExecutionDone
					productionStepExecution={productionStepExecution}
					onClickCommentsIcon={handleShowComments}
					commentsCount={(pseComments || []).length}
				/>
			)
		}
	}, [productionStepExecution, status])


	return (
		<Box
			sx={{
				paddingBottom: status !== PSE_STATUS.done && "86px"
			}}>
			{getContent()}
			<CommentsDrawer
				comments={pseComments}
				open={commentsDrawerShown}
				onAddComment={handleCommentEdit}
				onClose={() => setCommentsDrawerShown(false)}
			/>
		</Box>
	)
}

export default ProductionStepExecution