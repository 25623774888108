import React from "react"
import { Stack } from "@mui/material"
import { Form, Formik } from "formik"
import PackagingExecutionSections from "./PackagingExecutionSections"
import PackagingExecutionPackagings from "./PackagingExecutionPackagings"
import { formatPackagingExecutionWeightsInitialValues, packagingExecutionWeightValidator } from "../../../utils/packagingExecutions"

export const packagingExecutionWeightsFormId = "PackagingExecutionWeightsForm"

const PackagingExecutionWeightsForm = ({ packagingExecution, handleSubmit, editable = true, proposedWeightsBySections }) => {

	const FCPackagingNumber = packagingExecution.packagingNumber ? packagingExecution.packagingNumber.find(item => item.brand === "FOODCHERI").value : 1

	return (<Formik
		initialValues={formatPackagingExecutionWeightsInitialValues(packagingExecution, proposedWeightsBySections)}
		onSubmit={handleSubmit}
		validationSchema={packagingExecutionWeightValidator}
	>
		{({ values, setFieldValue, touched, setFieldTouched }) => {
			return (
				<Form
					id={packagingExecutionWeightsFormId}>
					<Stack spacing={4}>
						<Stack direction="row">
							<PackagingExecutionPackagings
								packagings={values.packagings}
								expectedPackagingNumber={values.expectedPackagingNumber}
								packagingForecastNumber={values.packagingForecastNumber}
								setFieldValue={setFieldValue}
							/>
						</Stack>
						<Stack direction="row">
							<PackagingExecutionSections
								proposedWeightsBySections={proposedWeightsBySections}
								editable={editable}
								sections={values.sections}
								packagingForecastNumber={values.packagingForecastNumber}
								touchedSections={touched.sections}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								FCPackagingNumber={FCPackagingNumber}
							/>
						</Stack>
					</Stack>
				</Form>
			)
		}}
	</Formik>)
}

export default PackagingExecutionWeightsForm