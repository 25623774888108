import React from "react"
import { StyledTableHead, StyledTableCell, StyledTableContainer, StyledHeadTableCell } from "../styledComponents"
import { Table, TableRow, TableBody, Stack, Chip } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { roundNumber } from "../../../utils"
import ProductionStepExecutionSuppliesSelector from "./ProductionStepExecutionSuppliesSelector"
import dayjs from "dayjs"
import { COLLECTION_NAMES } from "../../../parseUtils"
import { getStepComponentName } from "../preview/PSEStepComponents"

const useStyles = makeStyles({
  weightLabel: {
    color: "#7C7C7C"
  },
  nameChipContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start"
  },
  chip: {
    borderRadius: "43px",
    background: "#EBF7EE",
    height: "30px",
    padding: "8px",
    textAlign: "center",
    color: "#55555",
    marginLeft: 8
  }
})

const ProductionStepExecutionLotsTable = ({
  productionStepExecution,
  priorStepData = [],
  editable = false,
  setFieldValue,
  values,
  errors,
  secondaryDLCsBySupplierItems = new Map(),
  lotsBySupplierItems = new Map(),
}) => {

  const classes = useStyles()

  // integrate substitutes from production substitutions
  const _getSupplierItemObjectIdWithSubstitutes = (stepComponent, substitutes = []) => {
    if (!stepComponent.supplierItem) {
      return ""
    }
    const replacementSupplierItem = substitutes.find(substitute => substitute.originalSupplierItem.objectId === stepComponent.supplierItem.objectId)?.replacementSupplierItem
    if (replacementSupplierItem) {
      return replacementSupplierItem.objectId
    }
    return stepComponent.supplierItem.objectId
  }

  /**
   * FILTERED LOTS =
   * lots that matches with the substitute supplier item from production substitutions
   * lots which were used to replace another in parcours traiter le manquant (field originalSupplierItem in lot events)
   */
  const _renderLots = (lots = [], stepComponent, substitutes = []) => {
    const supplierItemId = _getSupplierItemObjectIdWithSubstitutes(stepComponent, substitutes)
    const lotsAndSecondaryDlcs = []
    const filteredLots = lots.filter(lot => lot.className === COLLECTION_NAMES.lot).filter(lot => lot.supplierItem?.objectId === supplierItemId || (lot.events || []).some(event => event.originalSupplierItem?.objectId === supplierItemId && event.productionDate === productionStepExecution.stepProductionDate))
    const filteredSecondaryDlCs = lots.filter(lot => lot.className === COLLECTION_NAMES.secondaryDLC).filter(secondaryDlc => secondaryDlc.supplierItem?.objectId === supplierItemId || secondaryDlc.originalSupplierItem?.objectId === supplierItemId)
    lotsAndSecondaryDlcs.push(...filteredSecondaryDlCs, ...filteredLots)
    const formattedLots = lotsAndSecondaryDlcs.map((lot) => {
      const className = lot.className
      if (className === COLLECTION_NAMES.secondaryDLC) {
        return `DLC 2nd (${dayjs(lot.secondaryDLC).format("DD/MM/YYYY")})`
      }
      else {
        return `${lot.lotNumber} (${dayjs(lot.dlc).format("DD/MM/YYYY")})`
      }
    })
    if (!formattedLots.length) {
      return (
        <span>-</span>
      )
    }
    return (
      <Stack gap={"8px"}>
        {formattedLots.map((lot, index) => (
          <span key={index}>{lot}</span>
        ))}
      </Stack>
    )
  }

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledHeadTableCell>
              Ingrédients
            </StyledHeadTableCell>
            <StyledHeadTableCell>
              Poids en entrée
            </StyledHeadTableCell>
            <StyledHeadTableCell>
              Écart au poids théorique
            </StyledHeadTableCell>
            <StyledHeadTableCell>
              Lots
            </StyledHeadTableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {(productionStepExecution?.productionStepSnapshot.stepComponents || []).map((stepComponent, index) => {
            let weight = roundNumber(stepComponent.grossWeight * (productionStepExecution.expectedProduction) * (productionStepExecution.coeff || 1), 3)
            let gap = ""

            if (productionStepExecution.reusableProductionStep) {
              weight = roundNumber(productionStepExecution.theoreticalGrossWeight)
            }

            if (stepComponent.priorSteps && stepComponent.priorSteps.index) {
              const currentPriorStepData = priorStepData.find(priorStep => priorStep.stepIndex === stepComponent.priorSteps.index)
              if (currentPriorStepData) {
                gap = roundNumber(currentPriorStepData.realNetWeight - weight, 3)
                weight = currentPriorStepData.realNetWeight
              }
            }

            const supplierItemObjectId = _getSupplierItemObjectIdWithSubstitutes(stepComponent, productionStepExecution.substitutes)

            const isPriorSteps = !stepComponent.supplierItem
            const hasSecondaryDLC = isPriorSteps ? false : (secondaryDLCsBySupplierItems.get(supplierItemObjectId) || []).length !== 0

            return (
              <TableRow key={index}>
                <StyledTableCell>
                  <Stack className={classes.nameChipContainer}>
                    <span>{getStepComponentName(stepComponent, productionStepExecution.substitutes)}</span>
                    {hasSecondaryDLC && <Chip className={classes.chip} label="DLC 2nd" />}
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>{roundNumber(weight, 2)} <span className={classes.weightLabel}>Kg</span></StyledTableCell>
                <StyledTableCell>{gap || "-"}</StyledTableCell>
                <StyledTableCell>
                  {editable && (
                    <ProductionStepExecutionSuppliesSelector
                      setFieldValue={setFieldValue}
                      values={values}
                      errors={errors}
                      secondaryDLCs={secondaryDLCsBySupplierItems.get(supplierItemObjectId) || []}
                      lots={lotsBySupplierItems.get(supplierItemObjectId) || []}
                    />
                  )}
                  {!editable && _renderLots(productionStepExecution.lots, stepComponent, productionStepExecution.substitutes)}
                </StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}

export default ProductionStepExecutionLotsTable